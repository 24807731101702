import axios from './index';

/**
 * APIs
 */
// 获取代币配置列表
export const tokenConfigs = (params: TokenConfigsParams) => {
    return axios<ResType<TokenConfig[]>>('/v1/web3/token/configs', {
        method: 'get',
        params,
    });
};

// 代币配置列表排序
export const tokenConfigsSorts = (data: { token_ids: string[] | number[] }) => {
    return axios<ResType<TokenConfig[]>>('/v1/web3/token/configs/sort', {
        method: 'post',
        data,
    });
};

// 修改代币配置
export const updateTokenConfigs = (token_id: string | number, data: TokenConfigUpdate) => {
    return axios<ResType<TokenConfig[]>>(`/v1/web3/token/configs/${token_id}`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type TokenConfigsParams = {
    token_id: string;
};

export type Token = {
    /**
     * ID
     */
    id: string;
    /**
     * 代币 ID
     */
    token_id: string;
    /**
     * 符号
     */
    symbol: string;
    /**
     * 全称
     */
    full_symbol: string;
    /**
     * 标签
     */
    label: string;
    /**
     * URL
     */
    url: string;
};

/**
 * 代币修改
 */
export type TokenConfigUpdate = {
    /**
     * 色值
     */
    color: string;
    /**
     * 交易对排序
     */
    sort: number;
    /**
     * 价格精度
     */
    price_scale: number;
    /**
     * 数量精度
     */
    show_scale: number;
    /**
     * 最小划转金额
     */
    // min_top_up: string;
    /**
     * 最小交易金额
     */
    // min_trade_amount: string;
    /**
     * 类型
     */
    type: number;
    /**
     * 状态
     */
    status: number;
    /**
     * 更新时间
     */
    updated_at: string;
};

/**
 * 代币配置
 */
export interface TokenConfig {
    /**
     * ID
     */
    id: string;
    /**
     * 代币
     */
    token: Token;
    /**
     * 交易对排序
     */
    sort: number;
    /**
     * 色值
     */
    color: string;
    /**
     * 引擎精度
     */
    decimals: string;
    /**
     * 最小划转金额
     */
    min_top_up: string;
    /**
     *
     */
    chain: string;
    /**
     * 状态
     */
    status: number;
    /**
     * 价格精度
     */
    price_scale: number;
    /**
     * 数量精度
     */
    show_scale: number;
    /**
     *
     */
    min_trade_amount: string;
    /**
     * 类型
     */
    type: number;
    /**
     * 操作人
     */
    operator: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 更新时间
     */
    updated_at: string;
}
