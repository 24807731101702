import { ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Switch } from 'antd';
import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * APIs
 */
import { blocks, uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { BlocksResult } from '@/services/square';

/**
 * Constants
 */
const areaOrder = ['Default', 'NorthAmerica', 'Asia', 'Africa', 'Europe', 'SouthAmerica'];

const typeOrder = [
    'Tag',
    'Hot',
    'Custom1',
    'Custom2',
    'Custom3',
    'Custom4',
    'Custom5',
    'Promotion',
    'KOL',
    'Group',
    'Shorts',
];

const nameMaps: Record<string, string> = {
    Tag: 'Tags',
    Hot: 'Trending',
    Custom1: 'Leaderboard 1',
    Custom2: 'Leaderboard 2',
    Custom3: 'Leaderboard 3',
    Custom4: 'Leaderboard 4',
    Custom5: 'Leaderboard 5',
    Promotion: 'Promotion area',
    KOL: 'KOL',
    Group: 'Community',
    Shorts: 'Short video',
};

const processingData = (data: BlocksResult[]): BlocksResult[] => {
    // 根据 type 和 area 进行排序和分组
    const groupedData = typeOrder.flatMap((type) => {
        const filteredItems = data.filter((item) => item.type === type && item.area !== 'All');

        if (filteredItems.length > 0) {
            filteredItems.sort((a, b) => areaOrder.indexOf(a.area) - areaOrder.indexOf(b.area));

            return {
                area: '-',
                children: filteredItems,
                data: [],
                id: uniqueId('group_'),
                index: 0,
                max_item_count: 0,
                name: nameMaps[filteredItems[0].type],
                status: false,
                type,
            } as BlocksResult;
        }

        return [];
    });

    // 合并数据
    const combinedData = [...groupedData, ...data.filter((item) => item.area === 'All')];

    // 根据 type 进行最终排序
    combinedData.sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));

    return combinedData;
};

const ComponentTable: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * States
     */
    const [loading, setLoading] = useSetState<Record<string, boolean>>({});

    const { current, dataSource, refreshDataSource, setCurrent, setDataSource, setRefreshDataSource } =
        useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    const { refresh: refreshBlocks } = useRequest(blocks, {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setDataSource(processingData(data));
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<BlocksResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: '1',
                title: 'No.',
                width: 50,
                renderText: () => ' ',
                valueType: (entity) => (areaOrder.includes(entity.area) ? 'text' : 'index'),
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.name" />,
            },
            {
                dataIndex: 'area',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.area" />,
                width: 125,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.status" />,
                width: 80,
                render: (dom, entity) =>
                    entity.children ? (
                        '-'
                    ) : (
                        <Switch
                            checked={!!dom}
                            loading={loading[entity.id]}
                            onChange={(checked, e) => {
                                e.stopPropagation();

                                setLoading({ [entity.id]: true });

                                uBlock(entity.id, {
                                    status: checked,
                                    updated_at: entity.updated_at,
                                })
                                    .then(({ data: { code, data, msg } }) => {
                                        if (code === 150203) {
                                            return message.error('It has been edited by someone else, please refresh');
                                        }

                                        if (code !== 0) {
                                            return message.error(msg);
                                        }

                                        entity.status = data.status;
                                        entity.updated_at = data.updated_at;
                                    })
                                    .finally(() => {
                                        setLoading({ [entity.id]: false });
                                    });
                            }}
                        />
                    ),
            },
        ],
        dataSource,
        expandable: {
            expandIconColumnIndex: 1,
            expandRowByClick: true,
            indentSize: 0,
        },
        headerTitle: <FormattedMessage id="p.plazaConfig.t.headerTitle" />,
        options: {
            reload: () => {
                setRefreshDataSource();
            },
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            y: 'calc(100vh - 232px)',
        },
        search: false,
        onRow: (data) => {
            return {
                onClick: () => {
                    setCurrent(data.children ? null : data);
                },
            };
        },
        rowClassName: (record) => (record.id === current?.id ? 'rowClassName' : ''),
    };

    /**
     * Effects
     */
    useEffect(() => {
        refreshBlocks();
    }, [refreshDataSource]);

    return <ProTable {...proTableProps} />;
};

export default ComponentTable;
