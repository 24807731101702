export default {
    rm: {
        b: {
            normal: 'Normal',
            violate: 'Violation',
            multNormal: 'Bulk Normal',
        },
        t: {
            c: {
                reportId: 'Report ID',
                postUid: 'Post ID',
                formPostUid: 'Post ID',
                commentId: 'Reply ID',
                commentContent: 'Content',
                postAccUid: 'Creator UID',
                content: 'Content',
                postImg: 'Image/Video',
                postAudio: 'Audio',
                nickName: 'Nickname',
                name: 'Username',
                avatar: 'Avatar',
                userInfo: 'Bio',
                reportType: 'Type',
                otherReason: 'Other',
                reportTime: 'Reported At',
                'reporter-UID': 'Reporter UID',
                'review-result': 'Result',
                debate: 'Debate',
                debateUsers: 'For&Against',
            },
        },
        reportType: {
            racism: '原因1',
            platform_safety: '原因2',
            misinformation: '原因3',
            other: '其他原因',
        },
        p: {},
    },
};
