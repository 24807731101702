import axios from './index';

/**
 * APIs 话题管理
 */
// 获取列表
export const gDomainApi = (params: DomainParams) => {
    return axios<PageResType<DomainResult>>('/v1/domains', {
        method: 'get',
        params,
    });
};

// 创建话题
export const cDomainApi = (data: CreateDomainData) => {
    return axios<PageResType<null>>('/v1/domains', {
        method: 'post',
        data,
    });
};

// 编辑话题
export const pDomainApi = (data: EditeDomainData) => {
    return axios<PageResType<null>>(`/v1/domains`, {
        method: 'put',
        data,
    });
};

// 删除话题
export const dDomainApi = (id: string | number) => {
    return axios<PageResType<null>>(`/v1/domains/${id}`, {
        method: 'delete',
    });
};

/**
 * ErrorCode
 */

export const ErrorCodeMap = {
    1001: 'su.f.domainFormateError',
    290101: 'su.f.domainExit',
};

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';

/**
 * Domain
 */
export type DomainResult = {
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 删除时间
     */
    deleted_at?: string | null;
    /**
     * 域名
     */
    domain: string;
    /**
     * ID
     */
    id: string;
    operator_account: OperatorAccount;
    status: Status;
    /**
     * 更新时间
     */
    updated_at: string;
};

export interface OperatorAccount {
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 删除时间
     */
    deleted_at?: string | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 状态
     */
    status: number;
    /**
     * 更新时间
     */
    updated_at: string;
}

export enum Status {
    Disabled = 'disabled',
    Enabled = 'enabled',
}

export type DomainParams = {
    /**
     * 每页条数
     */
    limit?: string;
    order_by?: string;
    order_sort?: string;
    /**
     * 当前页数
     */
    page?: string;
};

export type CreateDomainData = {
    /**
     * 域名
     */
    domain: string;
    status: Status;
};

export type EditeDomainData = {
    /**
     * 表唯一 ID
     */
    ids: string[];
    status: Status;
    updated_at?: string;
};
