export default {
    p: {
        inc: {
            column: {
                id: '活动ID',
                timeRange: '起止时间',
                target: '目标人数&奖励SOC',
                targetTooltips: '成功进行活体验证的历史累计用户总数以及对应奖励的SOC',
                status: '活动状态',
                user: '目标人数{value}',
                soc: '目标奖励SOC{value}',
                socCount: '累计空投SOC数',
                faceActiveCount: '活体认证用户数',
            },
            msg: {
                soc: '请输入一个小数点前最多11位小数点后最多4位的数',
                user: '请输入1-100000000之间的正整数',
            },
            status: {
                pending: '未开始',
                running: '进行中',
                completed: '已结束',
            },
        },
    },
};
