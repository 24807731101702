import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { App, Button, Drawer, Form, Input, Popconfirm, Space, Typography } from 'antd';
import { DragSortTable } from '@ant-design/pro-components';
import { HolderOutlined } from '@ant-design/icons';
import { useRequest, useSetState } from 'ahooks';
import { cloneDeep } from 'lodash';
import store from 'store2';

/**
 * APIs
 */
import { checkGroupId } from '@/services/common';
import { uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { DragTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps } from 'antd';

type DataType = {
    created_at: string;
    id: string;
    operator: string;
    updated_at: string;
};

type State = {
    index: number;
    open: boolean;
    type: 'create' | 'update';
    sorting: boolean;
};

const ComponentBlockGroups: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        index: -1,
        open: false,
        type: 'create',
        sorting: false,
    });

    const { current, dataSource, setCurrent, setDataSource, setRefreshDataSource } =
        useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    const { loading: loadingCheckGroupId, runAsync: runAsyncCheckGroupId } = useRequest(checkGroupId, {
        manual: true,
    });

    const { loading: loadingUBlock, run: runUBlock } = useRequest(uBlock, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code === 150203) {
                return message.error('It has been edited by someone else, please refresh');
            }

            if (code !== 0) {
                return message.error(msg);
            }

            const index = dataSource.findIndex((item) => item.id === current!.id);

            dataSource[index] = {
                ...current!,
                ...data,
            };

            setCurrent(data);

            setDataSource(dataSource);

            setState({
                open: false,
                sorting: false,
            });

            message.success('Updated');
        },
    });

    /**
     * ChildrenProps
     */
    const dragTableProps: DragTableProps<DataType, any> = {
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                title: '社群 ID',
            },
            {
                dataIndex: 'operator',
                title: '操作人',
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: '操作时间',
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: '操作',
                valueType: 'option',
                width: 120,
                render: (_, entity, index) => [
                    <a
                        key="update"
                        onClick={() => {
                            form.setFieldsValue(entity);

                            setState({
                                index,
                                open: true,
                                type: 'update',
                            });
                        }}
                    >
                        编辑
                    </a>,
                    <Popconfirm
                        cancelText="No"
                        key="delete"
                        okText="Yes"
                        title="Are you sure?"
                        onConfirm={() => {
                            current!.data.splice(index, 1);

                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                    >
                        <Typography.Link type="danger">删除</Typography.Link>
                    </Popconfirm>,
                ],
            },
        ],
        dataSource: current!.data,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        headerTitle: current!.name,
        options: {
            reload: () => {
                setRefreshDataSource();
            },
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 740,
        },
        search: false,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingUBlock}
                        onClick={() => {
                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,

                    <Button
                        loading={loadingUBlock}
                        onClick={() => {
                            setState({ sorting: false });
                            const index = dataSource.findIndex((item) => item.id === current!.id);

                            const oldCurrentData = dataSource[index].data;

                            setCurrent({
                                ...current!,
                                data: oldCurrentData,
                            });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Button
                    disabled={current!.data?.length >= current!.max_item_count}
                    key="add"
                    type="primary"
                    onClick={() => {
                        setState({
                            open: true,
                            type: 'create',
                        });
                    }}
                >
                    添加
                </Button>,

                <Button
                    key="sort"
                    type="primary"
                    ghost
                    onClick={() => {
                        setState({ sorting: true });
                    }}
                >
                    <FormattedMessage id="tc.t.c.sort" />
                </Button>,
            ];
        },
        onDragSortEnd: (_, __, newDataSource) => {
            setCurrent({ ...current!, data: newDataSource });
        },
    };

    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        loading={loadingCheckGroupId || loadingUBlock}
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    if (
                                        current!.data?.some(
                                            (item: DataType, index: number) =>
                                                item.id === values.id && index !== state.index,
                                        )
                                    ) {
                                        return message.error('社群 ID 重复');
                                    }

                                    runAsyncCheckGroupId(values.id).then(({ data: { data } }) => {
                                        if (!data) {
                                            return message.error('社群 ID 验证不通过');
                                        }

                                        const prevData = cloneDeep(current!.data) || [];

                                        // 创建
                                        if (state.type === 'create') {
                                            prevData.push({
                                                ...values,
                                                created_at: new Date().toISOString(),
                                                operator: store('user').name,
                                                updated_at: new Date().toISOString(),
                                            });
                                        }
                                        // 编辑
                                        else {
                                            prevData[state.index] = {
                                                ...prevData[state.index],
                                                ...values,
                                                operator: store('user').name,
                                                updated_at: new Date().toISOString(),
                                            };
                                        }

                                        runUBlock(current!.id, {
                                            data: prevData,
                                            updated_at: current!.updated_at,
                                        });
                                    });
                                })
                                .catch(() => {});
                        }}
                    >
                        保存
                    </Button>
                </Space>
            </div>
        ),
        maskClosable: false,
        open: state.open,
        title: state.type === 'create' ? '添加' : '编辑',
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            }
        },
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
    };

    useEffect(() => {
        setState({
            sorting: false,
        });
    }, [current?.id]);

    return (
        <>
            <DragSortTable {...dragTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item label="社群 ID" name="id" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default ComponentBlockGroups;
