export default {
    'menu.dashboard': '仪表盘',

    /**
     * 大使计划
     */
    'menu.ambassador': '大使计划',
    'menu.ambassador.application': '大使申请',
    'menu.ambassador.applicationPublicPool': '申请公海池',
    'menu.ambassador.applicationPrivatePool': '申请私海池',
    'menu.ambassador.applicationReviewRecords': '审核记录',

    'menu.ambassador.l2-3': '数据管理',
    'menu.ambassador.l2-3-1': '大使任务数据',

    'menu.ambassador.config': '配置管理',
    'menu.ambassador.config.applicationPrivatePoolConfiguration': '申请私海池配置',
    /**
     * 大使计划 end
     */

    'menu.client': '客户端',
    'menu.client.digitizedCivilizationSquare': '数字文明广场',

    'menu.airdropRootAccount': '帖子空投超级账号',
    'menu.systemManagement': '系统管理',
    'menu.accountManagement': '账号管理',
    'menu.userNameManagement': '用户名管理',
    'menu.roleManagement': '角色管理',
    clientConfigurationManagement: '客户端配置管理',
    'menu.airDropManagement': '空投管理',
    'menu.officialAirDropList': '官方空投活动列表',
    /**
     * 举报管理
     */
    'menu.reportManagement': '举报管理',
    'menu.postReport': '帖子举报',
    'menu.commentReport': '评论举报',
    'menu.accountInfoReport': '个人资料举报',
    /**
     * 举报管理 end
     */
};
