export default {
    p: {
        tp: {
            column: {
                token: 'Token',
                token0: 'Token0',
                token1: 'Token1',
                fee: '交易费率',
                defaultFee: '默认费率',
                price: '初始化价格',
            },
            defaultFee: {
                true: '是',
                false: '否',
            },
            msg: {
                token0GreaterThanToken1: 'Token0的交易对排序应小于Token1的交易对排序',
                price: '请输入一个小数点前最多11位小数点后最多10位的正数',
                defaultFee: '首次添加的交易对设置的费率必须为默认费率',
                302001: '初始化价格与市场价格偏差应在5%以内',
            },
            tips: {
                price: '1个Token0兑换成Token1的个数',
            },
        },
    },
};
