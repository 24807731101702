import { useEffect, useRef, useState } from 'react';
import { App, Button, Flex, Form, Input, DatePicker } from 'antd';
import { isEmpty, omitBy } from 'lodash';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import Permission from '@/components/permission';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { userExport, userListApi } from '@/services/user';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { User, UserListParams } from '@/services/user';
import { ProCard, ProTable, type ProTableProps } from '@ant-design/pro-components';
import dayjs from 'dayjs';

type State = {
    dataSource: User[];
    selectedRowKeys: React.Key[];
};

/**
 * Constants
 */
const defaultPayload = {
    name: undefined,
    nickname: undefined,
    register_start: undefined,
    register_end: undefined,
    parent_uid: undefined,
    uid: undefined,
};

const PageAccountManagement: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const [target, setTarget] = useState(0);

    const [countdown] = useCountDown({
        targetDate: target,
    });

    const [state, setState] = useSetState<State>({
        dataSource: [],
        selectedRowKeys: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserListParams>({
        register_start: dayjs().startOf('d').format(),
        register_end: dayjs().endOf('d').format(),
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshUserList,
        run: runUserList,
    } = useRequest(userListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            date: [dayjs(payload.register_start), dayjs(payload.register_end)],
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.date && values.date.length > 0) {
                values.register_start = values.date[0].startOf('d').format();
                values.register_end = values.date[1].endOf('d').format();
                delete values.date;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                ...defaultPayload,
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<User, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'user_id',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.uid" />,
            },
            {
                dataIndex: 'name',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.userName" />,
                render: (_, record) => {
                    return <span>@{record.name}</span>;
                },
            },
            {
                dataIndex: 'nickname',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.nickname" />,
            },
            {
                dataIndex: 'bio',
                title: <FormattedMessage id="p.uu.columns.bio" />,
            },
            {
                dataIndex: 'parent_user_id',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.parentUid" />,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.registrationTime" />,
                valueType: 'dateTime',
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, record) => [
                    <Permission permission="Users_Users_Details">
                        <a target="_blank" href={`/users/detail?uid=${record.user_id}`}>
                            <FormattedMessage id="b.details" />
                        </a>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshUserList,
        },
        rowKey: 'id',
        scroll: {
            x: 1470,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertRender: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runUserList({
            page: pagination.current,
            limit: pagination.pageSize,
            ...payload,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="uid" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.uid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.userName' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="nickname" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.nickname' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="parent_uid" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.parentUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 'p.uu.columns.registrationTime' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Users_Users_Export">
                            <Button
                                disabled={countdown !== 0}
                                onClick={async () => {
                                    const values = formSearch.getFieldsValue();
                                    if (values.date && values.date.length > 0) {
                                        values.register_start = values.date[0].startOf('d').format();
                                        values.register_end = values.date[1].endOf('d').format();
                                        delete values.date;
                                    }
                                    const { data } = await userExport({
                                        ...omitBy(values, isEmpty),
                                    });
                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(formatMessage({ id: 'm.exportTips' }));

                                    setTarget(Date.now() + 1000 * 10);
                                }}
                            >
                                <FormattedMessage id="b.export" />
                                {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageAccountManagement;
