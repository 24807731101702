import { useBoolean, useSetState } from 'ahooks';
import { get, omit } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Radio, Space, Upload } from 'antd';

/**
 * APIs
 */
import {
    CreatorAcademyDetailResult,
    AddCreatorAcademyDetailParams,
    TypeValue,
    pCreatorAcademyDetailApi,
    cCreatorAcademyDetailApi,
} from '@/services/creator-academy';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

// form submit
const formSubmit = async (
    propsData: CreatorAcademyDetailResult | null,
    formData: AddCreatorAcademyDetailParams,
    subject_id: string,
) => {
    console.log(formData._cover_image_file);
    if (Array.isArray(formData._cover_image_file) && formData._cover_image_file[0]) {
        formData.cover_image = formData._cover_image_file[0].url;
        formData._cover_image_file = undefined;
    }
    formData.subject_id = subject_id;
    formData._language = undefined;
    if (propsData && propsData.id) {
        // 区分是更新还是新增
        formData.updated_at = propsData.updated_at;
        return await pCreatorAcademyDetailApi(
            propsData.id,
            omit({
                title: formData.title,
                cover_image: formData.cover_image,
                updated_at: propsData.updated_at,
            }),
        );
    }
    return await cCreatorAcademyDetailApi(omit(formData));
};

/**
 * Types
 */
import type { DrawerProps, FormProps, UploadProps } from 'antd';
import type { IntlShape } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '@/services/common';

type Props = {
    open: boolean;
    entity: CreatorAcademyDetailResult | null;
    language: string;
    subjectId: string;
    closeModelForm: (reload?: boolean) => void;
};

type State = {
    uploadPoster: boolean;
    typeValue: TypeValue;
};

const ComponentDrawerFormRightDetail: React.FC<Props> = ({ open, subjectId, language, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const intl: IntlShape = useIntl();

    const [form] = Form.useForm();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);

    const [state, setState] = useSetState<State>({
        typeValue: 1,
        uploadPoster: true,
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData, subjectId);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            form.setFieldsValue({
                _language: language,
            });

            if (!open) {
                setSubmittingFlase();
                form.resetFields();
                setState({
                    typeValue: 1,
                    uploadPoster: true,
                });
            } else if (entity) {
                const reviewImg = [];
                if (entity.cover_image) {
                    reviewImg.push({
                        name: 'image',
                        url: entity.cover_image,
                    });
                }
                form.setFieldsValue({
                    type: entity.type,
                    title: entity.title,
                    content_id: entity.content_id,
                    cover_image: entity.cover_image,
                    _language: language,
                    _cover_image_file: reviewImg,
                });
                if (reviewImg.length) {
                    setState({
                        typeValue: entity.type,
                        uploadPoster: false,
                    });
                } else {
                    setState({
                        typeValue: entity.type,
                    });
                }
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * UploadProps
     */
    const uploadProps: UploadProps = {
        accept: 'image/jpg,image/jpeg,image/png',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<AddCreatorAcademyDetailParams> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="_language"
                    label={intl.formatMessage({ id: 'tc.f.language' })}
                    rules={[{ required: true }]}
                    initialValue={language}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="type"
                    initialValue={1}
                    label={intl.formatMessage({ id: 't.c.type' })}
                    rules={[{ required: true }]}
                >
                    <Radio.Group onChange={(e) => setState({ typeValue: e.target.value })} disabled={!!entity}>
                        <Radio value={1}>{intl.formatMessage({ id: 'rm.t.c.postUid' })}</Radio>
                        <Radio value={2}>{intl.formatMessage({ id: 'cp.t.c.shortsId' })}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="content_id"
                    label={intl.formatMessage({
                        id: state.typeValue === 1 ? 'rm.t.c.postUid' : 'cp.t.c.shortsId',
                    })}
                    rules={[{ required: true }]}
                >
                    <Input allowClear disabled={!!entity} />
                </Form.Item>

                <Form.Item
                    name="title"
                    label={intl.formatMessage({ id: 'cad.t.c.title' })}
                    rules={[{ required: true }]}
                >
                    <Input allowClear showCount maxLength={50} />
                </Form.Item>

                <Form.Item
                    extra="Size: 144*144 (width x height), JPG/JPEG/PNG, within 3 MB"
                    label={intl.formatMessage({ id: 'cad.t.c.cover' })}
                    name="_cover_image_file"
                    valuePropName="fileList"
                    getValueFromEvent={(e: any) => {
                        setState({
                            uploadPoster: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                        });

                        if (e.file.status === 'done') {
                            e.file.url = e.file.response.data.data?.url;

                            if (e.file.response.data.code !== 0) {
                                message.error(e.file.response.data.msg);
                                return [];
                            }
                        }

                        return e.fileList;
                    }}
                    rules={[{ required: true }]}
                >
                    <Upload {...uploadProps}>
                        {state.uploadPoster && (
                            <div>
                                <PlusOutlined />

                                <div style={{ marginTop: 8 }}>上传</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentDrawerFormRightDetail;
