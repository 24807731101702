import { createBrowserRouter } from 'react-router-dom';

/**
 * Components
 */
import AuthLogin from '@/pages/auth/login';
import Page403 from '@/pages/error-pages/403';
import LayoutIndex from '@/layouts';

/**
 * Constants
 */
import routes from './routes';

export const router = createBrowserRouter([
    {
        children: [
            {
                path: '/login',
                element: <AuthLogin />,
            },
            /* 错误页面 Error Pages */
            {
                element: <Page403 />,
                path: '/403',
            },
            ...routes,
        ],
        element: <LayoutIndex />,
    },
]);
