import { useEffect } from 'react';
import { useBoolean, useLocalStorageState, useRequest, useSetState } from 'ahooks';
import { Button, Drawer, Form, Input, message, Select, Space, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
/**
 * APIs
 */
import { getRoles, AccManagementApiErrorCodeMsg } from '@/services/account-management';
import { cAccount, uAccount } from '@/services/account';

// form submit
const formSubmit = async (propsData: AccountsResponse | undefined, formData: FeFormParams, operator: string) => {
    const submitFormData: CAccountData = {
        name: formData.username,
        status: formData.status ? 1 : 0,
        operator: operator,
    };
    // 更新和新增 password 和 roleIds 都是非必填
    if (formData.role) {
        submitFormData.roleIds = [formData.role];
    }
    if (formData.password) {
        submitFormData.password = formData.password;
    }

    // 区分是更新还是新增, 更新才有 updated_at
    if (propsData && propsData.id) {
        // 更新 角色单独处理。删除角色时，给后端传空数组
        if (!formData.role && propsData.roles?.length) {
            submitFormData.roleIds = [];
        }
        const updateFormData = {
            updated_at: propsData.updated_at,
            ...submitFormData,
        };
        return await uAccount(propsData.id, updateFormData);
    }
    return await cAccount(submitFormData);
};
/**
 * Utils
 */
import generateRandomPassword from '@/utils/generateRandomPassword';
import { accountPasswordRegex } from '@/utils';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { User } from '@repo/typings/user';
import type { AccountsResponse, CAccountData } from '@/services/account';

export type FeFormParams = {
    username: string;
    password: string;
    role: string;
    status: boolean;
};

export type ModalFormState = {
    open: boolean;
    data: AccountsResponse | undefined;
};

type RolesOptions = {
    label: string;
    value: string;
};

type Props = {
    state: ModalFormState;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ state, closeModelForm }) => {
    /**
     * State
     */
    const [passwordVisible, { set: setPasswordVisible }] = useBoolean(false);
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [playload, setPlayload] = useSetState<{ roles: RolesOptions[] }>({
        roles: [],
    });
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();
    const [user] = useLocalStorageState<User>('user', {
        defaultValue: {
            id: '',
            status: 0,
            name: '',
        },
    });

    const { run: runRolesRequest } = useRequest(getRoles, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPlayload({
                roles: get(data, 'list', []).map((v: any) => {
                    return {
                        label: v.name,
                        value: v.id,
                    };
                }),
            });
        },
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData: FeFormParams) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(state.data, formData, user!.name);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            const errorCodeKey = AccManagementApiErrorCodeMsg[get(res, 'data.code')];
                                            if (errorCodeKey) {
                                                message.error(intl.formatMessage({ id: errorCodeKey }));
                                            } else {
                                                message.error(get(res, 'data.msg', 'Error'));
                                            }
                                        }
                                        setSubmittingFlase();
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id={state.data ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            } else if (state.data) {
                const roles = get(state, 'data.roles', []);
                form.setFieldsValue({
                    username: state.data.name,
                    password: '',
                    role: roles[0] ? roles[0].id : undefined,
                    status: !!state.data.status,
                });
            } else {
                form.setFieldsValue({
                    status: true,
                });
            }
        },
        onClose: () => {
            setSubmittingFlase();
            closeModelForm();
        },
    };

    /**
     * FormProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        autoComplete: 'off',
        validateTrigger: 'onBlur',
    };

    /**
     *
     */
    useEffect(() => {
        state.open && runRolesRequest();
    }, [state.open]);
    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="username"
                    label={intl.formatMessage({ id: 't.c.account' })}
                    rules={[
                        { required: true },
                        {
                            pattern: /^[a-zA-Z][a-zA-Z0-9!@#$%^&*()-_=+[\]{}|;':",.<>/?]{0,19}$/,
                            message: intl.formatMessage({ id: 'sma.c.inputAccountPlaceholder' }),
                        },
                    ]}
                >
                    <Input
                        placeholder=""
                        maxLength={20}
                        count={{
                            show: true,
                            max: 20,
                        }}
                        autoComplete="new-account"
                    />
                </Form.Item>
                <Space align="start">
                    <Form.Item
                        name="password"
                        label={intl.formatMessage({ id: 'c.password' })}
                        rules={[
                            {
                                pattern: accountPasswordRegex,
                                message: intl.formatMessage({ id: 'sma.c.inputPWDPlaceholder' }),
                            },
                        ]}
                    >
                        <Input.Password
                            autoComplete="new-password"
                            style={{ width: 243 }}
                            placeholder=""
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button
                            style={{ width: 80 }}
                            onClick={() => {
                                form.setFieldsValue({
                                    password: generateRandomPassword(),
                                });
                                setPasswordVisible(true);
                            }}
                        >
                            <FormattedMessage id="sma.b.generated" />
                        </Button>
                    </Form.Item>
                </Space>
                <Form.Item name="role" label={<FormattedMessage id="t.c.role" />}>
                    <Select showSearch optionFilterProp="label" allowClear options={playload.roles} placeholder="" />
                </Form.Item>
                <Form.Item
                    initialValue={true}
                    name="status"
                    label={intl.formatMessage({ id: 't.c.status' })}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
