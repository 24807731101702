import { FormattedMessage } from 'react-intl';

const ReportTypeENUM = {
    racism: <FormattedMessage id="rm.reportType.racism" />,
    platform_safety: <FormattedMessage id="rm.reportType.platform_safety" />,
    misinformation: <FormattedMessage id="rm.reportType.misinformation" />,
    other: <FormattedMessage id="rm.reportType.other" />,
};

export default ReportTypeENUM;
