import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select, Tag } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { Address } from '@ant-design/web3';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */

/**
 * APIs
 */
import { gNFTApi, gNFTCollectionApi, subCategory, SubCategoryOptions } from '@/services/marketplace-nft';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { NFTCollectionResult, NFTParams, NFTResult } from '@/services/marketplace-nft';

type SelectOptions = {
    label: string | number;
    value: string | number;
};

/**
 * State
 */
type State = {
    dataSource: NFTResult[];
};

const PageNFTMarketplaceNFT: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [collections, setCollections] = useState<SelectOptions[]>([]);

    /**
     * Request
     */
    useRequest(
        () =>
            gNFTCollectionApi({
                page: '1',
                limit: '999',
            }),
        {
            onSuccess: ({ data }) => {
                setCollections(
                    get(data, 'data.list', []).map((v: NFTCollectionResult) => {
                        return {
                            label: v.id,
                            value: v.id,
                        };
                    }),
                );
            },
        },
    );

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<NFTParams>({});

    const {
        loading: loadingNFTApi,
        refresh: refreshNFTApi,
        run: runNFTApi,
    } = useRequest(gNFTApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: get(data, 'list', []).map((item) => {
                    item._fe_created_at = parseInt(`${item.created_at}`, 10);
                    return item;
                }),
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<NFTParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            if (values._created_date) {
                values.created_start = values._created_date[0].startOf('d').format();
                values.created_end = values._created_date[1].endOf('d').format();
                values._created_date = undefined;
            }
            if (values.nft_name) {
                values.nft_name = trim(values.nft_name);
            }
            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<NFTResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: 'ID',
                width: 200,
            },
            {
                dataIndex: 'nft_name',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.nftName" />,
            },
            {
                dataIndex: 'nft_url',
                valueType: 'image',
                title: <FormattedMessage id="mknft.t.c.img" />,
                width: 80,
            },
            {
                dataIndex: 'collection_id',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.collectionId" />,
                width: 200,
            },
            {
                copyable: true,
                dataIndex: 'nft_id',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.nftId" />,
                width: 200,
            },
            {
                dataIndex: 'nft_chain',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.chain" />,
                width: 120,
            },

            {
                dataIndex: 'nft_contract',
                title: <FormattedMessage id="mknft.t.c.eAddress" />,
                render: (_, entity) => (
                    <Address
                        ellipsis={{
                            headClip: 8,
                            tailClip: 6,
                        }}
                        copyable
                        address={get(entity, 'nft_contract')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: 'nft_type',
                ellipsis: true,
                title: <FormattedMessage id="t.c.type" />,
                width: 120,
            },
            {
                dataIndex: 'sub_category',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.subType" />,
                valueEnum: subCategory,
                width: 120,
            },
            {
                dataIndex: 'floor_price',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.lowPrice" />,
                width: 120,
            },
            {
                dataIndex: 'nft_total_supply',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.count" />,
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => (
                    <Tag color={status === 1 ? 'green' : 'orange'}>{status === 1 ? 'Enabled' : 'Disabled'}</Tag>
                ),
                width: 120,
            },
            {
                dataIndex: '_fe_created_at',
                title: <FormattedMessage id="t.c.createdAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingNFTApi,
        options: {
            reload: refreshNFTApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 2050,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runNFTApi({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="ID" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="nft_name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'mknft.t.c.nftName' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="nft_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'mknft.t.c.nftId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="collection_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={collections}
                                showSearch={true}
                                placeholder={<FormattedMessage id="mknft.t.c.collectionId" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="sub_category" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={SubCategoryOptions}
                                placeholder={<FormattedMessage id="mknft.t.c.subType" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: 'Enabled',
                                        value: '1',
                                    },
                                    {
                                        label: 'Disabled',
                                        value: '0',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.createdAt' })}
                            name="_created_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingNFTApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageNFTMarketplaceNFT;
