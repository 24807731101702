export default {
    p: {
        logs: {
            t: {
                c: {
                    body: '操作详情',
                    menu: '菜单',
                    params: '路径',
                    result: '操作结果',
                },
            },
        },
    },
};
