import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import routes from '@/router/routes';
import { Button, Card, Flex, Radio, Tree, message, Form } from 'antd';
import { useAccess } from '@/components/permission';
import { permissionData } from '@/const';
import { get } from 'lodash';
import { updateRoleApi } from '@/services/roles';
import { Key } from 'antd/es/table/interface';
import { findRouteByAccess } from '@/utils/findRoute';

const { TreeNode } = Tree;

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { RoleDataResult } from '@/services/roles';
import type { ExtendedTreeDataNode } from '@/const';
type Props = {
    role: RoleDataResult | undefined;
};

const getParentKey = (key: string, tree: ExtendedTreeDataNode[]): string | null => {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                return node.key as string;
            }
            const result = getParentKey(key, node.children);
            if (result) {
                return result;
            }
        }
    }
    return null;
};

const findNodeByKey = (key: string, data: ExtendedTreeDataNode[]): ExtendedTreeDataNode | undefined => {
    for (const node of data) {
        if (node.key === key) {
            return node;
        }
        if (node.children) {
            const found = findNodeByKey(key, node.children);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
};

/**
 * 过滤提交的权限
 * @param data 权限树
 * @param permissions 提交的权限
 * @returns 过滤后的权限
 */
const filterPermissions = (data: ExtendedTreeDataNode[], permissions: string[]) => {
    const filteredPermissions = permissions.filter((permission) => {
        // 检查权限是否在权限树中
        const isInData = data.some((node) => {
            const checkNode = (n: ExtendedTreeDataNode): boolean =>
                n.key === permission || (n.children ? n.children.some(checkNode) : false);
            return checkNode(node);
        });

        if (!isInData) return false;

        // 检查权限是否在路由中
        const route = findRouteByAccess(routes, permission);
        if (!route) return true;

        // 如果有子路由，检查子路由的权限
        if (route.children && route.children.length > 0) {
            const childPermissions = route.children
                .map((child) => child.access)
                .filter((access): access is string => typeof access === 'string');

            const hasValidChild = childPermissions.some((childPermission) => permissions.includes(childPermission));

            return hasValidChild;
        }

        return true;
    });

    return filteredPermissions;
};

/**
 * 遍历树，获取permissionData中所有的节点中的type=data的key，看是否在permissions中，如果在，则返回该key，否则返回空数组
 * @param data 权限树数据
 * @param permissions 当前权限列表
 * @returns 符合条件的key数组
 */
const getDataType = (data: ExtendedTreeDataNode[], permissions: string[]): { [key: string]: string } => {
    let result: { [key: string]: string } = {};

    const traverse = (nodes: ExtendedTreeDataNode[]) => {
        for (const node of nodes) {
            if (node.type === 'data' && Array.isArray(node.options)) {
                const options = node.options.map((option) => option.value);
                const currentOption = options.find((option) => permissions.includes(option));
                if (currentOption) {
                    result = {
                        ...result,
                        [node.key as string]: currentOption,
                    };
                }
            }
            if (node.children) {
                traverse(node.children);
            }
        }
    };

    traverse(data);
    return result;
};

const RolePermission: React.FC<Props> = ({ role }) => {
    /**
     * States
     */
    const checkAccess = useAccess();
    const intl: IntlShape = useIntl();
    const [permissions, setPermissions] = useState<string[]>([]);
    const [form] = Form.useForm();

    const hasEditPermission = checkAccess('System-Management_Permissions');

    /**
     * Effects
     */
    useEffect(() => {
        if (role && Array.isArray(role.permissions)) {
            setPermissions(filterPermissions(permissionData, role.permissions));
        } else {
            setPermissions([]);
        }
        console.log('role', getDataType(permissionData, role?.permissions || []));
        form.setFieldsValue(getDataType(permissionData, role?.permissions || []));
    }, [role]);

    /**
     * 遍历树，获取permissionData中所有的节点中的type=data的key，看是否在permissions中，如果在，则返回该key，否则返回空数组
     * @param data
     * @returns
     */

    const getAllowedKeys = (data: ExtendedTreeDataNode[]): string[] => {
        return data.reduce((acc: string[], node) => {
            if (node.children) {
                return [...acc, node.key as string, ...getAllowedKeys(node.children)];
            }
            return [...acc, node.key as string];
        }, []);
    };

    const handleCheckPermission = (checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }, info: any) => {
        const { checked, node } = info;
        let newCheckedKeys = Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked;

        if (checked) {
            // 如果选中子节点，自动选中父节点
            let parentKey = getParentKey(node.key, permissionData);
            while (parentKey) {
                if (!newCheckedKeys.includes(parentKey)) {
                    newCheckedKeys.push(parentKey);
                }
                parentKey = getParentKey(parentKey, permissionData);
            }

            // 如果选中父节点，自动勾选所有子节点
            if (node.children) {
                const childKeys = getAllowedKeys(node.children);
                newCheckedKeys = [...newCheckedKeys, ...childKeys];
            }
            // 通过node.key在permissions中找到对应的节点
            const permissionNode = findNodeByKey(node.key as string, permissionData);
            if (permissionNode && permissionNode.type === 'data' && permissionNode.options) {
                const defaultOption = permissionNode.defaultChecked;
                if (defaultOption && !newCheckedKeys.includes(defaultOption)) {
                    newCheckedKeys.push(defaultOption);
                }
                console.log('defaultOption', defaultOption);
                form.setFieldsValue({
                    [node.key as string]: defaultOption,
                });
            }
        } else {
            // 如果取消选中父节点，自动取消选中所有子节点
            if (node.children) {
                const childKeys = getAllowedKeys([node]);
                newCheckedKeys = newCheckedKeys.filter((key) => typeof key === 'string' && !childKeys.includes(key));
            }

            // 检pnpm查node.key是否在routes中
            const route = findRouteByAccess(routes, node.key as string);
            if (route) {
                // 根据node向上检查父节点，查看是否全部取消选中，如果是，则取消选中父节点
                let parentKey = getParentKey(node.key, permissionData);
                while (parentKey) {
                    const parentNode = findNodeByKey(parentKey, permissionData);
                    if (parentNode && parentNode.children) {
                        const allChildrenUnchecked = parentNode.children.every(
                            (child) => !newCheckedKeys.includes(child.key as string), // 修改为检查未选中的子节点
                        );
                        if (allChildrenUnchecked) {
                            newCheckedKeys = newCheckedKeys.filter((key) => key !== parentKey);
                        }
                        parentKey = allChildrenUnchecked ? getParentKey(parentKey, permissionData) : null;
                    }
                }
            }
            // 取消选中默认选择
            form.setFieldsValue({
                [node.key as string]: '',
            });
        }

        setPermissions(
            filterPermissions(permissionData, newCheckedKeys as string[]).filter(
                (key): key is string => typeof key === 'string',
            ),
        );
    };

    const renderTreeNodes = (data: ExtendedTreeDataNode[]) => {
        return data.map((item) => (
            <TreeNode
                disableCheckbox={!hasEditPermission}
                key={item.key}
                title={
                    <>
                        {item.title}
                        {item.type === 'data' && permissions.includes(item.key as string) && (
                            <div
                                style={{ marginLeft: 16 }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <Form.Item name={`${item.key}`} noStyle>
                                    <Radio.Group options={item.options} />
                                </Form.Item>
                            </div>
                        )}
                    </>
                }
                checkable
            >
                {item.children && renderTreeNodes(item.children)}
            </TreeNode>
        ));
    };

    if (!role) {
        return (
            <Card title={<FormattedMessage id="common.permissions" />}>
                <div style={{ padding: 48, textAlign: 'center' }}>
                    <FormattedMessage id="p.rm.ph.permission" />
                </div>
            </Card>
        );
    }

    return (
        <Card bordered={false} title={<FormattedMessage id="common.permissions" />}>
            <Form form={form}>
                <div
                    style={{
                        height: 'calc(100vh - 312px)',
                        overflowY: 'auto',
                    }}
                >
                    <Tree
                        checkable
                        defaultExpandAll
                        checkStrictly
                        checkedKeys={permissions}
                        onCheck={handleCheckPermission}
                        selectable={false}
                    >
                        {renderTreeNodes(permissionData)}
                    </Tree>
                </div>
                {hasEditPermission && (
                    <Flex justify="flex-end" style={{ marginTop: 16 }}>
                        <Button
                            type="primary"
                            onClick={async () => {
                                const formValues = form.getFieldsValue();
                                console.log('Form values:', formValues);

                                const values = Object.values(formValues).filter(
                                    (value): value is string => typeof value === 'string',
                                );

                                const { id, name, status, operator, updated_at } = role;
                                const res = await updateRoleApi(id, {
                                    name,
                                    status,
                                    operator,
                                    permissions: [...filterPermissions(permissionData, permissions), ...values],
                                    updated_at, // 包含Radio.Group的选择
                                });

                                if (get(res, 'data.code') === 0) {
                                    message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                                } else {
                                    message.error(
                                        get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })),
                                    );
                                }
                            }}
                        >
                            <FormattedMessage id="b.save" />
                        </Button>
                    </Flex>
                )}
            </Form>
        </Card>
    );
};

export default RolePermission;
