import type { TreeDataNode } from 'antd';
import { FormattedMessage } from 'react-intl';

export interface ExtendedTreeDataNode extends TreeDataNode {
    children?: ExtendedTreeDataNode[];
    type?: 'data' | 'action';
    defaultChecked?: string;
    options?: {
        label: React.ReactNode;
        value: string;
    }[];
}

export const permissionData: ExtendedTreeDataNode[] = [
    {
        title: <FormattedMessage id="pm.Users" />,
        key: 'Users',
        children: [
            {
                title: <FormattedMessage id="pm.Users_Users" />,
                key: 'Users_Users',
                children: [
                    {
                        title: <FormattedMessage id="pm.Users_Users_Details" />,
                        key: 'Users_Users_Details',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Users_Users_Details_Mute" />,
                                key: 'Users_Users_Details_Mute',
                            },
                            {
                                title: <FormattedMessage id="pm.Users_Users_Details_Disable" />,
                                key: 'Users_Users_Details_Disable',
                            },
                            {
                                title: <FormattedMessage id="pm.Users_Users_Details_Unmute" />,
                                key: 'Users_Users_Details_Unmute',
                            },
                            {
                                title: <FormattedMessage id="pm.Users_Users_Details_Enable" />,
                                key: 'Users_Users_Details_Enable',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Users_Users_Export" />,
                        key: 'Users_Users_Export',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Users_Sanction-History" />,
                key: 'Users_Sanction-History',
            },
            {
                title: <FormattedMessage id="pm.Users_Usernames" />,
                key: 'Users_Usernames',
                children: [
                    {
                        title: <FormattedMessage id="pm.Users_Usernames_Add" />,
                        key: 'Users_Usernames_Add',
                    },
                    {
                        title: <FormattedMessage id="pm.Users_Usernames_Import" />,
                        key: 'Users_Usernames_Import',
                    },
                    {
                        title: <FormattedMessage id="pm.Users_Usernames_Delete" />,
                        key: 'Users_Usernames_Delete',
                    },
                    {
                        title: <FormattedMessage id="pm.Users_Usernames_Bulk-Delete" />,
                        key: 'Users_Usernames_Bulk-Delete',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Content" />,
        key: 'Content',
        children: [
            {
                title: <FormattedMessage id="pm.Content_Posts" />,
                key: 'Content_Posts',
            },
            {
                title: <FormattedMessage id="pm.Content_Shorts" />,
                key: 'Content_Shorts',
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Ambassadors" />,
        key: 'Ambassadors',
        selectable: false,
        children: [
            {
                title: <FormattedMessage id="pm.Ambassadors_Applications" />,
                key: 'Ambassadors_Applications',
                children: [
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Applications_Public-Pool" />,
                        key: 'Ambassadors_Applications_Public-Pool',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Ambassadors_Applications_Public-Pool_Assign" />,
                                key: 'Ambassadors_Applications_Public-Pool_Assign',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Applications_Private-Pool" />,
                        key: 'Ambassadors_Applications_Private-Pool',
                        type: 'data',
                        defaultChecked: 'Ambassadors_Applications_Private-Pool_All-Data',
                        options: [
                            {
                                label: <FormattedMessage id="pm.Ambassadors_Applications_Private-Pool_All-Data" />,
                                value: 'Ambassadors_Applications_Private-Pool_All-Data',
                            },
                            {
                                label: <FormattedMessage id="pm.Ambassadors_Applications_Private-Pool_Personal-Data" />,
                                value: 'Ambassadors_Applications_Private-Pool_Personal-Data',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Applications_Review-Records" />,
                        key: 'Ambassadors_Applications_Review-Records',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Ambassadors_Ambassadors" />,
                key: 'Ambassadors_Ambassadors',
                children: [
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Ambassadors_Public-Pool" />,
                        key: 'Ambassadors_Ambassadors_Public-Pool',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Ambassadors_Ambassadors_Public-Pool_Assign" />,
                                key: 'Ambassadors_Ambassadors_Public-Pool_Assign',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Ambassadors_Ambassadors" />,
                        key: 'Ambassadors_Ambassadors_Ambassadors',
                        type: 'data',
                        defaultChecked: 'Ambassadors_Ambassadors_Ambassadors_Personal-Data',
                        options: [
                            {
                                label: <FormattedMessage id="pm.Ambassadors_Ambassadors_Ambassadors_All-Data" />,
                                value: 'Ambassadors_Ambassadors_Ambassadors_All-Data',
                            },
                            {
                                label: <FormattedMessage id="pm.Ambassadors_Ambassadors_Ambassadors_Personal-Data" />,
                                value: 'Ambassadors_Ambassadors_Ambassadors_Personal-Data',
                            },
                        ],
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Ambassadors_Data" />,
                key: 'Ambassadors_Data',
                children: [
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Data_Ambassador-Data" />,
                        key: 'Ambassadors_Data_Ambassador-Data',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Ambassadors_Data_Ambassador-Data_Export" />,
                                key: 'Ambassadors_Data_Ambassador-Data_Export',
                            },
                        ],
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Ambassadors_Configurations" />,
                key: 'Ambassadors_Configurations',
                children: [
                    {
                        title: <FormattedMessage id="pm.Ambassadors_Configurations_Private-Pool-Config" />,
                        key: 'Ambassadors_Configurations_Private-Pool-Config',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Ambassadors_Configurations_Private-Pool-Config_Add" />,
                                key: 'Ambassadors_Configurations_Private-Pool-Config_Add',
                            },
                            {
                                title: <FormattedMessage id="pm.Ambassadors_Configurations_Private-Pool-Config_Edit" />,
                                key: 'Ambassadors_Configurations_Private-Pool-Config_Edit',
                            },
                            {
                                title: (
                                    <FormattedMessage id="pm.Ambassadors_Configurations_Private-Pool-Config_Delete" />
                                ),
                                key: 'Ambassadors_Configurations_Private-Pool-Config_Delete',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Incentives" />,
        key: 'Incentives',
        children: [
            {
                title: <FormattedMessage id="pm.Incentives_Incentive-Seasons" />,
                key: 'Incentives_Incentive-Seasons',
                children: [
                    {
                        title: <FormattedMessage id="pm.Incentives_Incentive-Seasons_Add" />,
                        key: 'Incentives_Incentive-Seasons_Add',
                    },
                    {
                        title: <FormattedMessage id="pm.Incentives_Incentive-Seasons_Edit" />,
                        key: 'Incentives_Incentive-Seasons_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.Incentives_Incentive-Seasons_Delete" />,
                        key: 'Incentives_Incentive-Seasons_Delete',
                    },
                ],
            },
            {
                title: <FormattedMessage id="m.Incentives_Task-Config" />,
                key: 'Incentives_Task-Config',
                children: [
                    {
                        title: <FormattedMessage id="pm.Incentives_Task-Config_Add" />,
                        key: 'Incentives_Task-Config_Add',
                    },
                    {
                        title: <FormattedMessage id="pm.Incentives_Task-Config_Edit" />,
                        key: 'Incentives_Task-Config_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.Incentives_Task-Config_Delete" />,
                        key: 'Incentives_Task-Config_Delete',
                    },
                    {
                        title: <FormattedMessage id="pm.Incentives_Task-Config_Sort" />,
                        key: 'Incentives_Task-Config_Sort',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Creator_Center" />,
        key: 'Creator-Center',
        children: [
            {
                title: <FormattedMessage id="pm.Creator_Center-Topic" />,
                key: 'Creator-Center_Topic',
            },
            {
                title: <FormattedMessage id="pm.Creator_Center-Creator_Hub" />,
                key: 'Creator-Center_Creator-Hub',
            },
            {
                title: <FormattedMessage id="pm.Creator_Center-Top_Posts" />,
                key: 'Creator-Center_Top-Posts',
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Reports" />,
        key: 'Reports',
        children: [
            {
                title: <FormattedMessage id="pm.Reports_Report-Moderation" />,
                key: 'Reports_Report-Moderation',
                children: [
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Posts" />,
                        key: 'Reports_Report-Moderation_Posts',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Replies" />,
                        key: 'Reports_Report-Moderation_Replies',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Profiles" />,
                        key: 'Reports_Report-Moderation_Profiles',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Reports_Moderation-Records" />,
                key: 'Reports_Moderation-Records',
                children: [
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Post-Moderation-Records" />,
                        key: 'Reports_Moderation-Records_Post-Moderation-Records',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Reply-Moderation-Records" />,
                        key: 'Reports_Moderation-Records_Reply-Moderation-Records',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Profile-Moderation-Records" />,
                        key: 'Reports_Moderation-Records_Profile-Moderation-Records',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Client-Config" />,
        key: 'Client-Config',
        children: [
            {
                title: <FormattedMessage id="pm.Client-Config_Plaza-Config" />,
                key: 'Client-Config_Plaza-Config',
            },
            {
                title: <FormattedMessage id="pm.Client-Config_Super-Users" />,
                key: 'Client-Config_Super-Users',
                children: [
                    {
                        title: <FormattedMessage id="pm.Client-Config_Super-Users_Add" />,
                        key: 'Client-Config_Super-Users_Add',
                    },
                    {
                        title: <FormattedMessage id="pm.Client-Config_Super-Users_Edit" />,
                        key: 'Client-Config_Super-Users_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.Client-Config_Super-Users_Delete" />,
                        key: 'Client-Config_Super-Users_Delete',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Client-Config_Domain" />,
                key: 'Client-Config_Domain',
            },
            {
                title: <FormattedMessage id="pm.Client-Config_App-Version" />,
                key: 'Client-Config_App-Version',
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Marketplace" />,
        key: 'Marketplace',
        children: [
            {
                title: <FormattedMessage id="pm.Marketplace_NFT" />,
                key: 'Marketplace_NFT',
                children: [
                    {
                        title: <FormattedMessage id="pm.Marketplace_NFT_NFT-Collection" />,
                        key: 'Marketplace_NFT_NFT-Collection',
                    },
                    {
                        title: <FormattedMessage id="pm.Marketplace_NFT_NFT" />,
                        key: 'Marketplace_NFT_NFT',
                    },
                    {
                        title: <FormattedMessage id="pm.Marketplace_NFT_NFT-Marketplace" />,
                        key: 'Marketplace_NFT_NFT-Marketplace',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Marketplace_Token" />,
                key: 'Marketplace_Token',
                children: [
                    {
                        title: <FormattedMessage id="pm.Marketplace_Token_Token-Config" />,
                        key: 'Marketplace_Token_Token-Config',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Token-Config_Edit" />,
                                key: 'Marketplace_Token_Token-Config_Edit',
                            },
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Token-Config_Sort" />,
                                key: 'Marketplace_Token_Token-Config_Sort',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Marketplace_Token_Pool" />,
                        key: 'Marketplace_Token_Pool',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Pool_Create" />,
                                key: 'Marketplace_Token_Pool_Add',
                            },
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Pool_Edit" />,
                                key: 'Marketplace_Token_Pool_Edit',
                            },
                        ],
                    },
                    {
                        title: <FormattedMessage id="pm.Marketplace_Token_Top-Token" />,
                        key: 'Marketplace_Token_Top-Token',
                        children: [
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Top-Token_Add" />,
                                key: 'Marketplace_Token_Top-Token_Add',
                            },
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Top-Token_Sort" />,
                                key: 'Marketplace_Token_Top-Token_Sort',
                            },
                            {
                                title: <FormattedMessage id="pm.Marketplace_Token_Top-Token_Delete" />,
                                key: 'Marketplace_Token_Top-Token_Delete',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Airdrop" />,
        key: 'Airdrop',
        children: [
            {
                title: <FormattedMessage id="pm.Airdrop_Airdrop-Seasons" />,
                key: 'Airdrop_Airdrop-Seasons',
                children: [
                    {
                        title: <FormattedMessage id="pm.Airdrop_Airdrop-Seasons_Create" />,
                        key: 'Airdrop_Airdrop-Seasons_Create',
                    },
                    {
                        title: <FormattedMessage id="pm.Airdrop_Airdrop-Seasons_Edit" />,
                        key: 'Airdrop_Airdrop-Seasons_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.Airdrop_Airdrop-Seasons_Delete" />,
                        key: 'Airdrop_Airdrop-Seasons_Delete',
                    },
                    {
                        title: <FormattedMessage id="pm.Airdrop_Airdrop-Seasons_Distribute" />,
                        key: 'Airdrop_Airdrop-Seasons_Distribute',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Airdrop_Airdrop-Config" />,
                key: 'Airdrop_Airdrop-Config',
                children: [
                    {
                        title: <FormattedMessage id="pm.Airdrop_Airdrop-Config_Edit" />,
                        key: 'Airdrop_Airdrop-Config_Edit',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.System-Management" />,
        key: 'System-Management',
        children: [
            {
                title: <FormattedMessage id="pm.System-Management_Accounts" />,
                key: 'System-Management_Accounts',
                children: [
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Create" />,
                        key: 'System-Management_Accounts_Create',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Edit" />,
                        key: 'System-Management_Accounts_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Delete" />,
                        key: 'System-Management_Accounts_Delete',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.System-Management_Roles" />,
                key: 'System-Management_Roles',
                children: [
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Create" />,
                        key: 'System-Management_Roles_Create',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Edit" />,
                        key: 'System-Management_Roles_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Delete" />,
                        key: 'System-Management_Roles_Delete',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Permissions" />,
                        key: 'System-Management_Permissions',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.System-Management_Logs" />,
                key: 'System-Management_Logs',
            },
            {
                title: <FormattedMessage id="pm.System-Management_Exports" />,
                key: 'System-Management_Downloads',
                type: 'data',
                defaultChecked: 'System-Management_Downloads_Personal-Data',
                options: [
                    {
                        label: <FormattedMessage id="pm.System-Management_Exports_Exports_All-Data" />,
                        value: 'System-Management_Downloads_All-Data',
                    },
                    {
                        label: <FormattedMessage id="pm.System-Management_Exports_Exports_Personal-Data" />,
                        value: 'System-Management_Downloads_Personal-Data',
                    },
                ],
            },
        ],
    },
];
