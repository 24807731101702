import React from 'react';
import { Space, Image } from 'antd';

/**
 * Types
 */
// import type { ImageProps } from 'antd';

/**
 * Constants
 */
const DEFAULT_COVER = '/video.png';
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Props
 */
type Props = {
    list: string[];
};

const ComponentTableCellMedia: React.FC<Props> = ({ list }) => {
    if (!list) {
        return '-';
    }
    const children: React.ReactNode[] = [];
    list.forEach((src: string, index: number) => {
        if (src.startsWith('video')) {
            children.push(
                <Image
                    src={DEFAULT_COVER}
                    preview={{
                        destroyOnClose: true,
                        imageRender: () => (
                            <video
                                width="80%"
                                style={{ objectFit: 'revert', maxHeight: '96vh' }}
                                autoPlay
                                controls
                                src={`${WEB2_ASSETS_URL}/${src}`}
                            />
                        ),
                        toolbarRender: () => null,
                    }}
                    width={80}
                    key={index}
                />,
            );
        } else if (src.startsWith('image')) {
            children.push(<Image src={`${WEB2_ASSETS_URL}/${src}`} width={80} key={index} />);
        }
    });
    if (!children.length) {
        return '-';
    }
    return <Space wrap={true}>{children}</Space>;
};

export default ComponentTableCellMedia;
