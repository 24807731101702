import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

/**
 * Contexts
 */
import { useLayoutStore } from '@/store/layout';

/**
 * Types
 */
import type { DropdownProps } from 'antd';

const ComponentLanguage: React.FC = () => {
    /**
     * States
     */
    const { locale, setLocale } = useLayoutStore();

    /**
     * ChildrenProps
     */
    const dropdownProps: DropdownProps = {
        menu: {
            activeKey: locale,
            items: [
                {
                    key: 'en-US',
                    label: (
                        <>
                            <span style={{ marginRight: 8 }}>🇺🇸</span>English
                        </>
                    ),
                },
                {
                    key: 'zh-CN',
                    label: (
                        <>
                            <span style={{ marginRight: 8 }}>🇨🇳</span>简体中文
                        </>
                    ),
                },
            ],
            onClick: ({ key }) => {
                setLocale(key);

                // window.location.reload();
            },
        },
        overlayStyle: {
            minWidth: 120,
        },
        placement: 'bottomRight',
    };

    return (
        <Dropdown {...dropdownProps}>
            <GlobalOutlined />
        </Dropdown>
    );
};

export default ComponentLanguage;
