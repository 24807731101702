import { HolderOutlined } from '@ant-design/icons';
import { DragSortTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Popconfirm, Segmented, Spin, Tag, Typography } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { taskConfigs, taskConfigsSorts, dTaskConfigs } from '@/services/task-config';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentDrawerFormCustom from './components/drawer-form-custom';

/**
 * Constants
 */

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { Task, TaskConfigsParams } from '@/services/task-config';

type State = {
    dataSource: Task[];
    entity: Task | null;
    openDrawer: boolean;
    sorting: boolean;
};

const PageAmbassadorsApplicationsReviewRecords: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
        sorting: false,
    });
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<TaskConfigsParams>({
        group: 'web2',
    });

    /**
     * Callbacks
     */
    const handleDragSortEnd = useCallback((_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
        setState({
            dataSource: newDataSource,
        });
    }, []);

    /**
     * Requests
     */
    const {
        loading: loadingTaskConfigs,
        refresh: refreshTaskConfigs,
        run: runTaskConfigs,
    } = useRequest(taskConfigs, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setState({
                dataSource: data,
            });
        },
    });
    // Sort
    const { loading: loadingTaskConfigsSorts, run: runTaskConfigsSorts } = useRequest(taskConfigsSorts, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            refreshTaskConfigs();
            setState({
                sorting: false,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<Task, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                fixed: 'left',
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'task_type_id',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.taskTypeId" />,
                width: 200,
            },
            {
                dataIndex: 'sub_task_id',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.subTaskId" />,
                width: 200,
            },
            {
                dataIndex: 'type',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.type" />,
                valueEnum: {
                    daily: <FormattedMessage id="tc.type.daily" />,
                    period: <FormattedMessage id="tc.type.period" />,
                    once: <FormattedMessage id="tc.type.once" />,
                },
                width: 120,
            },
            {
                dataIndex: 'title',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.taskTitle" />,
            },
            {
                dataIndex: 'desc',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.taskDesc" />,
                width: 200,
            },
            {
                dataIndex: 'value',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.paramsN" />,
                width: 120,
            },
            {
                dataIndex: 'integral',
                ellipsis: true,
                title: <FormattedMessage id="tc.t.c.awardValue" />,
                width: 120,
            },
            {
                dataIndex: ['action', 'android'],
                ellipsis: true,
                title: intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'Android' }),
                width: 120,
            },
            {
                dataIndex: ['action', 'ios'],
                ellipsis: true,
                title: intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'IOS' }),
                width: 120,
            },
            {
                dataIndex: ['action', 'web'],
                ellipsis: true,
                title: intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'Web' }),
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="tc.t.c.status" />,
                render: (status) => (
                    <Tag color={status === 'open' ? 'green' : 'orange'}>
                        {status === 'open' ? 'Enabled' : 'Disabled'}
                    </Tag>
                ),
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Permission permission="Incentives_Task-Config_Edit" key="detail">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    <Permission permission="Incentives_Task-Config_Delete" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    dTaskConfigs({ ids: [entity.id] })
                                        .then(({ data: { code, msg } }) => {
                                            setCellLoading({ [entity.id]: false });
                                            if (code !== 0) {
                                                return message.error(msg || 'Error');
                                            }
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            refreshTaskConfigs();
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                            message.error('Error');
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading: loadingTaskConfigs,
        options: {
            reload: refreshTaskConfigs,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 2260,
            y: `calc(100vh - 288px)`,
        },
        search: false,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingTaskConfigsSorts}
                        onClick={() => {
                            runTaskConfigsSorts({ ids: state.dataSource.map((item) => item.id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingTaskConfigsSorts}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshTaskConfigs();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Segmented<'web2' | 'web3'>
                    options={['web2', 'web3']}
                    value={payload.group}
                    onChange={(value) => {
                        setPayload({ group: value });
                    }}
                    key="group"
                />,
                <Permission permission="Incentives_Task-Config_Add" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Incentives_Task-Config_Sort" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: handleDragSortEnd,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runTaskConfigs({
            group: payload.group,
        });
    }, [payload]);

    return (
        <>
            <DragSortTable {...proTableProps} />
            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                group={payload.group}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshTaskConfigs();
                }}
            />
        </>
    );
};

export default PageAmbassadorsApplicationsReviewRecords;
