export default {
    tc: {
        b: {
            sureSort: 'Confirm',
            cancelSort: 'Cancel',
        },
        t: {
            c: {
                sort: 'Sort',
                taskTypeId: 'Category ID',
                subTaskId: 'Subtask ID',
                taskTitle: 'Title(EN)',
                taskDesc: 'Description(EN)',
                paramsN: 'Parameter n',
                awardValue: 'Reward Points',
                action: 'Redirect（{value}）',
                type: 'Tpye',
                status: 'Status',
            },
        },
        type: {
            daily: 'Daily',
            period: 'Period',
            once: 'Once',
        },
        f: {
            language: 'Language',
            title: 'Title',
            desc: 'Description',
            group: 'Group',
        },
        i: {
            incentivesInProgress: 'An active season is in progress',
        },
    },
};
