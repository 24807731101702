export default {
    p: {
        ush: {
            columns: {
                user: '用户',
                type: '类型',
                detail: '操作详情',
                remark: '备注',
            },
            action: {
                mute: '禁言',
                disable: '封号',
                unmute: '解除禁言',
                enabled: '解除封号',
            },
            types: {
                temp_mute: '临时禁言',
                perma_mute: '永久禁言',
                temp_ban: '临时封号',
                perma_ban: '永久封号',
                unmute: '解除禁言',
                unban: '解除封号',
            },
        },
    },
};
