import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取帖子列表
export const shorts = (params: ShortsParams) => {
    return axios<PageResType<ShortsItem>>('/v1/shorts', {
        method: 'get',
        params,
    });
};

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';

/**
 * 短视频，短视频表
 */
export type ShortsItem = {
    /**
     * 火山云视频编号
     */
    byte_video_id?: string;
    /**
     * 收藏数量
     */
    collect_count: number;
    /**
     * 评论数量
     */
    comment_count: number;
    /**
     * 封面
     */
    cover: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 删除时间，有表示已删除
     */
    deleted_at?: string;
    /**
     * 视频内容描述
     */
    description: string;
    /**
     * 高度
     */
    height: number;
    /**
     * 大尺寸视频地址
     */
    large_url: string;
    /**
     * 评分人数
     */
    rating_count: number;
    /**
     * 总评分
     */
    score: number;
    /**
     * 分享数量
     */
    share_count: number;
    /**
     * 小尺寸视频地址
     */
    small_url: string;
    /**
     * 视频原地址
     */
    source_url: string;
    /**
     * 视频状态
     */
    status: number;
    /**
     * 视频类型
     */
    type: number;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 用户编号
     */
    user_id: string;
    /**
     * 版本号
     */
    version: number;
    /**
     * 视频编号
     */
    video_id: string;
    /**
     * 宽度
     */
    width: number;
    [property: string]: any;
};

/**
 * 分页
 */
export interface Meta {
    /**
     * 当前页数
     */
    currentPage: number;
    /**
     * 最后一页
     */
    lastPage: number;
    /**
     * 下一页
     */
    next: number;
    /**
     * 每页条数
     */
    perPage: number;
    /**
     * 上一页
     */
    prev: number;
    /**
     * 总记录数
     */
    total: number;
}

export type ShortsParams = {
    create_end?: string;
    create_start?: string;
    /**
     * 正文搜索
     */
    description?: string;
    /**
     * 是否删除
     */
    is_deleted?: 'true' | 'false';
    /**
     * 每页条数
     */
    limit?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    user_id?: string;
    video_id?: string;
};

export type FeShortsParams = ShortsParams & {
    operation_date?: any;
    fe_is_deleted?: string;
};
