import { ProCard, ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Drawer, Flex, Form, Input, Select, Spin, Tooltip, Typography } from 'antd';
import { get, isEqual, mapValues } from 'lodash';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import store from 'store2';

/**
 * APIs
 */
import {
    ambassadorApplicationsPrivate,
    bulkUAmbassadorApplicationPrivate,
    privatePoolConfigs,
} from '@/services/ambassadors';

/**
 * Components
 */
import ComponentDetail from './components/detail';
import SearchUser from '@/components/search-user';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, PaginationProps } from 'antd';
import type {
    AmbassadorApplicationsParams,
    AmbassadorApplicationsResult,
    PrivatePoolConfigsResult,
} from '@/services/ambassadors';
import Permission from '@/components/permission';

type State = {
    configs: PrivatePoolConfigsResult[];
    entity: AmbassadorApplicationsResult | null;
    open: boolean;
    openDetail: boolean;
    selectedRowKeys: string[];
};

/**
 * Constants
 */
const defaultPayload: AmbassadorApplicationsParams = {
    apply_end: undefined,
    apply_start: undefined,
    nickname: undefined,
    request_email: undefined,
    user_id: undefined,
    username: undefined,
};

const PageAmbassadorsApplicationsPrivatePool: React.FC = () => {
    /**
     * Hooks
     */
    const { message, modal } = App.useApp();

    const [form] = Form.useForm();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [loading, setLoading] = useSetState<Record<string, boolean>>({});

    const [state, setState] = useSetState<State>({
        configs: [],
        entity: null,
        open: false,
        openDetail: false,
        selectedRowKeys: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorApplicationsParams>({
        ...defaultPayload,
    });

    /**
     * Requests
     */
    const { loading: loadingBulkUAmbassadorApplication, run: runBulkUAmbassadorApplication } = useRequest(
        bulkUAmbassadorApplicationPrivate,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }, params) => {
                if (code !== 0) {
                    return message.error(msg);
                }

                // Close the drawer
                setState({
                    open: false,
                    openDetail: false,
                    selectedRowKeys: state.selectedRowKeys.filter((key) => !params[0].ids.includes(key)),
                });

                // Reload the table
                tableRef.current?.reload?.();
            },
        },
    );

    useRequest(privatePoolConfigs, {
        defaultParams: [{ limit: 9999, page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                configs: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassadorApplication} type="primary" onClick={() => form.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id="b.reject" />,
        zIndex: 1100,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const drawerDetailProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            openDetail: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button
                    danger={true}
                    onClick={() => {
                        form.setFieldsValue({
                            ids: [state.entity!.id],
                        });

                        setState({
                            open: true,
                        });
                    }}
                >
                    <FormattedMessage id="b.reject" />
                </Button>

                <Button
                    loading={loadingBulkUAmbassadorApplication}
                    type="primary"
                    onClick={() => {
                        runBulkUAmbassadorApplication({
                            ids: [state.entity!.id],
                            status: 'approved',
                        });
                    }}
                >
                    <FormattedMessage id="b.approve" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.openDetail,
        size: 'large',
        title: <FormattedMessage id="b.details" />,
        onClose: () => {
            setState({
                openDetail: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        initialValues: {
            reject_reason:
                'We regret to inform you, your application information does not meet the requirements for an ambassador.',
        },
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassadorApplication({
                ...values,
                status: 'rejected',
            });
        },
    };

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.apply_date) {
                values.apply_end = values.apply_date[1].endOf('d').format();
                values.apply_start = values.apply_date[0].startOf('d').format();
                values.apply_date = undefined;
            }

            setState({
                selectedRowKeys: [],
            });

            setPagination({
                current: 1,
                pageSize: 20,
            });

            const assignPayload = {
                ...defaultPayload,
                ...mapValues(values, (value) => (value === '' ? undefined : value)),
            };

            isEqual(payload, assignPayload) ? tableRef.current?.reload?.() : setPayload(assignPayload);
        },
    };

    const proTableProps: ProTableProps<AmbassadorApplicationsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['request', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                width: 200,
            },
            {
                dataIndex: ['request', 'social_media_presence', 'link'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.link" />,
                renderText: (text) => (
                    <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                dataIndex: ['request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.appliedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Spin key="approve" size="small" spinning={loading[entity.id] === true}>
                        <a
                            onClick={() => {
                                setLoading({
                                    [entity.id]: true,
                                });

                                bulkUAmbassadorApplicationPrivate({
                                    ids: [entity.id],
                                    status: 'approved',
                                })
                                    .then(() => {
                                        setState({
                                            selectedRowKeys: state.selectedRowKeys.filter((key) => key !== entity.id),
                                        });

                                        tableRef.current?.reload?.();
                                    })
                                    .finally(() => {
                                        setLoading({
                                            [entity.id]: false,
                                        });
                                    });
                            }}
                        >
                            <FormattedMessage id="b.approve" />
                        </a>
                    </Spin>,
                    <a
                        key="detail"
                        onClick={() => {
                            setState({
                                entity,
                                openDetail: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.details" />
                    </a>,
                    <a
                        key="reject"
                        onClick={() => {
                            form.setFieldsValue({
                                ids: [entity.id],
                            });

                            setState({
                                open: true,
                            });
                        }}
                    >
                        <Typography.Text type="danger">
                            <FormattedMessage id="b.reject" />
                        </Typography.Text>
                    </a>,
                ],
            },
        ],
        pagination,
        params: payload,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                setState({
                    selectedRowKeys: selectedRowKeys as string[],
                });
            },
        },
        scroll: {
            x: 1420,
            y: `calc(100vh - 224px - 48px)`,
        },
        search: false,
        onLoadingChange: (loading) => {
            setLoading({
                table: loading as boolean,
            });
        },
        request: async (params) => {
            const res = await ambassadorApplicationsPrivate({
                ...params,
                account_id: store('user').id,
                limit: params.pageSize,
                page: params.current,
                statuses: ['pending'],
            });

            if (get(res, 'data.code') !== 0) {
                message.error(get(res, 'data.msg'));
            }

            setState({
                selectedRowKeys: [],
            });

            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
        tableAlertOptionRender: false,
        tableAlertRender: false,
        toolbar: {
            actions: [
                state.selectedRowKeys.length ? (
                    <Button
                        key="bulkApprove"
                        type="primary"
                        onClick={() => {
                            modal.confirm({
                                okText: formatMessage({ id: 'b.confirm' }),
                                title: formatMessage({ id: 'c.areyousureBulk' }),
                                onOk() {
                                    runBulkUAmbassadorApplication({
                                        ids: state.selectedRowKeys,
                                        status: 'approved',
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="p.ambassadors.b.bulkApprove" />
                    </Button>
                ) : null,
            ],
        },
    };

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Tooltip title={formatMessage({ id: 'p.ambassadors.tips.privatePool.email' })}>
                            <Form.Item name="request_email" style={{ marginRight: 0 }}>
                                <Input allowClear={true} placeholder="Email" style={{ width: 200 }} />
                            </Form.Item>
                        </Tooltip>

                        <Permission permission="Ambassadors_Applications_Private-Pool_All-Data">
                            <Form.Item name="account_id" style={{ marginRight: 0 }}>
                                <Select
                                    allowClear={true}
                                    optionFilterProp="label"
                                    options={state.configs.map((config) => ({
                                        label: config.account.name,
                                        value: config.account.id,
                                    }))}
                                    placeholder={<FormattedMessage id="t.c.owner" />}
                                    showSearch={true}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                        </Permission>

                        <Form.Item
                            label={formatMessage({ id: 't.c.appliedAt' })}
                            name="apply_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading['table'] === true}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 'p.ambassadors.privatePool.rejectReason' })}
                        name="reject_reason"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea autoSize={{ minRows: 3 }} />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerDetailProps}>
                <ComponentDetail dataSource={state.entity!} />
            </Drawer>
        </>
    );
};

export default PageAmbassadorsApplicationsPrivatePool;
