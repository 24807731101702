export default {
    // creator topic
    ctp: {
        t: {
            c: {
                topic: 'Topic',
            },
        },
    },
    cpost: {
        e: {
            postExit: 'Post already exists',
            postNotFound: 'Post not found',
        },
    },
    cad: {
        i: {
            empty: 'Please click the type on the left to configure content',
        },
        t: {
            headerTitle: 'Type List',
            c: {
                name: 'Name',
                postOrShortsId: 'Post ID/Shorts ID',
                title: 'Title',
                cover: 'Cover',
            },
        },
    },
};
