import { useEffect } from 'react';
import { useBoolean } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Form, Button, Select, Input, App } from 'antd';
import { get } from 'lodash';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import { createUserNameApi } from '@/services/user-name';

export type ModalFormData = {
    id: string;
    account: string;
    password: string;
    role: string;
    status: boolean;
};

export type ModalFormState = {
    open: boolean;
    data?: ModalFormData;
};

type Props = {
    state: ModalFormState;
    reload?: () => void;
    onClose: () => void;
};

const ModalFormUserName: React.FC<Props> = ({ state, onClose, reload }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.resetFields();
            setSubmittingFlase();
        } else if (state.data) {
            form.setFieldsValue({
                account: state.data.account,
                password: state.data.password,
                role: state.data.role,
                status: state.data.status,
            });
        }
    }, [state]);

    const handleFinish = async (formData: any) => {
        setSubmittingTrue();
        const res = await createUserNameApi({
            ...formData,
        });
        if (get(res, 'data.code') === 0) {
            message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
            onClose();
            reload?.();
        } else {
            message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
        }
        setSubmittingFlase();
    };

    /**
     * ChildrenProps
     */
    const drawerProps = {
        title: state.data?.id ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        onClose: () => onClose(),
        maskClosable: false,
        open: state.open,
        destroyOnClose: true,
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={submitting}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    return (
        <Drawer {...drawerProps}>
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="type"
                    label={<FormattedMessage id="common.type" />}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'notEmpty' }) }]}
                >
                    <Select
                        showSearch
                        optionFilterProp="label"
                        placeholder={intl.formatMessage({ id: 'p.us.ph.type' })}
                        options={[
                            { label: intl.formatMessage({ id: 'p.us.type.company' }), value: 'company' },
                            { label: intl.formatMessage({ id: 'p.us.type.organisation' }), value: 'organisation' },
                            { label: intl.formatMessage({ id: 'p.us.type.country' }), value: 'country' },
                            { label: intl.formatMessage({ id: 'p.us.type.celebrity' }), value: 'celebrity' },
                            { label: intl.formatMessage({ id: 'p.us.type.fancyNo' }), value: 'fancy_no' },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="name"
                    label={<FormattedMessage id="common.userName" />}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'notEmpty' }) }]}
                >
                    <Input
                        placeholder={intl.formatMessage({ id: 'p.us.ph.name' })}
                        maxLength={20}
                        autoComplete="off"
                        showCount
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ModalFormUserName;
