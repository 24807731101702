import axios from './index';

/**
 * APIs
 */
// 获取操作人列表
export function operators(params: OperatorsParams) {
    return axios<PageResType<OperatorsResponse>>('/v1/operators', {
        method: 'get',
        params,
    });
}

/**
 * Types
 */
import { PageResType } from '@repo/typings/common';

// 获取操作人列表
export type OperatorsParams = {
    limit?: number | string;
    page?: number | string;
} & Pick<OperatorsResponse, 'model'>;

export type OperatorsResponse = {
    account: {
        id: string;
        name: string;
        status: number;
    };
    account_id: string;
    created_at: string;
    deleted_at: string;
    id: string;
    /**
     * 模块
     *
     * @desc
     * 1 大使计划-大使申请-审核记录-操作人
     * 2 系统管理-系统日志-操作人
     */
    model: string;
    updated_at: string;
};
