import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { isEmpty, omitBy } from 'lodash';

/**
 * Components
 */
import ComponentTableCellReason from '../components/table-cell-reason';
import ComponentSearchFormSelectOperator from '../components/search-form-select-operator';

/**
 * APIs
 */
import { profileReportApi, ProfileReportRequestsParams, Status } from '@/services/report';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { ProfileReportResult } from '@/services/report';

type State = {
    dataSource: ProfileReportResult[];
};

/**
 * Constants
 */
import ReportTypeENUM from '../components/report-type-enum';
const initStatusArrary = [Status.Normal, Status.Violate];

const PageReportReviewRecordsPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<ProfileReportRequestsParams>({
        status: [...initStatusArrary],
    });

    const {
        loading: loadingProfileReportApi,
        refresh: refreshProfileReportApi,
        run: runProfileReportApi,
    } = useRequest(profileReportApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.operation_date) {
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_date = undefined;
            }
            values.status = values.status ? [values.status as unknown as Status] : [...initStatusArrary];
            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
                status: values.status,
            });
        },
    };

    const proTableProps: ProTableProps<ProfileReportResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reportId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'user_id'],
                ellipsis: true,
                title: 'UID',
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'nickname'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.nickName" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'username'],
                title: <FormattedMessage id="rm.t.c.name" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'avatar'],
                title: <FormattedMessage id="rm.t.c.avatar" />,
                valueType: 'avatar',
                width: 80,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'bio'],
                title: <FormattedMessage id="rm.t.c.userInfo" />,
            },
            {
                dataIndex: 'report_type',
                title: <FormattedMessage id="rm.t.c.reportType" />,
                valueEnum: ReportTypeENUM,
                width: 120,
            },
            {
                dataIndex: 'reason',
                title: <FormattedMessage id="rm.t.c.otherReason" />,
                render: (_, entity) => <ComponentTableCellReason type={entity.report_type} reason={entity.reason} />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportUserInfo', 'user_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reporter-UID" />,
                width: 200,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="rm.t.c.reportTime" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="rm.t.c.review-result" />,
                valueEnum: {
                    normal: <FormattedMessage id="rm.b.normal" />,
                    delete: <FormattedMessage id="b.delete" />,
                },
                width: 120,
            },
            {
                dataIndex: 'operator',
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingProfileReportApi,
        options: {
            reload: refreshProfileReportApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 2290,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight || 64}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runProfileReportApi({
            ...payload,
            page: pagination.current as any,
            size: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.reportId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="UID" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="report_uid" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.reporter-UID' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: <FormattedMessage id="rm.b.normal" />,
                                        value: 'normal',
                                    },
                                    {
                                        label: <FormattedMessage id="rm.b.violate" />,
                                        value: 'violate',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="rm.t.c.review-result" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingProfileReportApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageReportReviewRecordsPosts;
