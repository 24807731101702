import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Upload, Form, Drawer, App } from 'antd';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { uploadUserName } from '@/services/user-name';

const { Dragger } = Upload;

/**
 * Types
 */
import type { IntlShape } from 'react-intl';

export type ModalFormUploadState = {
    open: boolean;
};

type Props = {
    state: ModalFormUploadState;
    reload: () => void;
    onClose: () => void;
};

let hideLoading: (() => void) | null = null;

const UserNameModalForm: React.FC<Props> = ({ state, reload, onClose }) => {
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.resetFields();
        }
    }, [state]);

    const handleUploadChange = (info: any) => {
        onClose();
        if (!hideLoading && info.file.status === 'uploading') {
            hideLoading = message.loading(intl.formatMessage({ id: 'p.us.msg.uploading' }), 0);
            setTimeout(hideLoading, 5000);
        }
        if (info.file.status === 'done') {
            hideLoading?.();
            const response = info.file.response;
            const number = response.data.data;
            if (response.data.code === 0 && number) {
                reload?.();
                message.success(intl.formatMessage({ id: 'p.us.msg.uploadSuccess' }, { number }) || '上传成功');
            } else {
                message.error(intl.formatMessage({ id: 'p.us.msg.uploadError' }) || '上传失败');
            }
        }
    };

    const customRequest = (options: any) => {
        uploadUserName({ file: options.file as File }).then(options.onSuccess, options.onError);
    };

    /**
     * ChildrenProps
     */
    const drawerProps = {
        title: <FormattedMessage id="b.import" />,
        width: 400,
        destroyOnClose: true,
        maskClosable: false,
        onClose: () => onClose(),
        visible: state.open,
        footer: null,
    };

    return (
        <Drawer {...drawerProps}>
            <Form form={form}>
                <Form.Item name="file" style={{ marginBottom: 8 }}>
                    <Dragger
                        name="file"
                        multiple={false}
                        accept=".xlsx,.xls,.csv"
                        maxCount={1}
                        showUploadList={false}
                        onChange={handleUploadChange}
                        customRequest={customRequest}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <FormattedMessage id="p.us.ph.upload" />
                        </p>
                        <p className="ant-upload-hint">
                            <FormattedMessage id="p.us.ph.uploadType" />
                        </p>
                    </Dragger>
                </Form.Item>
                <a href="https://socrates.s3.us-east-2.amazonaws.com/excel/2024/09/19/Username_list.xlsx">
                    <DownloadOutlined /> <FormattedMessage id="p.us.b.downloadTemplate" />
                </a>
            </Form>
        </Drawer>
    );
};

export default UserNameModalForm;
