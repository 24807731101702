export default {
    p: {
        tc: {
            column: {
                type: {
                    majorToken: '1-Major Token',
                    trustedToken: '2-Trusted Token',
                    mintableToken: '3-Mintable Token',
                    blacklist: '4-Blacklist',
                },
                sort: 'Pair Sort',
                minTopUp: 'Min Transfer',
                minTradeAmount: 'Min Trade',
                color: 'Color',
                priceScale: 'Price Precision',
                showScale: 'Quantity Precision',
                decimals: 'Token Decimals',
            },
            message: {
                invalidNumber: 'Please enter a positive integer between 0 and 10',
                amountInvalid:
                    'The number of decimal places in front of the decimal point is up to 11, and the number of decimal places after the decimal point is up to 10',
                decimalInvalid: 'Please enter a value with precision less than or equal to the token decimals',
                sort: 'Please enter an 8-digit number',
            },
            tips: {
                priceScale: 'The minimum number of decimal places for the price display',
                showScale: 'The minimum number of decimal places for the quantity display',
            },
        },
    },
};
