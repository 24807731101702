import axios from './index';

/**
 * APIs
 */
// table list
export const userListApi = (query: Record<string, any>) => {
    return axios<PageResType<User>>('/v1/users', {
        method: 'get',
        params: query,
    });
};

export const userDetailApi = (uid: string) => {
    return axios<ResType<User>>(`/v1/users/${uid}`, {
        method: 'get',
    });
};

// 导出用户数据列表
export const userExport = (params: UserDatasExportParams) => {
    return axios('/v1/users/export/async', {
        method: 'get',
        params,
    });
};

// 获取用户限制状态
export const userTnsApi = (user_id: string) => {
    return axios<ResType<UserTns>>(`/v1/tns/users/${user_id}`, {
        method: 'get',
    });
};

// 获取用户操作记录
export const userTnsLogs = (params: UserTnsLogsReq) => {
    return axios<PageResType<UserTnsLogs>>('/v1/tns/users/logs', {
        method: 'get',
        params,
    });
};

// 禁言接口
export const userMute = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/mute`, {
        method: 'put',
        data,
    });
};

// 封号接口
export const userBan = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/ban`, {
        method: 'put',
        data,
    });
};

// 解除禁言接口
export const userUnmute = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/unmute`, {
        method: 'put',
        data,
    });
};

// 解除封号接口
export const userUnban = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/unban`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

// user item
export type User = {
    user_id: string;
    name: string;
    nickname: string;
    avatar: string;
    status: 'FROZEN' | 'UNREGISTER' | 'SILENCE' | 'VALID';
    bio: string;
};

export type UserListParams = {
    uid?: string;
    name?: string;
    nickname?: string;
    parent_uid?: string;
    register_start?: string;
    register_end?: string;
    page?: number;
    size?: number;
};

export type UserDatasExportParams = {
    start?: string;
    end?: string;
};

export type UserQuery = {
    uid?: string;
    name?: string;
};

/**
 *
 */
export type UserTnsOperator = {
    name: string;
    id: string;
    created_at: string;
    deleted_at: string;
    updated_at: string;
};

/**
 * 用户封禁信息
 */
export type UserTns = {
    id: string;
    created_at?: string;
    updated_at?: string;
    user_id: string;
    mute_status: boolean;
    unmute_at?: string;
    ban_status: boolean;
    unban_at?: string;
};

/**
 * 用户封禁记录
 */
export type UserTnsLogs = {
    id: string;
    user_id: string;
    type: UserAction;
    period: number;
    period_unit: 'minute' | 'hour' | 'day';
    remark: string;
    operator_account?: UserTnsOperator;
    created_at: string;
    updated_at: string;
    userInfo: User;
};

/**
 * 用户封禁记录查询条件
 */
export type UserTnsLogsReq = {
    page?: number;
    limit?: number;
    order_by?: string;
    order_sort?: string;
    user_id?: string;
    type?: UserAction;
    types?: UserAction[];
    operator_account_id?: string;
    operation_start?: string;
    operation_end?: string;
};

/**
 * 禁言/封号接口
 */
export type UserTnsAction = {
    period?: number;
    period_unit?: 'minute' | 'hour' | 'day';
    remark: string;
};

// user action
export type UserAction = 'temp_mute' | 'perma_mute' | 'temp_ban' | 'perma_ban' | 'unmute' | 'unban';

export enum UserActionType {
    TEMP_MUTE = 'temp_mute',
    PERMA_MUTE = 'perma_mute',
    TEMP_BAN = 'temp_ban',
    PERMA_BAN = 'perma_ban',
    UNMUTE = 'unmute',
    UNBAN = 'unban',
}

export enum UserActionName {
    TEMP_MUTE = '临时禁言',
    PERMA_MUTE = '永久禁言',
    TEMP_BAN = '临时封号',
    PERMA_BAN = '永久封号',
    UNMUTE = '解除禁言',
    UNBAN = '解除封号',
}

export const userActionOptions = [
    {
        label: UserActionName.TEMP_MUTE,
        value: UserActionType.TEMP_MUTE,
    },
    {
        label: UserActionName.PERMA_MUTE,
        value: UserActionType.PERMA_MUTE,
    },
    {
        label: UserActionName.TEMP_BAN,
        value: UserActionType.TEMP_BAN,
    },
    {
        label: UserActionName.PERMA_BAN,
        value: UserActionType.PERMA_BAN,
    },
    {
        label: UserActionName.UNMUTE,
        value: UserActionType.UNMUTE,
    },
    {
        label: UserActionName.UNBAN,
        value: UserActionType.UNBAN,
    },
];
