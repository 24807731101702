import { useRequest, useSetState } from 'ahooks';
import { App, Button, Col, Form, Input, Row, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import store from 'store2';

/**
 * APIs
 */
import { captcha, login } from '@/services/auth';

/**
 * Styles
 */
import styles from './styles.module.less';

const PageLogin: React.FC = () => {
    /**
     * Params
     */
    const [params] = useSearchParams();

    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    /**
     * States
     */
    const [state, setState] = useSetState({
        captcha: '',
        key: '',
    });

    /**
     * Requests
     */
    const { loading: loadingCaptcha, run: runCaptcha } = useRequest(captcha, {
        onSuccess({ data: { code, data, msg } }) {
            if (code !== 0) {
                return message.error(msg);
            }

            setState(data);
        },
    });

    const { loading: loadingLogin, run: runLogin } = useRequest(login, {
        manual: true,
        onSuccess({ data: { code, data, msg } }) {
            if (code !== 0) {
                return message.error(msg);
            }

            store(data);

            window.location.href = params.get('redirect') || '/';
        },
    });

    /**
     * Events
     */
    const onSubmit = () => {
        form.validateFields()
            .then((value) => {
                runLogin({
                    ...value,
                    captcha: value.captcha.toLowerCase(),
                    key: state.key,
                });
            })
            .catch(() => {});
    };

    return (
        <Row className={styles.authLogin}>
            <Col span={12}>
                <Row className={styles.authLoginForm} justify="space-between">
                    <Col className="p-24">
                        <Space>
                            <img alt="" className={styles.authLoginLogo} src="/favicon.svg" />

                            <strong>Socrates Admin</strong>
                        </Space>
                    </Col>

                    <Col className={styles.authLoginFormWrap}>
                        <Typography.Title className="mb-24" level={2}>
                            Login
                        </Typography.Title>

                        <Form form={form} layout="vertical">
                            <Form.Item
                                name="username"
                                label="Account"
                                rules={[{ message: 'Account is required', required: true }]}
                            >
                                <Input size="large" onPressEnter={onSubmit} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ message: 'Password is required', required: true }]}
                            >
                                <Input.Password size="large" onPressEnter={onSubmit} />
                            </Form.Item>

                            <Form.Item
                                name="captcha"
                                label="Captcha"
                                rules={[{ message: 'Captcha is required', required: true }]}
                            >
                                <Row gutter={8}>
                                    <Col flex="auto">
                                        <Input size="large" onPressEnter={onSubmit} />
                                    </Col>

                                    <Col flex="none">
                                        <Spin spinning={loadingCaptcha}>
                                            <img
                                                alt=""
                                                height={40}
                                                src={state.captcha}
                                                style={{ cursor: 'pointer' }}
                                                width={100}
                                                onClick={runCaptcha}
                                            />
                                        </Spin>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>

                        <Button block loading={loadingLogin} size="large" type="primary" onClick={onSubmit}>
                            Login
                        </Button>
                    </Col>

                    <Col className="p-24">
                        Copyright &copy; {dayjs().format('YYYY')} Socrates Enterprise Limited. v{__VERSION__}
                    </Col>
                </Row>
            </Col>

            <Col span={12}>
                <img alt="" className={styles.authLoginImage} src="/auth/background.jpg" />
            </Col>
        </Row>
    );
};

export default PageLogin;
