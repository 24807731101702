import axios from './index';

/**
 * APIs
 */
// get account list
export const getAccountListApi = (params: AccountListQuery) => {
    return axios<ResType<AccountResult[]>>('/v1/users', {
        method: 'get',
        params,
    });
};

// get account list
export const getAccountFaceActiveCountApi = () => {
    return axios<ResType<AccountFaceActiveCountResult>>('/v1/users/faceActiveCount', {
        method: 'get',
    });
};

// delete account by id
export const deleteAccountApi = (accountId: string | number) => {
    return axios<ResType<AccountResult>>(`/v1/users/${accountId}`, {
        method: 'delete',
    });
};

// update account
export const updateAccountApi = (id: string | number, data: UpdateAccountData) => {
    return axios<ResType<AccountResult>>(`/v1/users/${id}`, {
        method: 'put',
        data,
    });
};

// create account
export const createAccountApi = (data: CreateAccountData) => {
    return axios<ResType<AccountResult>>('/v1/users', {
        method: 'post',
        data,
    });
};

// change account password by id
export const updateAccountPasswordByIdApi = (id: string | number, data: UpdateAccountPasswordData) => {
    return axios<ResType<AccountResult>>(`/v1/users/${id}/password`, {
        method: 'put',
        data,
    });
};

//
export const getRoles = () => {
    return axios<ResType<string[]>>('/v1/roles', {
        method: 'get',
        params: {
            page: 1,
            limit: 9999,
        },
    });
};

export const AccManagementApiErrorCodeMsg: Record<number, string> = {};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type AccountListQuery = {
    limit: number;
    page: number;
    // 默认不带权限，需要传参?withRole=true
    withRole: boolean;
};

export type AccountResult = {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    list: AccountItem[];
};

export type AccountItem = {
    id: string;
    username: string;
    status: number;
    roles: any[];
    created_at: string;
    updated_at: string;
};

export type CreateAccountData = {
    username: string;
    password?: string;
    roleIds?: string[];
    status: number;
    operator: string;
};

export type UpdateAccountData = CreateAccountData & { updated_at: string };

export type UpdateAccountPasswordData = {
    old_password: string;
    new_password: string;
};

export type AccountFaceActiveCountResult = {
    user_count: number;
};
