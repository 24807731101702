import axios from './index';

/**
 * APIs
 */
// get app list
export const getAppApi = (device: number) => {
    return axios<ResType<AppResult>>(`/v1/app/manage/versions/${device}`, {
        method: 'get',
    });
};

// create app
export const createAppApi = (data: AppResult) => {
    return axios<ResType<AppResult>>(`/v1/app/manage/versions`, {
        method: 'post',
        data,
    });
};

// update app
export const updateAppApi = (device: number, data: AppResult) => {
    return axios<ResType<AppResult>>(`/v1/app/manage/versions/${device}`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type AppVersion = {
    major: string;
    minor: string;
    patch: string;
};

export type AppContent = {
    code: string;
    language: string;
    region: string;
    title: string;
    copywriter: string;
};

export type AppResult = {
    id: string;
    device_type: string;
    latest_version: AppVersion;
    review_version: AppVersion;
    force_lowest_version: AppVersion;
    store_url: string;
    download_url?: string;
    content: AppContent[];
};
