export default {
    p: {
        ush: {
            columns: {
                user: 'User',
                type: 'Type',
                detail: 'Operation Details',
                remark: 'Remark',
            },
            action: {
                mute: 'Mute',
                disable: 'Disable',
                unmute: 'Unmute',
                enabled: 'Enabled',
            },
            types: {
                temp_mute: 'Temporary Mute',
                perma_mute: 'Permanent Mute',
                temp_ban: 'Temporary Disable',
                perma_ban: 'Permanent Disable',
                unmute: 'Unmute',
                unban: 'Enable',
            },
        },
    },
};
