import { useEffect, useState } from 'react';
import { ProCard } from '@ant-design/pro-components';
import { Button, Flex, Form, Input, Segmented, Radio, App } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * Types
 */
import type { FormProps } from 'antd';
import { AppContent, createAppApi, getAppApi, updateAppApi } from '@/services/app';
import { get } from 'lodash';
type LanguageType = (typeof Language)[number];

/**
 * Constants
 */
enum Platform {
    Android = 1,
    iOS = 0,
}

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP'];

const languageOptions = [
    {
        // EN
        language: 'en',
        code: 'EN',
        region: '',
    },
    {
        // CHS
        language: 'zh',
        code: 'CHS',
        region: 'CN',
    },
    {
        // CHT
        language: 'zh',
        code: 'CHT',
        region: 'TW',
    },
    {
        // JPN
        language: 'ja',
        code: 'JPN',
        region: 'JP',
    },
    {
        // VIE
        language: 'vi',
        code: 'VIE',
        region: 'VN',
    },
    {
        // TRK
        language: 'tr',
        code: 'TRK',
        region: 'TR',
    },
    {
        // ESP
        language: 'es',
        code: 'ESP',
        region: 'ES',
    },
];

const VersionInputList: React.FC<{ fields: any; disabled: boolean }> = ({ fields, disabled }) => (
    <Flex align="center">
        {fields.map(({ key, ...restField }: { key: React.Key; [key: string]: any }, index: number) => (
            <>
                <Form.Item
                    rules={[
                        { required: true, message: <FormattedMessage id="notEmpty" /> },
                        {
                            pattern: /^(0|[1-9]\d*)$/,
                            message: <FormattedMessage id="common.positiveInteger" />,
                        },
                    ]}
                    key={key}
                    {...restField}
                >
                    <Input maxLength={2} disabled={disabled} style={{ width: '60px', textAlign: 'center' }} />
                </Form.Item>
                {index !== fields.length - 1 && (
                    <strong style={{ marginRight: 4, marginLeft: 4, marginTop: '-20px' }}>.</strong>
                )}
            </>
        ))}
    </Flex>
);

const PageClientAppVersion: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const [showLanguage, setShowLanguage] = useState('EN');
    const [platform, setPlatform] = useState(Platform.Android);
    const [disabled, setDisabled] = useState(true);
    const [id, setId] = useState<string | undefined>(undefined);

    const intl = useIntl();

    const formContentData = (data: AppContent[]) => {
        return languageOptions.map((item) => {
            const version = data.find((v) => v.language === item.language && v.region === item.region);
            return {
                code: item.code,
                language: item.language,
                region: item.region,
                title: version?.title || '',
                copywriter: version?.copywriter || '',
            };
        });
    };

    /**
     * ChildrenProps
     */
    const formProps: FormProps = {
        form: form,
        initialValues: {
            platform: 'Android',
            latest_version: ['', '', ''],
            review_version: ['', '', ''],
            force_lowest_version: ['', '', ''],
            content: formContentData([]),
        },
        layout: 'vertical',
        onFinish: async (values) => {
            const data = {
                ...values,
                id,
                device_type: Number(platform),
                latest_version: {
                    major: values.latest_version[0],
                    minor: values.latest_version[1],
                    patch: values.latest_version[2],
                },
                review_version: {
                    major: values.review_version[0],
                    minor: values.review_version[1],
                    patch: values.review_version[2],
                },
                force_lowest_version: {
                    major: values.force_lowest_version[0],
                    minor: values.force_lowest_version[1],
                    patch: values.force_lowest_version[2],
                },
            };

            const res = await (id ? updateAppApi(platform, data) : createAppApi(data));
            if (res.data.code === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                setDisabled(true);
                getApp();
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        },
    };

    const getApp = async () => {
        const res = await getAppApi(platform);
        if (res.data.code !== 0) {
            message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            return;
        }

        const data = res.data.data;

        if (!data) {
            form.setFieldsValue({
                store_url: '',
                download_url: '',
                latest_version: ['', '', ''],
                review_version: ['', '', ''],
                force_lowest_version: ['', '', ''],
                content: formContentData([]),
            });
            return;
        }

        const latestVersion = data.latest_version || [];
        const reviewVersion = data.review_version || [];
        const forceLowestVersion = data.force_lowest_version || [];
        form.setFieldsValue({
            ...data,
            force_lowest_version: [forceLowestVersion.major, forceLowestVersion.minor, forceLowestVersion.patch],
            latest_version: [latestVersion.major, latestVersion.minor, latestVersion.patch],
            review_version: [reviewVersion.major, reviewVersion.minor, reviewVersion.patch],
            content: formContentData(data.content || []),
        });

        setId(data.id);
    };

    useEffect(() => {
        getApp();
    }, [platform]);

    return (
        <Form {...formProps}>
            <ProCard style={{ marginBottom: 16 }}>
                <Radio.Group
                    value={String(platform)}
                    onChange={(e) => {
                        setPlatform(e.target.value);
                        form.setFieldValue('platform', e.target.value);
                        setDisabled(true);
                        setId(undefined);
                    }}
                >
                    <Radio.Button value="1">Android</Radio.Button>
                    <Radio.Button value="0">iOS</Radio.Button>
                </Radio.Group>
            </ProCard>
            <ProCard title={<FormattedMessage id="p.appVersion.title" />} headerBordered>
                <Form.Item required label={<FormattedMessage id="p.appVersion.version" />} style={{ marginBottom: 0 }}>
                    <Form.List name="latest_version">
                        {(fields) => <VersionInputList fields={fields} disabled={disabled} />}
                    </Form.List>
                </Form.Item>
                {Number(platform) === Platform.Android && (
                    <>
                        <Form.Item
                            rules={[{ required: true, message: <FormattedMessage id="notEmpty" /> }]}
                            name="store_url"
                            label={<FormattedMessage id="p.appVersion.googlePlay" />}
                        >
                            <Input showCount maxLength={255} disabled={disabled} style={{ width: '480px' }} />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: <FormattedMessage id="notEmpty" /> }]}
                            name="download_url"
                            label={<FormattedMessage id="p.appVersion.androidApk" />}
                        >
                            <Input showCount maxLength={255} disabled={disabled} style={{ width: '480px' }} />
                        </Form.Item>
                    </>
                )}
                {Number(platform) === Platform.iOS && (
                    <Form.Item
                        rules={[{ required: true, message: <FormattedMessage id="notEmpty" /> }]}
                        name="store_url"
                        label="App Store地址"
                    >
                        <Input showCount maxLength={255} disabled={disabled} style={{ width: '480px' }} />
                    </Form.Item>
                )}
                <Form.Item
                    required
                    label={<FormattedMessage id="p.appVersion.lowestVersion" />}
                    style={{ marginBottom: 0 }}
                >
                    <Form.List name="force_lowest_version">
                        {(fields) => <VersionInputList fields={fields} disabled={disabled} />}
                    </Form.List>
                </Form.Item>
                <Form.Item
                    required
                    label={<FormattedMessage id="p.appVersion.approvedVersion" />}
                    style={{ marginBottom: 0 }}
                >
                    <Form.List name="review_version">
                        {(fields) => <VersionInputList fields={fields} disabled={disabled} />}
                    </Form.List>
                </Form.Item>
                <Form.Item name="language" label={<FormattedMessage id="p.appVersion.language" />}>
                    <Segmented<LanguageType>
                        options={languageOptions.map((item) => ({ label: item.code, value: item.code }))}
                        value={showLanguage}
                        disabled={disabled}
                        onChange={(value) => {
                            setShowLanguage(value);
                            form.setFieldValue('_language', value);
                        }}
                    />
                </Form.Item>
                <Form.List name="content">
                    {(fields) => (
                        <>
                            {fields.map(({ name }) => (
                                <div style={{ display: Language[name] === showLanguage ? 'block' : 'none' }}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: Language[name] === 'EN',
                                                message: <FormattedMessage id="notEmpty" />,
                                            },
                                        ]}
                                        name={[name, 'title']}
                                        label={<FormattedMessage id="p.appVersion.versionUpdateTitle" />}
                                    >
                                        <Input
                                            showCount
                                            maxLength={100}
                                            disabled={disabled}
                                            style={{ width: '480px' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: Language[name] === 'EN',
                                                message: <FormattedMessage id="notEmpty" />,
                                            },
                                        ]}
                                        name={[name, 'copywriter']}
                                        label={<FormattedMessage id="p.appVersion.versionUpdateContent" />}
                                    >
                                        <Input.TextArea
                                            showCount
                                            maxLength={1000}
                                            disabled={disabled}
                                            style={{ width: '480px' }}
                                            rows={4}
                                        />
                                    </Form.Item>
                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
                {disabled ? (
                    <Button type="primary" htmlType="submit" onClick={() => setDisabled(false)}>
                        <FormattedMessage id="p.appVersion.modify" />
                    </Button>
                ) : (
                    [
                        <Button
                            type="primary"
                            ghost
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                setDisabled(true);
                                getApp();
                            }}
                        >
                            <FormattedMessage id="p.appVersion.cancel" />
                        </Button>,
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="p.appVersion.save" />
                        </Button>,
                    ]
                )}
            </ProCard>
        </Form>
    );
};

export default PageClientAppVersion;
