import axios from './index';

/**
 * APIs nft 交易市场
 */
// 获取 NFT列表
export const gNFTApi = (params: NFTParams) => {
    return axios<PageResType<NFTResult>>('/v1/nfts', {
        method: 'get',
        params,
    });
};

// 获取 NFT集合
export const gNFTCollectionApi = (params: NFTCollectionParams) => {
    return axios<PageResType<NFTCollectionResult>>('/v1/nft/collections', {
        method: 'get',
        params,
    });
};

// 获取 NFT交易市场类型列表
export const gTypeListApi = () => {
    return axios<ResType<NFTTypeListResult[]>>('/v1/nft/top/collections', {
        method: 'get',
    });
};

// 新增 NFT交易市场管理类型
export const cTypeApi = (data: CreateNFTTypeParams) => {
    return axios<PageResType<null>>('/v1/nft/top/collections', {
        method: 'post',
        data,
    });
};

// 编辑 NFT交易市场管理类型
export const pTypeApi = (collectionId: IdType, data: UpdateNFTTypeParams) => {
    return axios<PageResType<null>>(`/v1/nft/top/collections/${collectionId}`, {
        method: 'put',
        data,
    });
};

// 删除 NFT交易市场管理类型
export const dTypeApi = (collectionId: IdType) => {
    return axios<PageResType<null>>(`/v1/nft/top/collections/${collectionId}`, {
        method: 'delete',
    });
};

// 排序
export const sTypeApi = (data: { collectionIds: IdType[] }) => {
    return axios<PageResType<null>>('/v1/nft/top/collections/sort', {
        method: 'put',
        data,
    });
};

// 获取 NFT交易市场类型 详情列表
export const gTypeNFTListApi = (params: { collection_id: IdType }) => {
    return axios<ResType<NFTTypeDetailResult[]>>('/v1/nft/top/nfts', {
        method: 'get',
        params,
    });
};

// 新增 NFT交易市场管理类型 详情
export const cTypeNFTApi = (data: CreateNFTTypeDetailParams) => {
    return axios<PageResType<null>>('/v1/nft/top/nfts', {
        method: 'post',
        data,
    });
};

// 删除 NFT交易市场管理类型 详情
export const dTypeDetailApi = (data: CreateNFTTypeDetailParams) => {
    return axios<PageResType<null>>(`/v1/nft/top/nfts`, {
        method: 'delete',
        data,
    });
};

// 排序 NFT交易市场管理类型 详情
export const sTypeDetailApi = (data: { collection_id: IdType; nft_ids: IdType[] }) => {
    return axios<PageResType<null>>('/v1/nft/top/nfts/sort', {
        method: 'put',
        data,
    });
};

/**
 * Constant
 */
// 子类型
export const subCategory = {
    1: 'Background',
    2: 'Accessories',
    4: 'Face',
    5: 'Hair',
    6: 'Clothing',
    7: 'Hat',
    8: 'Handheld',
    9: 'Pet',
};

export const SubCategoryOptions = Object.entries(subCategory).map((item) => ({
    label: item[1],
    value: item[0],
}));

// 错误码
export const ErrorCodeMap: ErrorCodeMapType = {
    104901: 'mknft.e.cAlreadyExists', // 集合已存在
    100410: 'mknft.e.cNotFound', // 集合不存在
    104902: 'mknft.e.nAlreadyExists', // NFT 已存在
    280101: 'mknft.e.nNotFound', // NFT  不存在
    280102: 'mknft.e.cAlreadyExists', //集合已存在
    280104: 'mknft.e.cAlreadyExists', //集合中 NFT 已存在
    280105: 'mknft.e.nftHasDisabeld', // 当前 NFT 已被禁用
    280106: 'mknft.e.nftNotToC', // 添加的 NFT 不属于当前集合
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';
import type { ErrorCodeMapType } from '@/utils/apiErrorMsg';
export type IdType = string | number;
export type TypeValue = 1 | 2;

export type NFTParams = {
    collection_id?: string;
    created_end?: string;
    created_start?: string;
    id?: string;
    limit?: string;
    nft_id?: string;
    nft_name?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 子类型
     */
    sub_category?: string;
    status?: number;

    _created_date?: any[];
};

/**
 * NFT
 */
export type NFTResult = {
    /**
     * nft主键
     */
    asset_id: number;
    /**
     * 集合id
     */
    collection_id: string;
    created_at: string;
    /**
     * 创建者用户id
     */
    creator_id?: number;
    /**
     * 创建者钱包地址
     */
    creator_wallet_address?: string;
    /**
     * 地板价
     */
    floor_price: number;
    /**
     * 主键id
     */
    id: string;
    /**
     * ethereum, polygon
     */
    nft_chain: string;
    /**
     * NFT合约地址
     */
    nft_contract: string;
    /**
     * nft_id，这个不是唯一值，入参不能用
     */
    nft_id: string;
    /**
     * NFT名称
     */
    nft_name: string;
    /**
     * 总数量: 721固定位1, 1155总发行数量
     */
    nft_total_supply: number;
    /**
     * NFT类型: 721, 1155
     */
    nft_type: number;
    /**
     * NFT Metadata数据地址
     */
    nft_uri?: string;
    /**
     * NFT图片地址
     */
    nft_url: string;
    /**
     * 子类型
     */
    sub_category?: number;
    updated_at?: string;

    _fe_created_at?: number;
};

export type NFTCollectionParams = {
    limit?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    status?: number;
};

/**
 * NFTCollection
 */
export type NFTCollectionResult = {
    /**
     * 24小时交易额(usdt计价)
     */
    amount_24h: number;
    /**
     * 集合背景图
     */
    background_url: string;
    /**
     * 是否显示徽章: 0:不显示, 1:显示
     */
    badge_status: number;
    /**
     * 最优报价
     */
    best_offer_price: number;
    /**
     * 类别id
     */
    category_id: number;
    /**
     * 所属链
     */
    chain: string;
    /**
     * 集合内容
     */
    content: string;
    /**
     * 合约地址
     */
    contract: string;
    created_at: string;
    /**
     * 地板价
     */
    floor_price: number;
    /**
     * 集合图标
     */
    icon_url: string;
    /**
     * 合集 ID
     */
    id: string;
    /**
     * 集合名称
     */
    name: string;
    /**
     * 价格币种
     */
    price_symbol: string;
    /**
     * 是否显示自营: 0:非自营, 1:自营
     */
    self_operate_status: number;
    /**
     * 在售NFT数量
     */
    sell_nft_count: number;
    /**
     * 在售NFT的占比
     */
    sell_nft_ratio: number;
    /**
     * 状态 0:停用, 1:启用
     */
    status: number;
    /**
     * 总NFT数量
     */
    total_nft_count: number;
    /**
     * 累计交易额(usdt计价)
     */
    total_trading_amount: number;
    /**
     * 累计交易量
     */
    total_trading_volume: number;
    updated_at?: string;
    /**
     * 24小时交易量
     */
    volume_24h: number;
    /**
     * 集合背景宽图
     */
    wide_background_url: string;

    _fe_created_at?: number;
};

/**
 * NFTTypeListResult
 */
export interface NFTTypeListResult {
    /**
     * 合集id
     */
    collection_id: string;
    created_at: string;
    /**
     * 删除时间
     */
    deleted_at?: string;
    /**
     * 主键id
     */
    id: string;
    /**
     * 合集名称
     */
    name: string;
    /**
     * 操作人
     */
    operator_id?: string;
    /**
     * 排序字段: 从小到大排序
     */
    sort: number;
    /**
     * 状态 0:停用, 1:启用
     */
    status: number;
    updated_at?: string;
    /**
     * 版本号
     */
    version: number;
}

export type CreateNFTTypeParams = {
    collection_id: string;
    status: number;
};

export type UpdateNFTTypeParams = Omit<CreateNFTTypeParams, 'collection_id'> & {
    update_at?: string;
};

/**
 * NFTForCollection
 */
export interface NFTTypeDetailResult {
    /**
     * 合集id，首页: 0
     */
    collection_id: string;
    created_at: number;
    /**
     * 删除时间
     */
    deleted_at?: number;
    /**
     * 主键id
     */
    id: string;
    /**
     * market_nft_id
     */
    market_nft_id: number;
    nft_info: Nft;
    /**
     * 操作人
     */
    operator_account?: OperatorAccount;
    /**
     * 排序字段，:从小到大排序
     */
    sort: number;
    /**
     * 状态， 0:停用, 1:启用
     */
    status: number;
    updated_at?: number;
    _fe_created_at?: number;
}

/**
 * NFT
 */
export interface Nft {
    /**
     * nft主键
     */
    asset_id: number;
    /**
     * 集合id
     */
    collection_id: string;
    created_at: string;
    /**
     * 创建者用户id
     */
    creator_id?: number;
    /**
     * 创建者钱包地址
     */
    creator_wallet_address?: string;
    /**
     * 地板价
     */
    floor_price: number;
    /**
     * 主键id
     */
    id: string;
    /**
     * ethereum, polygon
     */
    nft_chain: string;
    /**
     * NFT合约地址
     */
    nft_contract: string;
    /**
     * nft_id，这个不是唯一值，入参不能用
     */
    nft_id: string;
    /**
     * NFT名称
     */
    nft_name: string;
    /**
     * 总数量: 721固定位1, 1155总发行数量
     */
    nft_total_supply: number;
    /**
     * NFT类型: 721, 1155
     */
    nft_type: number;
    /**
     * NFT Metadata数据地址
     */
    nft_uri?: string;
    /**
     * NFT图片地址
     */
    nft_url: string;
    /**
     * 子类型
     */
    sub_category?: number;
    updated_at?: string;
}

/**
 * 操作人
 */
export interface OperatorAccount {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 操作人
     */
    operator: string;
    /**
     * 权限
     */
    permissions: string[];
    /**
     * 角色
     */
    roles?: any;
    /**
     * 状态
     */
    status: number;
    /**
     * 更新时间
     */
    updated_at: Date;
}

export type CreateNFTTypeDetailParams = {
    collection_id: string;
    nft_id: string;
};
