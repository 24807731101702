import { UploadImageData } from './common';
import axios from './index';

/**
 * APIs
 */
// table list
export const userNameListApi = (query: Record<string, any>) => {
    return axios<PageResType<UserNameListResult>>('/v1/username', {
        method: 'get',
        params: query,
    });
};

// delete userName by id
export const deleteUserNameByIdApi = (ids: string[]) => {
    return axios<ResType<UserNameListResult>>(`/v1/username`, {
        method: 'delete',
        data: {
            ids,
        },
    });
};

// create userName
export const createUserNameApi = (data: CreateUserNameData) => {
    return axios<ResType<UserNameListResult>>('/v1/username', {
        method: 'post',
        data,
    });
};

// upload image
export const uploadUserName = (data: UploadImageData) => {
    const formData = new FormData();
    formData.append('file', data.file);

    return axios<ResType<string[]>>('/v1/username/import', {
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'post',
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

// userName item
export type UserNameListResult = {
    id: string;
    name: string;
    type: string;
    operated_by: string;
    created_at: string;
    updated_at: string;
};

// 获取用户名占用数据列表
export type UserNameListParams = {
    name?: string;
    type?: string;
    order_by?: string;
    order_sort?: 'asc' | 'desc';
    page?: number | string;
    size?: number | string;
    updated_end?: string;
    updated_start?: string;
};

// create userName
export type CreateUserNameData = {
    type: string;
    name: string;
};
