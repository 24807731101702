export default {
    p: {
        tc: {
            column: {
                type: {
                    majorToken: '1-大币种',
                    trustedToken: '2-可信度高币种',
                    mintableToken: '3-普通mint币种',
                    blacklist: '4-黑名单币种',
                },
                sort: '交易对排序',
                minTopUp: '最小划转数量',
                minTradeAmount: '最小交易数量',
                color: '色值',
                priceScale: '价格精度',
                showScale: '数量精度',
                decimals: '引擎精度',
            },
            message: {
                invalidNumber: '请输入0-10的正整数',
                amountInvalid: '小数点前最多11位小数点后最多10位的正数',
                decimalInvalid: '请输入精度小于引擎精度的数值',
                sort: '请输入8位数字',
            },
            tips: {
                priceScale: '最小小数位美元价格展示',
                showScale: '最小小数位数量展示',
            },
        },
    },
};
