import { useCallback } from 'react';
import { useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, message, Popconfirm, Spin, Tag, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { get } from 'lodash';

/**
 * APIs
 */
import { accounts, dAccount } from '@/services/account';

/**
 * Components
 */
import Permission from '@/components/permission';

/**
 * Types
 */
import type { MutableRefObject } from 'react';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { IntlShape } from 'react-intl';
import type { AccountsResponse } from '@/services/account';

type TableParams = {
    pageSize: number;
    current: number;
};

type Props = {
    tableActionRef: MutableRefObject<ActionType | undefined>;
    showModalFormEvent: ReturnType<typeof useCallback>;
};

/**
 * Constants
 */
const StatusTextMap: Record<number, string> = {
    0: 'Disabled',
    1: 'Enabled',
};

const ComponentTable: React.FC<Props> = ({ showModalFormEvent, tableActionRef }) => {
    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<AccountsResponse, any> = {
        actionRef: tableActionRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="t.c.account" />,
                width: 200,
                hideInSearch: true,
            },
            {
                dataIndex: 'roles',
                title: <FormattedMessage id="t.c.role" />,
                hideInSearch: true,
                render: (_, entity) => {
                    const roles = get(entity, 'roles', []);
                    return (
                        <>
                            {roles.map((role) => (
                                <Tag key={role.id} color="blue">
                                    {role.name}
                                </Tag>
                            ))}
                        </>
                    );
                },
            },
            {
                dataIndex: 'status',
                width: 120,
                title: <FormattedMessage id="t.c.status" />,
                valueType: 'text',
                hideInSearch: true,
                render: (status, entity) => (
                    <Tag color={status === 1 ? 'green' : 'orange'}>{StatusTextMap[entity.status]}</Tag>
                ),
            },
            {
                dataIndex: 'operator',
                width: 200,
                title: <FormattedMessage id="t.c.createdBy" />,
                hideInSearch: true,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.createdAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                width: 200,
                fixed: 'right',
                valueType: 'option',
                title: <FormattedMessage id="t.c.action" />,
                hideInSearch: true,
                render: (_, entity) => {
                    if (entity.id === '11111111-1111-1111-1111-111111111111') {
                        return '-';
                    }
                    return [
                        <Permission permission="System-Management_Accounts_Edit">
                            <a key="update" onClick={() => showModalFormEvent(entity)}>
                                <FormattedMessage id="b.edit" />
                            </a>
                        </Permission>,
                        <Permission permission="System-Management_Accounts_Delete">
                            <Spin spinning={!!cellLoading[entity.id]} size="small">
                                <Popconfirm
                                    key="delete"
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setCellLoading({ [entity.id]: true });
                                        dAccount(entity.id)
                                            .then(({ data }) => {
                                                if (get(data, 'code') === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    tableActionRef?.current?.reload();
                                                } else {
                                                    message.error(get(data, 'msg', 'Error'));
                                                }
                                                setCellLoading({ [entity.id]: false });
                                            })
                                            .catch(() => {
                                                setCellLoading({ [entity.id]: false });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>,
                    ];
                },
            },
        ],
        search: false,
        toolBarRender: () => [
            <Permission permission="System-Management_Accounts_Create">
                <Button type="primary" onClick={() => showModalFormEvent()}>
                    <FormattedMessage id="b.add" />
                </Button>
            </Permission>,
        ],
        rowKey: 'id',
        scroll: {
            y: 'calc(100vh - 272px)',
            x: 1170,
        },
        request: async (params: TableParams) => {
            const {
                data: { code, data, msg },
            } = await accounts({
                withRole: true,
                page: params.current,
                limit: params.pageSize,
            });
            if (code !== 0) {
                message.error(msg || 'Error');
            }
            return {
                data: get(data, 'list', []),
                success: code === 0,
                total: get(data, 'meta.total', 0),
            };
        },
    };

    return <ProTable {...proTableProps} />;
};

export default ComponentTable;
