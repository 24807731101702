/**
 * Permission
 */
export default {
    pm: {
        Users: 'Users',
        Users_Users: 'Users',
        Users_Usernames: 'Usernames',
        Users_Usernames_Add: 'Add',
        Users_Usernames_Import: 'Import',
        Users_Usernames_Delete: 'Delete',
        'Users_Usernames_Bulk-Delete': 'Bulk Delete',
        Users_Users_Details: 'Detail',
        Users_Users_Details_Mute: 'Mute',
        Users_Users_Details_Disable: 'Disable',
        Users_Users_Details_Unmute: 'Unmute',
        Users_Users_Details_Enable: 'Enable',
        Users_Users_Export: 'Export',
        'Users_Sanction-History': 'Sanction History',
        Content: 'Content',
        Content_Posts: 'Posts',
        Content_Shorts: 'Shorts',
        Ambassadors: 'Ambassadors',
        Ambassadors_Applications: 'Applications',
        'Ambassadors_Applications_Public-Pool': 'Public Pool',
        'Ambassadors_Applications_Public-Pool_Assign': 'Assign',
        'Ambassadors_Applications_Private-Pool': 'Private Pool',
        'Ambassadors_Applications_Private-Pool_All-Data': 'All-Data',
        'Ambassadors_Applications_Private-Pool_Personal-Data': 'Personal-Data',
        'Ambassadors_Applications_Review-Records': 'Review Records',
        Ambassadors_Ambassadors: 'Ambassadors Management',
        'Ambassadors_Ambassadors_Public-Pool': 'Public Pool',
        Ambassadors_Ambassadors_Ambassadors: 'Ambassadors',
        Ambassadors_Ambassadors_Ambassadors_Assign: 'Assign',
        'Ambassadors_Ambassadors_Ambassadors_All-Data': 'All Data',
        'Ambassadors_Ambassadors_Ambassadors_Personal-Data': 'Personal Data',
        Ambassadors_Data: 'Data',
        'Ambassadors_Data_Ambassador-Data': 'Ambassador Data',
        'Ambassadors_Data_Ambassador-Data_Export': 'Export',
        Ambassadors_Configurations: 'Configurations',
        'Ambassadors_Configurations_Private-Pool-Config': 'Private Pool Config',
        'Ambassadors_Configurations_Private-Pool-Config_Add': 'Add',
        'Ambassadors_Configurations_Private-Pool-Config_Edit': 'Edit',
        'Ambassadors_Configurations_Private-Pool-Config_Delete': 'Delete',
        Incentives: 'Incentives',
        'Incentives_Incentive-Seasons': 'Incentive Seasons',
        'Incentives_Incentive-Seasons_Add': 'Add',
        'Incentives_Incentive-Seasons_Edit': 'Edit',
        'Incentives_Incentive-Seasons_Delete': 'Delete',
        'Incentives_Task-Config_Add': 'Add',
        'Incentives_Task-Config_Edit': 'Edit',
        'Incentives_Task-Config_Delete': 'Delete',
        'Incentives_Task-Config_Sort': 'Sort',
        Creator_Center: 'Creator Center',
        'Creator_Center-Topic': 'Topic',
        'Creator_Center-Creator_Hub': 'Creator Hub',
        'Creator_Center-Top_Posts': 'Top Posts',
        Reports: 'Reports',
        'Reports_Report-Moderation': 'Report Moderation',
        'Reports_Report-Moderation_Posts': 'Posts',
        'Reports_Report-Moderation_Replies': 'Replies',
        'Reports_Report-Moderation_Profiles': 'Profiles',
        'Reports_Moderation-Records': 'Moderation Records',
        'Reports_Moderation-Records_Post-Moderation-Records': 'Post Moderation Records',
        'Reports_Moderation-Records_Reply-Moderation-Records': 'Reply Moderation Records',
        'Reports_Moderation-Records_Profile-Moderation-Records': 'Profile Moderation Records',
        'Client-Config': 'Client Config',
        'Client-Config_Plaza-Config': 'Plaza Config',
        'Client-Config_Super-Users': 'Super Users',
        'Client-Config_Super-Users_Add': 'Add',
        'Client-Config_Super-Users_Edit': 'Edit',
        'Client-Config_Super-Users_Delete': 'Delete',
        'Client-Config_Domain': 'Domain',
        'Client-Config_App-Version': 'App Version',
        Marketplace: 'Marketplace',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT Collection',
        Marketplace_NFT_NFT: 'NFT',
        'Marketplace_NFT_NFT-Marketplace': 'NFT Marketplace',
        Marketplace_Token: 'Token',
        'Marketplace_Token_Token-Config': 'Token Config',
        'Marketplace_Token_Token-Config_Edit': 'Edit',
        'Marketplace_Token_Token-Config_Sort': 'Sort',
        Marketplace_Token_Pool: 'Token Pool',
        Marketplace_Token_Pool_Create: 'Add',
        Marketplace_Token_Pool_Edit: 'Edit',
        'Marketplace_Token_Top-Token': 'Top Token',
        'Marketplace_Token_Top-Token_Add': 'Add',
        'Marketplace_Token_Top-Token_Sort': 'Sort',
        'Marketplace_Token_Top-Token_Delete': 'Delete',
        Airdrop: 'Airdrop',
        'Airdrop_Airdrop-Seasons': 'Airdrop Seasons',
        'Airdrop_Airdrop-Seasons_Create': 'Create',
        'Airdrop_Airdrop-Seasons_Edit': 'Edit',
        'Airdrop_Airdrop-Seasons_Delete': 'Delete',
        'Airdrop_Airdrop-Seasons_Distribute': 'Distribute',
        'Airdrop_Airdrop-Config': 'Airdrop Config',
        'Airdrop_Airdrop-Config_Edit': 'Edit',
        'System-Management': 'System Management',
        'System-Management_Accounts': 'Accounts',
        'System-Management_Accounts_Create': 'Create',
        'System-Management_Accounts_Edit': 'Edit',
        'System-Management_Accounts_Delete': 'Delete',
        'System-Management_Roles': 'Roles',
        'System-Management_Roles_Create': 'Create',
        'System-Management_Roles_Edit': 'Edit',
        'System-Management_Roles_Delete': 'Delete',
        'System-Management_Permissions': 'Permissions',
        'System-Management_Logs': 'Logs',
        'System-Management_Exports': 'Downloads',
        'System-Management_Exports_Exports': 'Downloads',
        'System-Management_Exports_Exports_All-Data': 'All Data',
        'System-Management_Exports_Exports_Personal-Data': 'Personal Data',
    },
};
