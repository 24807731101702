export default {
    p: {
        us: {
            b: {
                batchDelete: 'Batch Delete',
                downloadTemplate: 'Download Template',
            },
            ph: {
                name: 'Please enter name',
                type: 'Please select type',
                upload: 'Drop files here or click to upload',
                uploadType: 'Supported file extensions: .xls, .xlsx, .csv',
            },
            msg: {
                deleteConfirm: 'Delete this user name?',
                uploading: 'Uploading',
                uploadError: 'Import failed, some data does not meet the format requirements.',
                uploadSuccess: 'Import successful, a total of {number} records have been imported.',
            },
            type: {
                fancyNo: 'Fancy No',
                company: 'Company',
                celebrity: 'Celebrity',
                organisation: 'Organisation',
                country: 'Country',
            },
        },
    },
};
