import { useEffect } from 'react';
import { useBoolean, useLocalStorageState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Drawer, Button, message, Input, Switch } from 'antd';
import { get } from 'lodash';
import { createRoleApi, updateRoleApi } from '@/services/roles';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { User } from '@repo/typings/user';

export type ModalFormData = {
    id: string;
    name: string;
    status: number;
    permissions: string[] | null;
    updated_at: string;
};

export type ModalFormState = {
    open: boolean;
    data: ModalFormData | undefined;
};

type Props = {
    state: ModalFormState;
    onClose: (reload: boolean) => void;
};

const RoleModalForm: React.FC<Props> = ({ state, onClose }) => {
    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();
    const [user] = useLocalStorageState<User>('user', {
        defaultValue: {
            id: '',
            status: 0,
            name: '',
        },
    });

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.setFieldsValue({
                name: '',
                status: true,
            });
            setSubmittingFlase();
        } else if (state.data) {
            console.log(state.data.status === 1);
            form.setFieldsValue({
                name: state.data.name,
                status: state.data.status === 1,
            });
        }
    }, [state]);

    const handleFinish = async (formData: any) => {
        console.log(formData);
        setSubmittingTrue();
        if (state?.data?.id) {
            const res = await updateRoleApi(state.data?.id || '', {
                ...formData,
                permissions: state.data.permissions || [],
                operator: user?.name,
                status: formData.status ? 1 : 0,
                updated_at: state.data.updated_at,
            });
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose(true);
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        } else {
            const res = await createRoleApi({
                ...formData,
                status: formData.status ? 1 : 0,
                operator: user?.name,
            });
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose(true);
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        }
        setSubmittingFlase();
    };

    const drawerProps = {
        title: state.data?.id ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        open: state.open,
        maskClosable: false,
        destroyOnClose: true,
        onClose: () => onClose(false),
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose(false)} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={submitting}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    return (
        <Drawer {...drawerProps}>
            <Form form={form} layout="vertical" labelAlign="right" onFinish={handleFinish}>
                <Form.Item
                    name="name"
                    label={<FormattedMessage id="common.role" />}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'notEmpty' }) }]}
                >
                    <Input
                        placeholder={intl.formatMessage({ id: 'p.rm.ph.name' })}
                        showCount
                        maxLength={20}
                        autoComplete="off"
                    />
                </Form.Item>
                <Form.Item name="status" label={<FormattedMessage id="common.status" />} valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default RoleModalForm;
