import { useState } from 'react';
import { useBoolean, useRequest } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Select, Space } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { pTopToken, ErrorCodeMap, TokenConfig, gTokenList } from '@/services/top-token';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';

type Props = {
    open: boolean;
    includes: TokenConfig[];
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, includes, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();
    const [form] = Form.useForm();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [options, setOptions] = useState<any[]>([]);

    /**
     * Request
     */
    const { run: runTokenConfigs } = useRequest(gTokenList, {
        manual: false,
        onSuccess: (data) => {
            const resList = get(data, 'data.data', []);
            setOptions(
                resList.filter((v) => {
                    const hasAdd = includes.some((hasItem) => {
                        return v.token_id === hasItem.token.id;
                    });
                    return !hasAdd;
                }),
            );
        },
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields().then(async (formData) => {
                                setSubmittingTrue();
                                try {
                                    const res = await pTopToken(formData);
                                    if (get(res, 'data.code') === 0) {
                                        message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                        closeModelForm(true);
                                    } else {
                                        apiErrorMsg(message, intl, ErrorCodeMap, res.data);
                                        setSubmittingFlase();
                                    }
                                } catch {
                                    setSubmittingFlase();
                                }
                            });
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="b.add" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else {
                runTokenConfigs();
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item name="token_id" label={'Token'} rules={[{ required: true }]}>
                    <Select
                        allowClear={true}
                        showSearch={true}
                        filterOption={(input, option) => {
                            const label = get(option, 'children[1]', '');
                            return label.toLowerCase().includes(input.toLowerCase());
                        }}
                    >
                        {options.map((v) => {
                            return (
                                <Select.Option key={v.token_id} value={v.token_id}>
                                    <img style={{ width: '20px', height: '20px', marginRight: '8px' }} src={v.url} />
                                    {v.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
