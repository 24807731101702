import { useCallback } from 'react';
import { useBoolean, useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, InputNumber, message, Space } from 'antd';
import { ProCard } from '@ant-design/pro-components';
import { get } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';

/**
 * APIs
 */
import {
    getAirdropOfficialConfigApi,
    OfficialAirdropConfigResult,
    updateAirdropOfficialConfigApi,
} from '@/services/official-airdrop-config';

const updateForm = async (data: OfficialAirdropConfigResult) => {
    const res = await updateAirdropOfficialConfigApi({
        content: JSON.stringify(data),
    });
    if (get(res, 'data.code') === 0) {
        return true;
    }
    message.error(get(res, 'data.msg', 'Error'));
    return false;
};
/**
 * Types
 */
import type { OfficialAirdropAmountResult, OfficialAirgropScoreResult } from '@/services/official-airdrop-config';
import type { IntlShape } from 'react-intl';

/**
 * Constants
 */
const childrenFormItemStyle: React.CSSProperties = {
    display: 'inline-block',
    // width: 200,
    marginBottom: 0,
};

const NumberInputStyle: React.CSSProperties = {
    width: 130,
};

const formItemLayoutData: Record<string, string> = {
    postMin: 's1',
    postCommentNewUserLogBase: 's2',
    postCommentOldUserLogBase: 's3',
    postVoteNewUserLogBase: 's4',
    postVoteOldUserLogBase: 's5',
    voteMin: 's6',
    commentMin: 's7',
    commentLikeLogBase: 's8',
    replyMinLogBase: 's9',
    inviteScore: 's10',
};

const PageOfficialAirdropConfig: React.FC = () => {
    /**
     * States
     */
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();
    const [serviceFormData, setServiceFormData] = useSetState<OfficialAirdropConfigResult>({
        airdropAmountLimits: [],
        scoreConfs: [],
    });
    const [submitting, { setTrue: setSubmittingTrue, setFalse: setSubmittingFalse }] = useBoolean(false);

    /**
     * Requests
     */

    useRequest(getAirdropOfficialConfigApi, {
        onSuccess: ({ data }) => {
            const configData: OfficialAirdropConfigResult = JSON.parse(get(data, 'data', '{}'));
            setServiceFormData(configData);
            const { airdropAmountLimits, scoreConfs } = configData;
            const airdropAmountLimit: OfficialAirdropAmountResult = airdropAmountLimits[0];
            const initFormData: Record<string, string | number | Array<string | number>> = {
                k1: airdropAmountLimit.k1,
                k2: airdropAmountLimit.k2,
                m: airdropAmountLimit.m,
            };
            scoreConfs.forEach((v: OfficialAirgropScoreResult) => {
                const feKey = formItemLayoutData[v.key];
                if (!feKey) return;
                initFormData[feKey] = v.params;
                initFormData[`${feKey}_1`] = String(v.params[0] || 0);
                initFormData[`${feKey}_2`] = String(v.params[1] || 0);
            });
            form.setFieldsValue(initFormData);
        },
    });

    const checkScoreFormula = useCallback((rule: any): Promise<void | any> | void => {
        if (!rule.field) {
            return Promise.resolve(null);
        }
        const field_1 = `${rule.field}_1`;
        const field_2 = `${rule.field}_2`;

        const values = form.getFieldsValue([field_1, field_2]);
        // 判断值是否存在
        if (!values[field_1] || !values[field_2]) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'common.nameRequired' })));
        }
        // number-input 设置了min 和 max，小于|大于设定值，会自动替换成min|max.
        // 所以正则用于判断格式，不处理最大值最小值。输出正确的错误信息
        // Min公式中的参数：正整数，1亿以内
        // s1 s6 s7 第一个参数是Min公式中的参数
        if (['s1', 's6', 's7'].includes(rule.field)) {
            const regex = /^\+?\d{1,9}$/;
            if (!regex.test(values[field_1])) {
                return Promise.reject(new Error(intl.formatMessage({ id: 'oa.p.oac.e.integerError' })));
            }
        }
        // number-input 设置了min 和 max，小于|大于设定值，会自动替换成min|max.
        // 所以正则用于判断格式，不处理最大值最小值。输出正确的错误信息
        // log底数、a10参数：正数，最多两位小数，1亿以内
        // s2 s3 s4 s5 s8 s9 第一个参数是log底数;
        // s10 第一个参数是 a10 和 log底数一样处理
        if (['s2', 's3', 's4', 's5', 's8', 's9', 's10'].includes(rule.field)) {
            const regex = /^(0|[1-9][0-9]{0,8})(\.[0-9]{1,2})?$/;
            if (!regex.test(values[field_1])) {
                return Promise.reject(new Error(intl.formatMessage({ id: 'oa.p.oac.e.validPositiveNumberError' })));
            }
        }
        // number-input 设置了min 和 max，小于|大于设定值，会自动替换成min|max.
        // 所以正则用于判断格式，不处理最大值最小值。输出正确的错误信息
        // 系数q：非负数，最多两位小数，1亿以内
        // 所有项的第二个参数都是 系数q
        const regex = /^(0|[1-9][0-9]{0,8})(\.[0-9]{1,2})?$/;
        if (!regex.test(values[field_2])) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'oa.p.oac.e.decimalsNumError' })));
        }
        return Promise.resolve(null);
    }, []);

    return (
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ flex: '0 0 200px' }}
            validateTrigger="onBlur"
            style={{ minWidth: 900 }}
        >
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProCard title={<FormattedMessage id="oa.p.oac.mainConfigTitle" />} headerBordered bordered>
                    <Form.Item>
                        <div>
                            <span style={{ display: 'inline-block', minWidth: 200, textAlign: 'right' }}>
                                {intl.formatMessage({ id: 'oa.p.oac.airdropAmount' })}:
                            </span>
                            <span> P = Min [ M , ( N1 * k1 + N2 * k2 ) ]</span>
                        </div>
                    </Form.Item>

                    <Form.Item
                        name="m"
                        label={intl.formatMessage({ id: 'oa.p.oac.airdropAmountMax' })}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min="1" max="1000000000000000" style={{ width: 540 }}></InputNumber>
                    </Form.Item>

                    <Form.Item
                        name="k1"
                        label={intl.formatMessage({ id: 'oa.p.oac.efficient' }, { value: 'k1' })}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min="0" max="100000000" style={{ width: 540 }}></InputNumber>
                    </Form.Item>

                    <Form.Item
                        name="k2"
                        label={intl.formatMessage({ id: 'oa.p.oac.efficient' }, { value: 'k2' })}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min="0" max="100000000" style={{ width: 540 }}></InputNumber>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                        <Permission permission="Airdrop_Airdrop-Config_Edit">
                            <Button
                                type="primary"
                                loading={submitting}
                                onClick={() => {
                                    form.validateFields(['m', 'k1', 'k2'])
                                        .then(async (values) => {
                                            setSubmittingTrue();
                                            const { airdropAmountLimits, scoreConfs } = serviceFormData;
                                            Object.assign(airdropAmountLimits[0], values);
                                            const toServerData = {
                                                airdropAmountLimits,
                                                scoreConfs,
                                            };
                                            if (await updateForm(toServerData)) {
                                                setServiceFormData(toServerData);
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            }
                                            setSubmittingFalse();
                                        })
                                        .catch(() => {});
                                }}
                            >
                                <FormattedMessage id="b.save" />
                            </Button>
                        </Permission>
                    </Form.Item>
                </ProCard>
                <ProCard title={<FormattedMessage id="oa.p.oac.userConfigTitle" />} headerBordered bordered>
                    <Form.Item label={intl.formatMessage({ id: 'oa.p.oac.userConfigAmount' })}>
                        <FormattedMessage id="oa.p.oac.userConfigAmountVla" />
                    </Form.Item>

                    <Form.Item name="s1" label="s1" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <div>
                                <FormattedMessage id="oa.p.oac.postScore" /> a1 * q1 = Min (
                                <FormattedMessage id="oa.p.oac.postScoreVla" />，
                            </div>

                            <Form.Item name="s1_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="1"
                                    max="100000000"
                                    step="1"
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>）*</span>

                            <Form.Item name="s1_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s2" label="s2" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.newUserCommentNum" /> a2 * q2 ={' '}
                                <FormattedMessage id="oa.p.oac.oldUserCommentNum" /> / [log
                            </span>

                            <Form.Item name="s2_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.oldUserCommentNum" />
                                )+1] *
                            </span>

                            <Form.Item name="s2_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s3" label="s3" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.oldUserCommentNumVla" /> a3 * q3 ={' '}
                                <FormattedMessage id="oa.p.oac.oldUserCommentNum" /> / [log
                            </span>

                            <Form.Item name="s3_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.oldUserCommentNum" />
                                )+1] *
                            </span>

                            <Form.Item name="s3_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s4" label="s4" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.newUserVoteNumVla" /> a4 * q4 ={' '}
                                <FormattedMessage id="oa.p.oac.voteUserNum" /> / [log
                            </span>

                            <Form.Item name="s4_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.voteUserNum" />
                                )+1] *
                            </span>

                            <Form.Item name="s4_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s5" label="s5" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.oldUserVoteNumVla" /> a4 * q5 ={' '}
                                <FormattedMessage id="oa.p.oac.voteUserNum" /> / [log
                            </span>

                            <Form.Item name="s5_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.voteUserNum" />
                                )+1] *
                            </span>

                            <Form.Item name="s5_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s6" label="s6" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.voteNumVla" /> a6 * q6 = Min (
                                <FormattedMessage id="oa.p.oac.voteNum" />，
                            </span>

                            <Form.Item name="s6_1" style={childrenFormItemStyle}>
                                <InputNumber min="1" max="100000000" stringMode style={NumberInputStyle}></InputNumber>
                            </Form.Item>

                            <span>）*</span>

                            <Form.Item name="s6_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s7" label="s7" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.commentNumScore" /> a6 * q6 = Min (
                                <FormattedMessage id="oa.p.oac.commentNum" />，
                            </span>

                            <Form.Item name="s7_1" style={childrenFormItemStyle}>
                                <InputNumber min="1" max="100000000" stringMode style={NumberInputStyle}></InputNumber>
                            </Form.Item>

                            <span>）*</span>

                            <Form.Item name="s7_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s8" label="s8" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.commentLikeScore" /> a8 * q8 ={' '}
                                <FormattedMessage id="oa.p.oac.likeNum" /> / [log
                            </span>

                            <Form.Item name="s8_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.likeNum" />
                                )+1] *{' '}
                            </span>

                            <Form.Item name="s8_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s9" label="s9" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.replyNumScore" /> a9 * q9 ={' '}
                                <FormattedMessage id="oa.p.oac.replyNum" /> / [log
                            </span>

                            <Form.Item name="s9_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span>
                                (<FormattedMessage id="oa.p.oac.replyNum" />
                                )+1] *{' '}
                            </span>

                            <Form.Item name="s9_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="s10" label="s10" rules={[{ required: true, validator: checkScoreFormula }]}>
                        <Space direction="horizontal" size={5} style={{ width: '100%' }}>
                            <span>
                                <FormattedMessage id="oa.p.oac.inviteScore" /> a10 * q10 ={' '}
                                <FormattedMessage id="oa.p.oac.inviteNum" /> *
                            </span>

                            <Form.Item name="s10_1" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0.01"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>

                            <span> * </span>

                            <Form.Item name="s10_2" style={childrenFormItemStyle}>
                                <InputNumber
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    precision={2}
                                    stringMode
                                    style={NumberInputStyle}
                                ></InputNumber>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                        <Permission permission="Airdrop_Airdrop-Config_Edit">
                            <Button
                                type="primary"
                                loading={submitting}
                                onClick={() => {
                                    const formFeildKeys: string[] = [];
                                    Object.values(formItemLayoutData).forEach((key) => {
                                        formFeildKeys.push(key);
                                        formFeildKeys.push(`${key}_1`);
                                        formFeildKeys.push(`${key}_2`);
                                    });
                                    form.validateFields(formFeildKeys)
                                        .then(async (values) => {
                                            setSubmittingTrue();
                                            const submitValues: Record<string, [string, string]> = {};
                                            for (const key in values) {
                                                // 嵌套formItem 的key是 field_1,field_2 格式
                                                const inputKeys = key.split('_');
                                                // 外层field 过滤掉，不处理。用于表单验证
                                                if (inputKeys.length === 1) {
                                                    continue;
                                                }
                                                if (!submitValues[inputKeys[0]]) {
                                                    submitValues[inputKeys[0]] = ['', ''];
                                                }
                                                submitValues[inputKeys[0]][inputKeys[1] === '1' ? 0 : 1] = values[key];
                                            }
                                            const { airdropAmountLimits, scoreConfs } = serviceFormData;
                                            scoreConfs.forEach((v) => {
                                                v.params = submitValues[formItemLayoutData[v.key]];
                                            });
                                            const toServerData = {
                                                airdropAmountLimits,
                                                scoreConfs,
                                            };
                                            if (await updateForm(toServerData)) {
                                                setServiceFormData(toServerData);
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            }
                                            setSubmittingFalse();
                                        })
                                        .catch(() => {});
                                }}
                            >
                                <FormattedMessage id="b.save" />
                            </Button>
                        </Permission>
                    </Form.Item>
                </ProCard>
            </Space>
        </Form>
    );
};

export default PageOfficialAirdropConfig;
