import { useCallback, useRef } from 'react';
import { useSetState } from 'ahooks';

/**
 * Components
 */
import ComponentDrawerFormCustom from './components/drawer-form-custom';
import ComponentTable from './components/table';

/**
 * Types
 */
import type { ActionType } from '@ant-design/pro-components';
import type { DrawerFormState } from './components/drawer-form-custom';
import type { OfficialAirdropItem } from '@/services/official-airdrop-list';

const PageOfficialAirdropList: React.FC = () => {
    /**
     * States
     */
    const tableActionRef = useRef<ActionType>();
    const [modalFormState, setModalFormState] = useSetState<DrawerFormState>({
        open: false,
        data: undefined,
    });

    /**
     * Callback
     */
    // entity 不存在时，是新增，存在时，是编辑
    const showModalFormEvent = useCallback((entity: OfficialAirdropItem | undefined) => {
        setModalFormState({
            open: true,
            data: entity,
        });
    }, []);

    const closeModalForm = useCallback((reload: boolean = false) => {
        setModalFormState({
            open: false,
            data: undefined,
        });
        if (reload) {
            tableActionRef.current?.reload();
        }
    }, []);

    return (
        <div>
            <ComponentTable tableActionRef={tableActionRef} showModalFormEvent={showModalFormEvent} />

            <ComponentDrawerFormCustom state={modalFormState} closeModelForm={closeModalForm} />
        </div>
    );
};

export default PageOfficialAirdropList;
