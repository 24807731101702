export default {
    sma: {
        b: {
            generated: 'Generate',
        },
        c: {
            inputAccountPlaceholder: '20字符数限制，可包含大小写字母、数字、特殊字符',
            inputPWDPlaceholder: '8 to 16 characters consisting of letters, digits, and special characters',
        },
    },
};
