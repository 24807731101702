import { ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Drawer, Flex, Form, Input, Popconfirm, Select, Spin, Typography } from 'antd';
import { get } from 'lodash';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { accounts } from '@/services/account';
import { cPrivatePoolConfig, dPrivatePoolConfig, privatePoolConfigs, uPrivatePoolConfig } from '@/services/ambassadors';
import { checkUserId } from '@/services/common';

/**
 * Components
 */
import Permission from '@/components/permission';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps } from 'antd';
import type { AccountsResponse } from '@/services/account';
import type { PrivatePoolConfigsResult } from '@/services/ambassadors';

type State = {
    accounts: AccountsResponse[];
    open: boolean;
    type: 'create' | 'update';
};

const PageAmbassadorsConfigurationsPrivatePoolConfig: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const { formatMessage } = useIntl();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [loading, setLoading] = useSetState<Record<string, boolean>>({});

    const [state, setState] = useSetState<State>({
        accounts: [],
        open: false,
        type: 'create',
    });

    /**
     * Requests
     */
    useRequest(accounts, {
        defaultParams: [{ limit: 50000, page: 1, status: 1, withRole: true }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                accounts: data.list.filter((account) => {
                    const permissions: string[] = get(account, 'roles.0.permissions', []);

                    return (
                        permissions.includes('super_admin') ||
                        permissions.includes('Ambassadors_Applications_Private-Pool')
                    );
                }),
            });
        },
    });

    const { loading: loadingCheckUserId, runAsync: runAsyncCheckUserId } = useRequest(checkUserId, {
        manual: true,
    });

    const { loading: loadingCPrivatePoolConfig, run: runCPrivatePoolConfig } = useRequest(cPrivatePoolConfig, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            // Close the drawer
            setState({
                open: false,
            });

            // Reload the table
            tableRef.current?.reload?.();
        },
    });

    const { loading: loadingUPrivatePoolConfig, run: runUPrivatePoolConfig } = useRequest(uPrivatePoolConfig, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            // Close the drawer
            setState({
                open: false,
            });

            // Reload the table
            tableRef.current?.reload?.();
        },
    });

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button
                    loading={loadingCheckUserId || loadingCPrivatePoolConfig || loadingUPrivatePoolConfig}
                    type="primary"
                    onClick={() => form.submit()}
                >
                    <FormattedMessage id="b.save" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: state.type === 'create' ? <FormattedMessage id="b.add" /> : <FormattedMessage id="b.edit" />,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runAsyncCheckUserId(values.user_id).then(
                ({ data: { data } }) => {
                    // 用户 ID 验证失败
                    if (!data) {
                        message.error(formatMessage({ id: 'c.checkUserId' }));
                        return;
                    }

                    if (state.type === 'create') {
                        return runCPrivatePoolConfig(values);
                    }

                    if (state.type === 'update') {
                        return runUPrivatePoolConfig(values.id, values);
                    }
                },
                (err) => {
                    message.error(err.message);
                },
            );
        },
    };

    const proTableProps: ProTableProps<PrivatePoolConfigsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: ['account', 'name'],
                title: <FormattedMessage id="t.c.account" />,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.operationsUid" />,
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Permission key="update" permission="Ambassadors_Configurations_Private-Pool-Config_Edit">
                        <a
                            onClick={() => {
                                form.setFieldsValue({
                                    ...entity,
                                    account_id: entity.account.id,
                                });

                                setState({
                                    open: true,
                                    type: 'update',
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,

                    <Permission key="delete" permission="Ambassadors_Configurations_Private-Pool-Config_Delete">
                        <Spin size="small" spinning={loading[entity.id] === true}>
                            <Popconfirm
                                title={<FormattedMessage id="c.areyousure" />}
                                onConfirm={() => {
                                    setLoading({
                                        [entity.id]: true,
                                    });

                                    dPrivatePoolConfig(entity.id)
                                        .then(() => tableRef.current?.reload?.())
                                        .finally(() => {
                                            setLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        rowKey: 'id',
        search: false,
        scroll: {
            x: 970,
            y: 'calc(100vh - 224px - 48px)',
        },
        toolbar: {
            actions: [
                <Permission key="create" permission="Ambassadors_Configurations_Private-Pool-Config_Add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({
                                open: true,
                                type: 'create',
                            });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
            ],
        },
        request: async (params) => {
            const res = await privatePoolConfigs({
                limit: params.pageSize,
                page: params.current,
            });

            if (get(res, 'data.code') !== 0) {
                message.error(get(res, 'data.msg'));
            }

            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return (
        <>
            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item hidden name="id">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 't.c.account' })}
                        name="account_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={state.type === 'update'}
                            optionFilterProp="label"
                            options={state.accounts.map((account) => ({
                                label: account.name,
                                value: account.id,
                            }))}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 't.c.operationsUid' })}
                        name="user_id"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default PageAmbassadorsConfigurationsPrivatePoolConfig;
