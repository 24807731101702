export default {
    'digitizedCivilizationSquare.table.headerTitle': 'Square lists',

    'digitizedCivilizationSquare.table.columns.name': 'Name',
    'digitizedCivilizationSquare.table.columns.area': 'Effective region',
    'digitizedCivilizationSquare.table.columns.status': 'Status',

    'digitizedCivilizationSquare.table.columns.sort': 'Sort',
    'digitizedCivilizationSquare.table.columns.l1': 'L1 tag',
    'digitizedCivilizationSquare.table.columns.icon': 'Icon',
    'digitizedCivilizationSquare.table.columns.headerImage': 'Header image',

    'digitizedCivilizationSquare.placeholder': 'Click the module on the left to configure content',
};
