import { get } from 'lodash';

/**
 * Types
 */
import type { Debate, FormalDebate } from '@/services/posts';

/**
 * Props
 */
type Props = {
    debate: Debate | null;
    formalDebate: FormalDebate | null;
    against?: string | null;
    forTxt?: string | null;
};

const ComponentTableCellDebate: React.FC<Props> = ({ against, forTxt, debate, formalDebate }) => {
    let forText = against;
    let againstText = forTxt;
    if (debate) {
        forText = get(debate, 'for_text', '');
        againstText = get(debate, 'against_text', '');
    } else if (formalDebate) {
        forText = get(formalDebate, 'pro_text', '');
        againstText = get(formalDebate, 'con_text', '');
    }
    if (!forText && !againstText) {
        return '-';
    }
    return (
        <div>
            <div>正方：{forText}</div>
            <div>反方：{againstText}</div>
        </div>
    );
};

export default ComponentTableCellDebate;
