import Permission from '@/components/permission';
import {
    CreatorAcademyDetailResult,
    CreatorAcademyResult,
    dCreatorAcademyDetailApi,
    gCreatorAcademyDetailApi,
    sCreatorAcademyDetailApi,
} from '@/services/creator-academy';
import { HolderOutlined } from '@ant-design/icons';
import { ActionType, DragSortTable, DragTableProps } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { Button, Spin, Popconfirm, Typography, App } from 'antd';
import { useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ComponentDrawerFormRightDetail from './drawer-form-right-detail';

/**
 * Types
 */
type Props = {
    language: string;
    subjectEntity: CreatorAcademyResult;
};
type State = {
    dataSource: CreatorAcademyDetailResult[];
    sorting: boolean;
    openDrawer: boolean;
    entity: CreatorAcademyDetailResult | null;
};

function ComponentRightTable({ subjectEntity, language }: Props) {
    /**
     * Hooks
     */
    const intl = useIntl();

    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        sorting: false,
        openDrawer: false,
        entity: null,
    });

    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const {
        loading: loadingCreatorAcademyDetailApi,
        refresh: refreshCreatorAcademyDetailApi,
        run: runCreatorAcademyDetailApi,
    } = useRequest(gCreatorAcademyDetailApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data || [],
                sorting: false,
            });
        },
    });

    const { run: runCreatorAcademyDetailSortApi, loading: loadingCreatorAcademyDetailSortApi } = useRequest(
        sCreatorAcademyDetailApi,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }
                refreshCreatorAcademyDetailApi();
            },
        },
    );
    /**
     * ChildrenProps
     */
    const dragSortTableProps: DragTableProps<CreatorAcademyDetailResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                fixed: 'left',
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'content_id',
                ellipsis: true,
                title: <FormattedMessage id="cad.t.c.postOrShortsId" />,
                width: 200,
            },
            {
                dataIndex: 'title',
                ellipsis: true,
                title: <FormattedMessage id="cad.t.c.title" />,
            },
            {
                dataIndex: 'cover_image',
                title: <FormattedMessage id="cad.t.c.cover" />,
                valueType: 'image',
                width: 80,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                ellipsis: true,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_',
                fixed: 'right',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                render: (_, entity) => [
                    <Permission permission="Creator-Center_Creator-Hub" key="detail">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    <Permission permission="Creator-Center_Creator-Hub" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    dCreatorAcademyDetailApi(entity.id)
                                        .then(({ data: { code, msg } }) => {
                                            setCellLoading({ [entity.id]: false });
                                            if (code !== 0) {
                                                return message.error(msg || 'Error');
                                            }
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            refreshCreatorAcademyDetailApi();
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                            message.error('Error');
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading: loadingCreatorAcademyDetailApi,
        options: {
            reload: refreshCreatorAcademyDetailApi,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 1180,
            y: `calc(100vh - 280px - 8px)`,
        },
        search: false,
        headerTitle: subjectEntity && subjectEntity.name,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingCreatorAcademyDetailSortApi}
                        onClick={() => {
                            runCreatorAcademyDetailSortApi({ ids: state.dataSource.map((item) => item.id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingCreatorAcademyDetailSortApi}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshCreatorAcademyDetailApi();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Permission permission="Creator-Center_Creator-Hub" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Creator-Center_Creator-Hub" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: (_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
            setState({
                dataSource: newDataSource,
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (subjectEntity && subjectEntity.id) {
            runCreatorAcademyDetailApi({
                subject_id: subjectEntity.id,
            });
        } else {
            setState({
                dataSource: [],
                sorting: false,
                openDrawer: false,
                entity: null,
            });
        }
    }, [subjectEntity]);

    return (
        <>
            <DragSortTable {...dragSortTableProps} style={{ display: subjectEntity ? '' : 'none' }} />

            <div
                style={{
                    textAlign: 'center',
                    padding: '20vh 20px 0',
                    fontSize: 20,
                    color: '#7F7F7F',
                    display: subjectEntity ? 'none' : '',
                }}
            >
                <FormattedMessage id="cad.i.empty" />
            </div>

            <ComponentDrawerFormRightDetail
                open={state.openDrawer}
                entity={state.entity}
                language={language}
                subjectId={subjectEntity?.id}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshCreatorAcademyDetailApi();
                }}
            />
        </>
    );
}

export default ComponentRightTable;
