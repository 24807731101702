import axios from './index';

/**
 * APIs
 */
// 获取大使申请列表 - 公海池
export const ambassadorApplicationsPublic = (params: AmbassadorApplicationsParams) => {
    return axios<PageResType<AmbassadorApplicationsResult>>('/v2/ambassadors/application/public', {
        method: 'get',
        params,
    });
};

// 获取大使申请列表 - 私海池
export const ambassadorApplicationsPrivate = (params: AmbassadorApplicationsParams) => {
    return axios<PageResType<AmbassadorApplicationsResult>>('/v2/ambassadors/application/private', {
        method: 'get',
        params,
    });
};

// 获取大使申请列表 - 审核记录
export const ambassadorApplicationsRecords = (params: AmbassadorApplicationsParams) => {
    return axios<PageResType<AmbassadorApplicationsResult>>('/v2/ambassadors/application/records', {
        method: 'get',
        params,
    });
};

// 批量更新大使申请 - 公海池
export const bulkUAmbassadorApplicationPublic = (data: BulkUAmbassadorApplicationData) => {
    return axios<ResType>('/v2/ambassadors/application/public', {
        method: 'put',
        data,
    });
};

// 批量更新大使申请 - 私海池
export const bulkUAmbassadorApplicationPrivate = (data: BulkUAmbassadorApplicationData) => {
    return axios<ResType>('/v2/ambassadors/application/private', {
        method: 'put',
        data,
    });
};

// 导出大使申请列表 - 审核记录
export const ambassadorApplicationsRecordsExport = (params: AmbassadorApplicationsParams) => {
    return axios('/v2/ambassadors/application/export/async', {
        method: 'get',
        params,
    });
};

// 获取大使列表
export const ambassadors = (params: AmbassadorsParams) => {
    return axios<PageResType<AmbassadorsResult>>('/v2/ambassadors', {
        method: 'get',
        params,
    });
};

// 导出大使列表
export const ambassadorsExport = (params: AmbassadorsParams) => {
    return axios('/v2/ambassadors/export/async', {
        method: 'get',
        params,
    });
};

// 批量更新大使
export const bulkUAmbassador = (data: BulkUAmbassadorData) => {
    return axios<ResType>('/v2/ambassadors', {
        method: 'put',
        data,
    });
};

// 获取大使数据列表
export const ambassadorDatas = (params: AmbassadorDatasParams) => {
    return axios<PageResType<AmbassadorDatasResult>>('/v2/ambassadors/report', {
        method: 'get',
        params,
    });
};

// 导出大使数据列表
export const ambassadorDatasExport = (params: AmbassadorDatasExportParams) => {
    return axios('/v2/ambassadors/report/export/async', {
        method: 'get',
        params,
    });
};

// 获取申请私海池配置列表
export const privatePoolConfigs = (params: PrivatePoolConfigsParams) => {
    return axios<PageResType<PrivatePoolConfigsResult>>('/v2/ambassadors/private_pool/config', {
        method: 'get',
        params,
    });
};

// 创建申请私海池配置
export const cPrivatePoolConfig = (data: CPrivatePoolConfigData) => {
    return axios<ResType<PrivatePoolConfigsResult>>('/v2/ambassadors/private_pool/config', {
        method: 'post',
        data,
    });
};

// 更新申请私海池配置
export const uPrivatePoolConfig = (id: string, data: UPrivatePoolConfigData) => {
    return axios<ResType<PrivatePoolConfigsResult>>(`/v2/ambassadors/private_pool/config/${id}`, {
        method: 'put',
        data,
    });
};

// 删除申请私海池配置
export const dPrivatePoolConfig = (id: string) => {
    return axios<ResType>(`/v2/ambassadors/private_pool/config/${id}`, {
        method: 'delete',
    });
};

// 获取大使公海池列表
export const ambassadorPublicPool = (params: AmbassadorApplicationsParams) => {
    return axios<PageResType<AmbassadorApplicationsResult>>('/v1/ambassadors/public', {
        method: 'get',
        params,
    });
};

// v3 大使分配
export const ambassadorAssign = (data: BulkUAmbassadorData) => {
    return axios<ResType>('/v1/ambassadors/assign', {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

// 获取大使申请列表
export type AmbassadorApplicationsParams = {
    account_id?: string;
    apply_end?: string;
    apply_start?: string;
    limit?: number | string;
    nickname?: string;
    operation_end?: string;
    operation_start?: string;
    operator_account_id?: string;
    order_by?: string;
    order_sort?: string;
    page?: number | string;
    parent_user_id?: string;
    request_email?: string;
    request_id?: string;
    statuses?: ('approved' | 'pending' | 'rejected')[];
    types?: ('assigned' | 'unassigned')[];
    user_id?: string;
    username?: string;
};

export type AmbassadorApplicationsResult = {
    account: {
        id: string;
        name: string;
    };
    created_at: string;
    id: string;
    operator_account: {
        id: string;
        name: string;
    };
    parent_user_id: string;
    parent_user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
    reject_reason: string;
    request: Record<string, any>;
    request_id: string;
    status: 'approved' | 'pending' | 'rejected';
    type: 'assigned' | 'unassigned';
    updated_at: string;
    user_id: string;
    user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
};

// 批量更新大使申请
export type BulkUAmbassadorApplicationData = {
    ids: string[];
    account_id?: string;
    reject_reason?: string;
    status?: 'approved' | 'pending' | 'rejected';
};

// 获取大使列表
export type AmbassadorsParams = {
    account_id?: string;
    apply_end?: string;
    apply_start?: string;
    level?: string;
    limit?: number | string;
    operator_account_id?: string;
    order_by?: string;
    order_sort?: 'asc' | 'desc';
    page?: number | string;
    parent_id?: string;
    remark?: string;
    statuses?: ('normal' | 'disabled' | 'deregistered')[];
    status?: 'normal' | 'deregistered';
    update_end?: string;
    update_start?: string;
    user_id?: string;
    nickname?: string;
    username?: string;
};

export type AmbassadorsResult = {
    account: {
        id: string;
        name: string;
    };
    application: AmbassadorApplicationsResult;
    created_at: string;
    id: string;
    level: string;
    operator_account: {
        id: string;
        name: string;
    };
    parent_id: string;
    remark: string;
    status: 'normal' | 'disabled' | 'deregistered';
    updated_at: string;
    user_id: string;
    user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
};

// 批量更新大使
export type BulkUAmbassadorData = {
    ids: string[];
    account_id?: string;
    remark?: string;
    status?: 'normal' | 'disabled' | 'deregistered' | 'unbound';
};

// 获取大使数据列表
export type AmbassadorDatasParams = {
    status?: 'normal' | 'deregistered';
    account_id?: string;
    date_end?: string;
    date_start?: string;
    limit?: number | string;
    order_by?: string;
    order_sort?: 'asc' | 'desc';
    page?: number | string;
    user_id?: string;
};

export type AmbassadorDatasResult = {
    account: {
        id: string;
        name: string;
    };
    application: AmbassadorApplicationsResult;
    active_user_count: number;
    post_count: number;
    post_user_count: number;
    realname_count: number;
    register_count: number;
    user_id: string;
    user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
};

// 导出大使数据列表
export type AmbassadorDatasExportParams = Pick<
    AmbassadorDatasParams,
    'account_id' | 'date_end' | 'date_start' | 'user_id'
>;

// 获取申请私海池配置列表
export type PrivatePoolConfigsParams = {
    account_id?: string;
    limit?: number | string;
    operator_account_id?: string;
    order_by?: string;
    order_sort?: 'asc' | 'desc';
    page?: number | string;
    user_id?: string;
};

export type PrivatePoolConfigsResult = {
    account: {
        id: string;
        name: string;
    };
    created_at: string;
    deleted_at: string;
    id: string;
    operator_account: {
        id: string;
        name: string;
    };
    updated_at: string;
    user_id: string;
    user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
};

// 创建申请私海池配置
export type CPrivatePoolConfigData = {
    account_id: string;
    user_id: string;
};

// 更新申请私海池配置
export type UPrivatePoolConfigData = Partial<CPrivatePoolConfigData>;
