import axios from './index';

/**
 * APIs
 */
// 获取流动性池列表
export const tokenPoolApi = (params: TokenPoolParams) => {
    return axios<PageResType<TokenPool>>('/v1/web3/token/pools', {
        method: 'get',
        params,
    });
};

// 添加流动性池
export const createTokenPool = (data: CreateTokenPool) => {
    return axios<ResType>('/v1/web3/token/pools', {
        method: 'post',
        data,
    });
};

// 修改流动性池
export const updateTokenPool = (data: TokenDefaultFee) => {
    return axios<ResType>(`/v1/web3/token/pools/defaultFee`, {
        method: 'post',
        data,
    });
};

// /v1/web3/token/pools/byPair
export const tokenPoolByPairApi = (params: TokenPoolByPairParams) => {
    return axios<ResType<TokenPool[]>>('/v1/web3/token/pools/byPair', {
        method: 'get',
        params,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

export type TokenPoolParams = {
    [key: string]: any;
};

export type TokenDefaultFee = {
    /**
     * tokenA
     */
    tokenA: number;
    /**
     * tokenB
     */
    tokenB: string;
    /**
     * 费率
     */
    fee: number;
};

export type TokenPoolByPairParams = {
    token0: string;
    token1: string;
};

/**
 * 流动性池
 */
export type CreateTokenPool = {
    /**
     * tokenA
     */
    tokenA: string;
    /**
     * tokenA_id
     */
    tokenA_id: string;
    /**
     * tokenA_sort
     */
    tokenA_sort: string;
    /**
     * tokenB
     */
    tokenB: string;
    /**
     * tokenB_id
     */
    tokenB_id: string;
    /**
     * tokenB_sort
     */
    tokenB_sort: string;
    /**
     * 费率
     */
    fee: number;
    /**
     * 默认费率
     */
    default_fee: string;
    /**
     * 初始化价格
     */
    price: string;
};

/**
 * 流动性池
 */
export interface TokenPool {
    /**
     * ID
     */
    id: string;
    /**
     * token0
     */
    token0: string;
    /**
     * token1
     */
    token1: string;
    /**
     * Fee Tier
     */
    fee: number;
    /**
     * 默认费率
     */
    default_fee: string;
    /**
     * 初始化价格
     */
    price: string;
    /**
     * 操作人
     */
    operator: string;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 更新时间
     */
    updated_at: Date;
}
