import { useEffect, useRef } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { Button, Spin, Popconfirm, Typography, App } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { ActionType, DragSortTable, DragTableProps } from '@ant-design/pro-components';
import { useIntl, FormattedMessage } from 'react-intl';

/**
 * Components
 */
import ComponentDrawerFormRightDetail from './drawer-form-right-detail';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { dTypeDetailApi, gTypeNFTListApi, sTypeDetailApi } from '@/services/marketplace-nft';

/**
 * Types
 */
import type { NFTTypeDetailResult, NFTTypeListResult } from '@/services/marketplace-nft';

type Props = {
    language: string;
    subjectEntity: NFTTypeListResult | null | undefined;
};
type State = {
    dataSource: NFTTypeDetailResult[];
    sorting: boolean;
    openDrawer: boolean;
    entity: NFTTypeDetailResult | null | undefined;
};

function ComponentRightTable({ subjectEntity, language }: Props) {
    /**
     * Hooks
     */
    const intl = useIntl();

    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        sorting: false,
        openDrawer: false,
        entity: null,
    });

    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const {
        loading: loadingTypeNFTListApi,
        refresh: refreshTypeNFTListApi,
        run: runTypeNFTListApi,
    } = useRequest(gTypeNFTListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setState({
                dataSource: (data || []).map((item) => {
                    item._fe_created_at = parseInt(`${item.created_at}`, 10);
                    return item;
                }),
                sorting: false,
            });
        },
    });

    const { run: runSortTypeDetailApi, loading: loadingSortTypeDetailApi } = useRequest(sTypeDetailApi, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            refreshTypeNFTListApi();
        },
    });
    /**
     * ChildrenProps
     */
    const dragSortTableProps: DragTableProps<NFTTypeDetailResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                fixed: 'left',
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'market_nft_id',
                ellipsis: true,
                title: 'ID',
                width: 200,
            },
            {
                dataIndex: ['nft_info', 'nft_name'],
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.nftName" />,
            },
            {
                dataIndex: ['nft_info', 'nft_url'],
                title: <FormattedMessage id="mknft.t.c.img" />,
                valueType: 'image',
                width: 80,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                ellipsis: true,
                width: 200,
            },
            {
                dataIndex: '_fe_created_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_',
                fixed: 'right',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                render: (_, entity) => [
                    <Permission permission="Marketplace_NFT_NFT-Marketplace" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    dTypeDetailApi({
                                        collection_id: subjectEntity?.collection_id as string,
                                        nft_id: entity.nft_info.id,
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            setCellLoading({ [entity.id]: false });
                                            if (code !== 0) {
                                                return message.error(msg || 'Error');
                                            }
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            refreshTypeNFTListApi();
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                            message.error('Error');
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading: loadingTypeNFTListApi,
        options: {
            reload: refreshTypeNFTListApi,
        },
        pagination: false,
        // Fix bug，会导致排序拖动无响应
        // (entity) => entity.nft_info.id
        rowKey: 'id',
        scroll: {
            x: 1180,
            y: `calc(100vh - 280px - 8px)`,
        },
        search: false,
        headerTitle: subjectEntity && subjectEntity.name,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingSortTypeDetailApi}
                        onClick={() => {
                            runSortTypeDetailApi({
                                collection_id: subjectEntity?.collection_id as string,
                                nft_ids: state.dataSource.map((item) => item.nft_info.id),
                            });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingSortTypeDetailApi}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshTypeNFTListApi();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Permission permission="Marketplace_NFT_NFT-Marketplace" key="add">
                    <Button
                        disabled={state.dataSource.length >= 10}
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Marketplace_NFT_NFT-Marketplace" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: (_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
            setState({
                dataSource: newDataSource,
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (subjectEntity && subjectEntity.id) {
            runTypeNFTListApi({
                collection_id: subjectEntity.collection_id,
            });
        } else {
            setState({
                dataSource: [],
                sorting: false,
                openDrawer: false,
                entity: null,
            });
        }
    }, [subjectEntity]);

    return (
        <>
            <DragSortTable {...dragSortTableProps} style={{ display: subjectEntity ? '' : 'none' }} />

            <div
                style={{
                    textAlign: 'center',
                    padding: '20vh 20px 0',
                    fontSize: 20,
                    color: '#7F7F7F',
                    display: subjectEntity ? 'none' : '',
                }}
            >
                <FormattedMessage id="mknft.i.empty" />
            </div>

            <ComponentDrawerFormRightDetail
                open={state.openDrawer}
                entity={state.entity}
                language={language}
                collection_id={subjectEntity?.collection_id as string}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshTypeNFTListApi();
                }}
            />
        </>
    );
}

export default ComponentRightTable;
