import axios from './index';

/**
 * APIs
 */
// airdrop official table list
export const airdropOfficialListApi = (query: Record<string, any>) => {
    return axios<ResType<AirdropAccListResult[]>>('/v1/airdrop/official/season', {
        method: 'get',
        params: query,
    });
};

// delete airdrop official by id
export const deleteAirdropOfficialByIdApi = (recordId: string | number) => {
    return axios<ResType<AirdropAccListResult>>(`/v1/airdrop/official/season/${recordId}`, {
        method: 'delete',
    });
};

// create airdrop official
export const createAirdropOfficialApi = (data: CreateAirdropOfficialData) => {
    return axios<ResType<AirdropAccListResult>>('/v1/airdrop/official/season', {
        method: 'post',
        data,
    });
};

// update airdrop official
export const updateAirdropOfficialApi = (id: string | number, season: string) => {
    return axios<ResType<AirdropAccListResult>>(`/v1/airdrop/official/season/${id}`, {
        data: {
            season,
        },
        method: 'put',
    });
};

// airdrop official distribute
export const airdropOfficialDistributeApi = (id: string | number) => {
    return axios<ResType<AirdropAccListResult>>(`/v1/airdrop/official/season/${id}/distribute`, {
        method: 'put',
    });
};

/**
 * Error Code
 */

export const AirdropAccApiErrorCodeMsg: Record<number, string> = {
    210201: 'oa.p.ae.i.seasonNotStart', // 空投季尚未开始 - 102100
    210202: 'su.f.AccountNotExist', // 102101
    210203: 'oa.p.ae.i.seasonNotStartButAc', // 空投季尚未开始已激活 - 102102
    210204: 'oa.p.ae.i.seasonStatusError', // Airdrop season status is not pending 102112
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

// airdrop official item
export type OfficialAirdropItem = {
    id: number;
    status: string;
    season: number;
    tokenId: string;
    label: string;
    describe: string;
    seasonStartAt: string;
    seasonEndAt: string;
    creator: string;
    createdAt: string | number;
    distributeAt: string | number;
    _seasonRange: number[];
};

// airdrop official list result
export type AirdropAccListResult = {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    list: OfficialAirdropItem[];
};

// create airdrop official type
export type CreateAirdropOfficialData = {
    season: string;
    seasonStartAt: string;
    seasonEndAt: string;
};
