import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });
/**
 * APIs
 */
// 举报管理-帖子举报
export const PostsReportApi = (params: PostReportRequestsParams) => {
    return axios<PageResType<PostReportRecordResult>>('/v1/reports/posts', {
        method: 'get',
        params,
    });
};

// 举报管理-评论举报
export const repliesReportApi = (params: RepliesReportRequestsParams) => {
    return axios<PageResType<RepliesReportRecordResult>>('/v1/reports/replies', {
        method: 'get',
        params,
    });
};

// 举报管理-用户举报
export const profileReportApi = (params: ProfileReportRequestsParams) => {
    return axios<PageResType<ProfileReportResult>>('/v1/reports/profile', {
        method: 'get',
        params,
    });
};

// 获取操作人
export const reportReviewRecordsPostsOperatedByApi = () => {
    return axios<ResType<any>>('/v1/accounts', {
        method: 'get',
        params: {
            withRole: false,
        },
    });
};

// 举报管理-更新举报状态
export const changeReportStatusApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>('/v1/reports', {
        method: 'put',
        data,
    });
};

// 举报管理-用户资料重置
export const changeUserReportStatusApi = (id: string | number, reset_items: string[]) => {
    return axios<ResType<void>>(`/v1/reports/profile/reset/${id}`, {
        method: 'put',
        data: {
            reset_items,
        },
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

/**
 * 举报类型
 */
export enum ReportType {
    Misinformation = 'misinformation',
    Other = 'other',
    PlatformSafety = 'platform_safety',
    Racism = 'racism',
}

/**
 * 状态
 */
export enum Status {
    Normal = 'normal',
    Pending = 'pending',
    Violate = 'violate',
}

// 举报响应公共参数
export type ReportCommon = {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 操作人
     */
    operated_by: string;
    /**
     * 其他原因
     */
    reason?: null | string;
    /**
     * 举报类型
     */
    report_type: ReportType;
    /**
     * 重置项，以英文逗号隔开
     */
    reset_items?: null | string;
    /**
     * 状态
     */
    status: Status;
    type: string;
    /**
     * 更新时间
     */
    updated_at: Date;
};

// 帖子举报
export type PostReportRecordResult = {
    content: PostReportContentResult;
} & ReportCommon;

// 帖子举报Api参数
export type PostReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operated_by?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status: Status[];
};

export type PostReportFormParams = PostReportRequestsParams & {
    operation_date: any;
};
// 评论举报
export type RepliesReportRecordResult = {
    content: RepliesReportContentResult;
} & ReportCommon;

// 评论举报
export type RepliesReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 发布者ID
     */
    creator_uid?: string;
    /**
     * 评论ID
     */
    evaluate_id?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status: Status[];
    operation_date?: string[];
};

// 用户举报
export type ProfileReportResult = {
    content: ProfileReportContentResult;
} & ReportCommon;

// 用户举报Api参数
export type ProfileReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status: Status[];
    /**
     * 用户ID
     */
    user_id?: string;
};

export type ChangeReportStatusParams = {
    /**
     * 举报ID
     */
    ids: string[];
    /**
     * 状态
     */
    status: Status;
};

// ==================  new
/**
 * 举报用户信息
 */
export type ReportUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报帖子内容
 *
 * @desc 仅在 type 为 2、3 时存在
 */
export type ReportedPostContent = {
    audios: Audio[];
    content: Content;
    ext: Ext;
    post_id: string;
    post_type: string;
    status: string;
    userInfo: UserInfo;
};

/**
 * 帖子音频
 */
export type Audio = {
    audio_duration?: number;
    text?: string;
    url: string;
};

/**
 * 帖子内容
 */
export type Content = {
    body: string;
    debate: Debate;
    uploaded_materials: string[];
};

/**
 * 投票信息
 *
 * @desc 投票帖有内容，否则为 null
 */
export type Debate = {
    against_text: string;
    expired_at: number;
    for_text: string;
    title: string;
};

/**
 * 扩展信息
 */
export type Ext = {
    debate_ext: Debateext;
};

/**
 * 投票扩展信息
 */
export type Debateext = {
    vote_against_count: number;
    vote_for_count: number;
};

/**
 * 帖子用户信息
 */
export type UserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报用户信息
 */
export type ReportedUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 评论内容
 */
export type ReportedReplyContentContent = {
    audio_duration: number;
    body: string;
    uploaded_materials: string[];
};

/**
 * 评论用户信息
 */
export type ReportedReplyContentUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报评论内容
 *
 * @desc 仅在 type 为 2 时存在
 */
export type ReportedReplyContent = {
    comment_id: string;
    content: ReportedReplyContentContent;
    parentInfo: null;
    status: string;
    userInfo: ReportedReplyContentUserInfo;
};

/**
 * 帖子举报
 */
export type PostReportContentResult = Omit<RepliesReportContentResult, 'reportedReplyContent'>;

/**
 * 评论举报
 */
export type RepliesReportContentResult = {
    reportedPostContent: ReportedPostContent;
    reportedReplyContent: ReportedReplyContent;
    reportedUserInfo: ReportedUserInfo;
    reportReason: string;
    reportType: string;
    reportUserInfo: ReportUserInfo;
    type: number;
};

/**
 * 用户举报
 */
export type ProfileReportContentResult = {
    reportedUserInfo: ReportedUserInfo;
    reportReason: string;
    reportType: string;
    reportUserInfo: ReportUserInfo;
    type: number;
};
