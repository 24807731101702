export default {
    su: {
        t: {
            c: {
                airdropCountRange: '空投人数范围',
                domain: '域名',
            },
        },
        f: {
            pleaseInputUID: '请输入UID',
            uidFormatError: '请输入正确的uid',
            AccountExist: '账户已存在',
            airdropCountRangeError: '请输入1-1000000之间的数字',
            AccountNotExist: '账户不存在',
            MaxAirdropLtMin: '最大空头人数要大于最小空投人数',
            uidRepeat: '用户UID已存在',
            domainFormateError: '域名格式错误',
            domainExit: '域名已存在',
        },
    },
};
