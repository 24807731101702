export default {
    p: {
        appVersion: {
            title: 'App Version Management',
            version: 'Latest version',
            androidApk: 'Android APK Address',
            googlePlay: 'Google Play Address',
            appStore: 'App Store address',
            save: 'Save',
            cancel: 'Cancel',
            modify: 'Modify',
            lowestVersion: 'Force update lowest version',
            approvedVersion: 'Approved version',
            language: 'Multi-language',
            versionUpdateTitle: 'Version update title',
            versionUpdateContent: 'Version update content',
        },
    },
};
