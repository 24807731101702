import { Image } from 'antd';

/**
 * Props
 */
type Props = {
    cover: string;
    source_url: string;
    large_url?: string;
};

/**
 * Constants
 */
const DEFAULT_COVER = '/video.png';
// const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

const ComponentTableCellMedia: React.FC<Props> = ({ cover, source_url, large_url }) => {
    if (!large_url && !source_url) {
        return '-';
    }
    return (
        <Image
            src={cover ? cover : DEFAULT_COVER}
            preview={{
                destroyOnClose: true,
                imageRender: () => (
                    <video
                        width="80%"
                        autoPlay
                        style={{ objectFit: 'revert', maxHeight: '96vh' }}
                        controls
                        src={source_url || large_url}
                    />
                ),
                toolbarRender: () => null,
            }}
            width={80}
        />
    );
};

export default ComponentTableCellMedia;
