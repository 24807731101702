export default {
    p: {
        exports: {
            t: {
                c: {
                    exportContent: 'Exported Content',
                    status: 'Status',
                },
            },
            status: {
                processing: 'Exporting',
                failed: 'Failed',
                completed: 'Completed',
            },
        },
    },
};
