import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { App, Button, Drawer, Form, Input, Space, Upload } from 'antd';
import { HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { DragSortTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { cloneDeep, get, isEmpty } from 'lodash';
import store from 'store2';

/**
 * APIs
 */
import { uploadImage } from '@/services/common';
import { tagsGetLeafCategory } from '@/services/other-ai';
import { uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { DragTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, UploadProps } from 'antd';

type DataType = {
    created_at: string;
    icon: string;
    id: string;
    image: string;
    name: string;
    operator: string;
    updated_at: string;
};

type State = {
    index: number;
    open: boolean;
    uploadIcon: boolean;
    uploadBanner: boolean;
    sorting: boolean;
};

const ComponentBlockTags: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        index: -1,
        open: false,
        uploadIcon: false,
        uploadBanner: false,
        sorting: false,
    });

    const { current, dataSource, setCurrent, setDataSource, setRefreshDataSource } =
        useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    useRequest(tagsGetLeafCategory, {
        ready: isEmpty(current!.data),
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            current!.data = data.map((item) => ({
                created_at: item.created_at,
                icon: 'https://socrates.s3.us-east-2.amazonaws.com/image/2024/08/24/33b09df1f2a7a85f92d96309c01fda18.png',
                id: item.id,
                image: 'https://socrates.s3.us-east-2.amazonaws.com/image/2024/08/24/33b09df1f2a7a85f92d96309c01fda18.png',
                name: item.names['en-US'],
                operator: store('user').name,
                updated_at: item.updated_at,
            }));
        },
    });

    const { run: runUBlock, loading: loadingUBlock } = useRequest(uBlock, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code === 150203) {
                return message.error('It has been edited by someone else, please refresh');
            }

            if (code !== 0) {
                return message.error(msg);
            }

            const index = dataSource.findIndex((item) => item.id === current!.id);

            dataSource[index] = {
                ...current!,
                ...data,
            };

            setCurrent(data);

            setDataSource(dataSource);

            setState({
                open: false,
                uploadIcon: true,
                uploadBanner: true,
                sorting: false,
            });

            message.success('Updated');
        },
    });

    /**
     * ChildrenProps
     */
    const dragTableProps: DragTableProps<DataType, any> = {
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.l1" />,
            },
            {
                dataIndex: 'icon',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.icon" />,
                valueType: 'image',
            },
            {
                dataIndex: 'image',
                title: <FormattedMessage id="digitizedCivilizationSquare.table.columns.headerImage" />,
                valueType: 'image',
            },
            {
                dataIndex: 'operator',
                title: <FormattedMessage id="common.table.columns.operatedBy" />,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="common.table.columns.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                key: 'option',
                title: <FormattedMessage id="common.table.columns.action" />,
                valueType: 'option',
                width: 80,
                render: (_, entity, index) => [
                    <a
                        key="update"
                        onClick={() => {
                            form.setFieldsValue({
                                ...entity,
                                icon: entity.icon
                                    ? [
                                          {
                                              name: 'icon',
                                              url: entity.icon,
                                          },
                                      ]
                                    : [],
                                image: entity.image
                                    ? [
                                          {
                                              name: 'image',
                                              url: entity.image,
                                          },
                                      ]
                                    : [],
                            });

                            setState({
                                index,
                                open: true,
                                uploadIcon: !entity.icon,
                                uploadBanner: !entity.image,
                            });
                        }}
                    >
                        <FormattedMessage id="common.edit" />
                    </a>,
                ],
            },
        ],
        dataSource: current!.data,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        headerTitle: current!.name,
        options: {
            reload: () => {
                setRefreshDataSource();
            },
        },
        pagination: false,
        rowKey: 'id',
        search: false,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingUBlock}
                        onClick={() => {
                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,

                    <Button
                        loading={loadingUBlock}
                        onClick={() => {
                            setState({ sorting: false });
                            const index = dataSource.findIndex((item) => item.id === current!.id);

                            const oldCurrentData = dataSource[index].data;

                            setCurrent({
                                ...current!,
                                data: oldCurrentData,
                            });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Button
                    key="sort"
                    type="primary"
                    ghost
                    onClick={() => {
                        setState({ sorting: true });
                    }}
                >
                    <FormattedMessage id="tc.t.c.sort" />
                </Button>,
            ];
        },
        onDragSortEnd: (_, __, newDataSource) => {
            setCurrent({ ...current!, data: newDataSource });
        },
    };

    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    const prevData = cloneDeep(current!.data) || [];

                                    prevData[state.index] = {
                                        ...prevData[state.index],
                                        ...values,
                                        icon: get(values, 'icon.0.url', ''),
                                        image: get(values, 'image.0.url', ''),
                                        operator: store('user').name,
                                        updated_at: new Date().toISOString(),
                                    };

                                    runUBlock(current!.id, {
                                        data: prevData,
                                        updated_at: current!.updated_at,
                                    });
                                })
                                .catch(() => {});
                        }}
                    >
                        保存
                    </Button>
                </Space>
            </div>
        ),
        maskClosable: false,
        open: state.open,
        title: '编辑标签',
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            }
        },
        onClose: () => {
            setState({
                open: false,
                uploadIcon: true,
                uploadBanner: true,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
    };

    const uploadProps: UploadProps = {
        accept: 'image/jpg,image/jpeg,image/png',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    useEffect(() => {
        setState({
            sorting: false,
        });
    }, [current?.id]);

    return (
        <>
            <DragSortTable {...dragTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item label="标签" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        extra="Size: 84x84 (width x height), PNG"
                        label="Icon"
                        name="icon"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (value.length === 0) {
                                        return Promise.reject('Please upload the file');
                                    }

                                    const isDone = value.every((item: any) => item.url);

                                    return isDone ? Promise.resolve() : Promise.reject('Uploading');
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadIcon: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadProps} accept="image/png">
                            {state.uploadIcon && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        extra="Size: 750*272 (width x height), JPG/JPEG/PNG, within 3 MB"
                        label="Header image"
                        name="image"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (value.length === 0) {
                                        return Promise.reject('Please upload the file');
                                    }

                                    const isDone = value.every((item: any) => item.url);

                                    return isDone ? Promise.resolve() : Promise.reject('Uploading');
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadBanner: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadProps}>
                            {state.uploadBanner && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default ComponentBlockTags;
