import { MenuDataItem } from '@ant-design/pro-components';
import { RouteObject } from 'react-router-dom';

// 遍历寻找路由
export function findRoute(key: string, routes: (MenuDataItem & RouteObject)[]): (MenuDataItem & RouteObject) | null {
    const route = routes.find((r) => r.path === key);
    if (route) {
        return route;
    }

    for (const r of routes) {
        const childRoute = findRoute(key, r.children || []);
        if (childRoute) {
            return childRoute;
        }
    }

    return null;
}

export const findRouteByAccess = (
    routes: (MenuDataItem & RouteObject)[],
    access: string,
): (MenuDataItem & RouteObject) | undefined => {
    for (const route of routes) {
        if (route.access === access) {
            return route;
        }
        if (route.children) {
            const found = findRouteByAccess(route.children, access);
            if (found) return found;
        }
    }
    return undefined;
};
