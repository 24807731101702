export default {
    mknft: {
        e: {
            cNotFound: '合集不存在',
            cAlreadyExists: '合集已存在',
            nAlreadyExists: 'NFT已存在',
            nNotFound: 'NFT不存在',
            nftHasDisabeld: '当前NFT已被禁用',
            nftNotToC: '添加的NFT不属于当前集合',
        },
        i: {
            empty: '请点击左侧各类型进行NFT配置',
        },
        t: {
            c: {
                collectionId: '合集ID',
                collectionName: '合集名称',
                name: '名称',
                nftName: 'NFT名称',
                img: 'Image',
                count: '数量',
                lowPrice: '地板价',
                subType: '子类型',
                eAddress: '合约地址',
                chain: 'Chain',
                nftId: 'NFT ID',
                bestPrice: '最优报价',
                sellCount: '在售NFT数量',
                nftCount: 'NFT 数量',
                nftRate: '在售NFT占比',
                volume_24h: '24h交易量',
                amount_24h: '24h交易额',
                total_trading_volume: '累计交易量',
                total_trading_amount: '累计交易额',
            },
        },
        makp: {},
        magt: {},
        col: {},
    },
};
