import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Space, Switch } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { cDomainApi, ErrorCodeMap, pDomainApi, Status } from '@/services/domain';

// form submit
const formSubmit = async (propsData: DomainResult | null, formData: CreateDomainData) => {
    formData.status = formData.status ? Status.Enabled : Status.Disabled;
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        return await pDomainApi({
            ids: [propsData.id],
            status: formData.status,
            updated_at: propsData.updated_at,
        });
    }
    return await cDomainApi(formData);
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import { CreateDomainData, DomainResult } from '@/services/domain';

type Props = {
    open: boolean;
    entity: DomainResult | null;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, ErrorCodeMap, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch (_e) {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    domain: entity.domain,
                    status: entity.status === Status.Enabled,
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<CreateDomainData> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="domain"
                    label={intl.formatMessage({ id: 'su.t.c.domain' })}
                    rules={[{ required: true, type: 'url' }]}
                >
                    <Input showCount maxLength={255} disabled={!!entity} />
                </Form.Item>

                <Form.Item
                    initialValue={true}
                    name="status"
                    label={intl.formatMessage({ id: 't.c.status' })}
                    rules={[{ required: true }]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
