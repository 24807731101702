import { useEffect, useRef } from 'react';
import { App, Button, Flex, Form, Input, DatePicker, Popconfirm, Select, Typography } from 'antd';
import { isEmpty, omitBy } from 'lodash';
import { useRequest, useSetState } from 'ahooks';
import Permission, { useAccess } from '@/components/permission';
import UserNameModalForm from './components/username-modal-form';
import UserNameUploadModalForm from './components/username-upload-modal-form';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { deleteUserNameByIdApi, userNameListApi } from '@/services/user-name';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { FormProps, PaginationProps } from 'antd';
import type { UserNameListParams, UserNameListResult } from '@/services/user-name';
import { ProCard, ProTable, type ProTableProps } from '@ant-design/pro-components';
import type { ModalFormState } from './components/username-modal-form';
import type { ModalFormUploadState } from './components/username-upload-modal-form';

type State = {
    dataSource: UserNameListResult[];
    selectedRowKeys: React.Key[];
    modalFormState: ModalFormState;
    modalFormStateUpload: ModalFormUploadState;
};

const PageAccountManagement: React.FC = () => {
    /**
     * Hooks
     */
    const { message, modal } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const checkAccess = useAccess();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        dataSource: [],
        selectedRowKeys: [],
        modalFormState: {
            open: false,
            data: undefined,
        },
        modalFormStateUpload: {
            open: false,
        },
    });

    const hasBulkDeletePermission = checkAccess('Users_Usernames_Bulk-Delete') || checkAccess('super_admin');

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserNameListParams>({});

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshUserNameList,
        run: runUserNameList,
    } = useRequest(userNameListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            date: [],
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.date && values.date.length > 0) {
                values.updated_end = values.date[1].endOf('d').format();
                values.updated_start = values.date[0].startOf('d').format();
                delete values.date;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                name: undefined,
                type: undefined,
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<UserNameListResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="common.userName" />,
                render: (_, record) => {
                    return <span>@{record.name}</span>;
                },
            },
            {
                dataIndex: 'type',
                width: 120,
                title: <FormattedMessage id="common.type" />,
                valueEnum: {
                    company: <FormattedMessage id="p.us.type.company" />,
                    organisation: <FormattedMessage id="p.us.type.organisation" />,
                    country: <FormattedMessage id="p.us.type.country" />,
                    celebrity: <FormattedMessage id="p.us.type.celebrity" />,
                    fancy_no: <FormattedMessage id="p.us.type.fancyNo" />,
                },
            },
            {
                dataIndex: 'operated_by',
                width: 120,
                title: <FormattedMessage id="common.table.columns.operatedBy" />,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                title: <FormattedMessage id="common.table.columns.operatedAt" />,
                valueType: 'dateTime',
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, record) => [
                    <Permission permission="Users_Usernames_Delete">
                        <Popconfirm
                            title={<FormattedMessage id="c.areyousure" />}
                            onConfirm={() => {
                                if (!record.id) {
                                    return;
                                }
                                deleteUserNameByIdApi([record.id]).then(() => {
                                    message.success(intl.formatMessage({ id: 'common.deleteOk' }));
                                    refreshUserNameList();
                                    setState({
                                        selectedRowKeys: [],
                                    });
                                });
                            }}
                        >
                            <Typography.Link type="danger">
                                <FormattedMessage id="common.delete" />
                            </Typography.Link>
                        </Popconfirm>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshUserNameList,
        },
        rowKey: 'id',
        toolBarRender: () => {
            if (state.selectedRowKeys.length > 0) {
                return [
                    <Permission key="bulkDelete" permission="Users_Usernames_Bulk-Delete">
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                modal.confirm({
                                    title: intl.formatMessage({ id: 'c.areyousure' }),
                                    onOk: () => {
                                        deleteUserNameByIdApi(state.selectedRowKeys as string[]).then(() => {
                                            message.success(intl.formatMessage({ id: 'common.deleteOk' }));
                                            refreshUserNameList();
                                            setState({
                                                selectedRowKeys: [],
                                            });
                                        });
                                    },
                                });
                            }}
                        >
                            <FormattedMessage id="p.us.b.batchDelete" />
                        </Button>
                    </Permission>,
                ];
            }
            return [
                <Permission key="create" permission="Users_Usernames_Add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({
                                modalFormState: {
                                    open: true,
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission key="import" permission="Users_Usernames_Import">
                    <Button
                        onClick={() => {
                            setState({
                                modalFormStateUpload: {
                                    open: true,
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.import" />
                    </Button>
                </Permission>,
            ];
        },
        scroll: {
            x: 940,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertRender: false,
        rowSelection: hasBulkDeletePermission
            ? {
                  columnWidth: 50,
                  selectedRowKeys: state.selectedRowKeys,
                  onChange: (selectedRowKeys: React.Key[]) => {
                      setState({
                          selectedRowKeys,
                      });
                  },
              }
            : undefined,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runUserNameList({
            page: pagination.current,
            limit: pagination.pageSize,
            ...payload,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'common.userName' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="type" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.us.type.company' }),
                                        value: 'company',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.us.type.organisation' }),
                                        value: 'organisation',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.us.type.country' }),
                                        value: 'country',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.us.type.celebrity' }),
                                        value: 'celebrity',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.us.type.fancyNo' }),
                                        value: 'fancy_no',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="common.type" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 't.c.dateRange' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <UserNameModalForm
                state={state.modalFormState}
                reload={refreshUserNameList}
                onClose={() => {
                    setState({
                        modalFormState: {
                            open: false,
                            data: undefined,
                        },
                    });
                }}
            />

            <UserNameUploadModalForm
                state={state.modalFormStateUpload}
                reload={refreshUserNameList}
                onClose={() => {
                    setState({
                        modalFormStateUpload: {
                            open: false,
                        },
                    });
                }}
            />
        </>
    );
};

export default PageAccountManagement;
