export default {
    p: {
        exports: {
            t: {
                c: {
                    exportContent: '导出内容',
                },
            },
            status: {
                processing: '导出中',
                failed: '导出失败',
                completed: '已完成',
            },
        },
    },
};
