import { useCallback } from 'react';
import { useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
import { Button, message, Popconfirm, Spin, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import {
    airdropOfficialListApi,
    deleteAirdropOfficialByIdApi,
    AirdropAccApiErrorCodeMsg,
    airdropOfficialDistributeApi,
} from '@/services/official-airdrop-list';

/**
 * Components
 */
import Permission from '@/components/permission';

/**
 * Types
 */
import type { MutableRefObject } from 'react';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { OfficialAirdropItem } from '@/services/official-airdrop-list';
import type { IntlShape } from 'react-intl';

type TableParams = {
    user_id: string;
    pageSize: number;
    current: number;
};

type Props = {
    tableActionRef: MutableRefObject<ActionType | undefined>;
    showModalFormEvent: ReturnType<typeof useCallback>;
};

/**
 * Constants
 */

const processTableListData = (list: OfficialAirdropItem[]) => {
    list.forEach((item) => {
        // item.status = i % 3 === 0 ? '0' : i % 3 === 1 ? '1' : '2';
        item.distributeAt = parseInt(item.distributeAt as string, 10);
        item._seasonRange = [parseInt(item.seasonStartAt), parseInt(item.seasonEndAt)];
        item.createdAt = parseInt(item.createdAt as string, 10);
    });
    return list;
};
// 活动状态
const statusNameMap: Record<string, React.ReactNode> = {
    '0': <FormattedMessage id="oa.p.ae.s.notStarted" />,
    '1': <FormattedMessage id="oa.p.ae.s.inProgress" />,
    '2': <FormattedMessage id="oa.p.ae.s.completed" />,
    '3': <FormattedMessage id="oa.p.ae.s.completed" />,
    '4': <FormattedMessage id="oa.p.ae.s.completed" />,
    '5': <FormattedMessage id="oa.p.ae.s.completed" />,
};
// 发放状态
const distributeStatusNameMap: Record<string, React.ReactNode> = {
    '3': <FormattedMessage id="oa.p.ae.s.waitDistribute" />,
    '4': <FormattedMessage id="oa.p.ae.s.distributing" />,
    '5': <FormattedMessage id="oa.p.ae.s.distributed" />,
};

const ComponentTable: React.FC<Props> = ({ showModalFormEvent, tableActionRef }) => {
    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<OfficialAirdropItem, any> = {
        actionRef: tableActionRef,
        bordered: true,
        columns: [
            {
                dataIndex: '_index',
                title: 'No.',
                valueType: 'index',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
            },
            {
                dataIndex: 'season',
                title: <FormattedMessage id="oa.p.ae.t.c.activityCycles" />,
                width: 120,
                hideInSearch: true,
            },
            {
                dataIndex: '_seasonRange',
                title: <FormattedMessage id="oa.p.ae.t.c.registrationDates" />,
                hideInSearch: true,
                valueType: 'dateTimeRange',
                // render: (_, entity) => {
                //     return [entity.seasonStartAt, entity.seasonEndAt];
                // },
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="oa.p.ae.t.c.activityStatus" />,
                hideInSearch: true,
                width: 120,
                render: (_, entity) => statusNameMap[entity.status] || '-',
            },
            {
                dataIndex: 'creator',
                width: 200,
                title: <FormattedMessage id="t.c.createdBy" />,
                hideInSearch: true,
            },
            {
                dataIndex: 'createdAt',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.createdAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_status',
                title: <FormattedMessage id="oa.p.ae.t.c.airdropStatus" />,
                hideInSearch: true,
                width: 160,
                render: (_, entity) => distributeStatusNameMap[entity.status] || '-',
            },
            {
                dataIndex: 'distributor',
                width: 200,
                title: <FormattedMessage id="oa.p.ae.t.c.airdropBy" />,
                hideInSearch: true,
            },
            {
                dataIndex: 'distributeAt',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="oa.p.ae.t.c.airdropAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="t.c.action" />,
                hideInSearch: true,
                render: (_, entity) => {
                    switch (entity.status) {
                        // 未开始 删除按钮
                        case '0': {
                            return (
                                <Permission permission="Airdrop_Airdrop-Seasons_Delete">
                                    <span style={{ display: 'inline-block' }}>
                                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                                            <Popconfirm
                                                key="delete"
                                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                                onConfirm={() => {
                                                    setCellLoading({ [entity.id]: true });
                                                    deleteAirdropOfficialByIdApi(entity.id)
                                                        .then((res) => {
                                                            if (get(res, 'data.code') === 0) {
                                                                message.success(
                                                                    intl.formatMessage({ id: 'c.operationCompleted' }),
                                                                );
                                                                tableActionRef?.current?.reload();
                                                            } else {
                                                                const errorCodeKey =
                                                                    AirdropAccApiErrorCodeMsg[
                                                                        get(res, 'data.code') as any
                                                                    ];
                                                                if (errorCodeKey) {
                                                                    message.error(
                                                                        intl.formatMessage({ id: errorCodeKey }),
                                                                    );
                                                                } else {
                                                                    message.error(get(res, 'data.msg', 'Error'));
                                                                }
                                                            }
                                                            setCellLoading({ [entity.id]: false });
                                                        })
                                                        .catch(() => {
                                                            setCellLoading({ [entity.id]: false });
                                                        });
                                                }}
                                            >
                                                <Typography.Link type="danger">
                                                    <FormattedMessage id="b.delete" />
                                                </Typography.Link>
                                            </Popconfirm>
                                        </Spin>
                                    </span>
                                </Permission>
                            );
                        }
                        // 进行中 编辑按钮
                        case '1': {
                            return (
                                <Permission permission="Airdrop_Airdrop-Seasons_Edit">
                                    <a onClick={() => showModalFormEvent(entity)}>
                                        <FormattedMessage id="b.edit" />
                                    </a>
                                </Permission>
                            );
                        }
                        // 待发放 状态，有 发放按钮
                        case '3': {
                            return (
                                <Permission permission="Airdrop_Airdrop-Seasons_Distribute">
                                    <span style={{ display: 'inline-block' }}>
                                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                                            <Popconfirm
                                                key="delete"
                                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                                onConfirm={() => {
                                                    setCellLoading({ [entity.id]: true });
                                                    airdropOfficialDistributeApi(entity.id)
                                                        .then((res) => {
                                                            if (get(res, 'data.code') === 0) {
                                                                message.success(
                                                                    intl.formatMessage({ id: 'c.operationCompleted' }),
                                                                );
                                                                tableActionRef?.current?.reload();
                                                            } else {
                                                                const errorCodeKey =
                                                                    AirdropAccApiErrorCodeMsg[
                                                                        get(res, 'data.code') as any
                                                                    ];
                                                                if (errorCodeKey) {
                                                                    message.error(
                                                                        intl.formatMessage({ id: errorCodeKey }),
                                                                    );
                                                                } else {
                                                                    message.error(get(res, 'data.msg', 'Error'));
                                                                }
                                                            }
                                                            setCellLoading({ [entity.id]: false });
                                                        })
                                                        .catch(() => {
                                                            setCellLoading({ [entity.id]: false });
                                                        });
                                                }}
                                            >
                                                <Typography.Link>
                                                    <FormattedMessage id="oa.p.ae.b.send" />
                                                </Typography.Link>
                                            </Popconfirm>
                                        </Spin>
                                    </span>
                                </Permission>
                            );
                        }
                        default: {
                            return '-';
                        }
                    }
                },
            },
        ],
        search: false,
        toolBarRender: () => [
            <Permission permission="Airdrop_Airdrop-Seasons_Create">
                <Button type="primary" onClick={() => showModalFormEvent()}>
                    <FormattedMessage id="b.add" />
                </Button>
            </Permission>,
        ],
        rowKey: 'id',
        scroll: {
            y: 'calc(100vh - 272px)',
            x: 1800,
        },
        request: async (params: TableParams) => {
            const res = await airdropOfficialListApi({
                page: params.current,
                limit: params.pageSize,
            });
            const data: OfficialAirdropItem[] = processTableListData(get(res, 'data.data.list', []));
            return {
                data,
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.total', 0),
            };
        },
    };

    return <ProTable {...proTableProps} />;
};

export default ComponentTable;
