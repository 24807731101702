export default {
    tc: {
        b: {
            sureSort: '确认排序',
            cancelSort: '取消排序',
        },
        t: {
            c: {
                sort: '排序',
                taskTypeId: '任务种类ID',
                subTaskId: '子任务ID',
                taskTitle: '任务标题(EN)',
                taskDesc: '任务描述(EN)',
                paramsN: '条件参数n',
                awardValue: '奖励积分',
                action: '跳转（{value}）',
                type: '类型',
                status: '启用状态',
            },
        },
        type: {
            daily: '每日',
            period: '每期',
            once: '单次',
        },
        f: {
            language: '多语言',
            title: '标题',
            desc: '描述',
            group: '分组',
        },
        i: {
            incentivesInProgress: '当前存在激励活动正在进行中',
        },
    },
};
