import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DatePicker, Drawer, Form, InputNumber, message, Space } from 'antd';

/**
 * APIs
 */
import { createAirdropOfficialApi, updateAirdropOfficialApi } from '@/services/official-airdrop-list';

// form submit
const formSubmit = async (propsData: OfficialAirdropItem | undefined, formData: FeFormParams) => {
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        return await updateAirdropOfficialApi(propsData.id, formData.season);
    }
    return await createAirdropOfficialApi({
        season: formData.season,
        seasonStartAt: formData.range[0] as unknown as string,
        seasonEndAt: formData.range[1] as unknown as string,
    });
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { OfficialAirdropItem } from '@/services/official-airdrop-list';

export type FeFormParams = {
    season: 'string';
    range: [number, number];
};

export type DrawerFormState = {
    open: boolean;
    data: OfficialAirdropItem | undefined;
};

type Props = {
    state: DrawerFormState;
    closeModelForm: (reload?: boolean) => void;
};

/**
 * Constants
 */
import { AirdropAccApiErrorCodeMsg } from '@/services/official-airdrop-list';
import dayjs from 'dayjs';

const ComponentModalFormCustom: React.FC<Props> = ({ state, closeModelForm }) => {
    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData: FeFormParams) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(state.data, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            const errorCodeKey =
                                                AirdropAccApiErrorCodeMsg[get(res, 'data.err.code') as any];
                                            if (errorCodeKey) {
                                                message.error(intl.formatMessage({ id: errorCodeKey }));
                                            } else {
                                                const errorMsg = get(res, 'data.err.error', undefined);
                                                message.error(get(res, 'data.err.msg', errorMsg || 'Error'));
                                            }
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id={state.data ? 'b.edit' : 'b.add'} />,
        // width: 420,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (state.data) {
                form.setFieldsValue({
                    season: state.data.season,
                    range: [dayjs(parseInt(state.data.seasonStartAt, 10)), dayjs(parseInt(state.data.seasonEndAt, 10))],
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="season"
                    label={intl.formatMessage({ id: 'oa.p.ae.t.c.activityCycles' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber placeholder="" min={1} max={1000} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id: 'oa.p.ae.t.c.registrationDates' })}
                    name="range"
                    rules={[{ required: true }]}
                >
                    <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        showTime={{
                            hideDisabledOptions: true,
                        }}
                        disabled={!!state.data}
                        allowEmpty
                        placeholder={['', '']}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
