import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Card, Flex, message } from 'antd';
import { useRequest } from 'ahooks';
import UserStatus from './components/user-status';
import UserDetail from './components/user-detail';
import UserStatusModalForm from './components/user-status-modal-form';
import { useSearchParams } from 'react-router-dom';

/**
 * Api
 */
import { userDetailApi, userTnsApi } from '@/services/user';

/**
 * Types
 */
import type { User, UserTns } from '@/services/user';
import Permission, { useAccess } from '@/components/permission';
import { FormattedMessage } from 'react-intl';

const Users: React.FC = () => {
    /**
     * States
     */
    const [user, setUser] = useState<User | null>(null);
    const [userTns, setUserTns] = useState<UserTns | null>(null);
    const [type, setType] = useState<'mute' | 'ban' | null>(null);
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');

    const checkAccess = useAccess();

    const isMute = checkAccess('Users_Users_Details_Mute');
    const isBan = checkAccess('Users_Users_Details_Disable');
    const isUnmute = checkAccess('Users_Users_Details_Unmute');
    const isEnable = checkAccess('Users_Users_Details_Enable');

    const hasMutePermission = isMute || isUnmute;
    const hasBanPermission = isBan || isEnable;

    /**
     * Effects
     */
    const { run: fetchUserDetail, refresh: refreshUserDetail } = useRequest(userDetailApi, {
        manual: true,
        onSuccess: (res) => {
            if (get(res, 'data.code') === 0) {
                setUser(res?.data?.data);
            } else {
                message.error(res?.data?.msg);
                setUser(null);
            }
        },
    });

    const { run: fetchUserTns, refresh: refreshUserTns } = useRequest(userTnsApi, {
        manual: true,
        onSuccess: (res) => {
            if (get(res, 'data.code') === 0) {
                setUserTns(
                    res?.data?.data || {
                        mute_status: false,
                        ban_status: false,
                    },
                );
            } else {
                message.error(res?.data?.msg);
                setUser(null);
            }
        },
    });

    useEffect(() => {
        if (uid) {
            fetchUserDetail(uid);
            fetchUserTns(uid);
        }
    }, [uid]);

    return (
        <>
            {(hasMutePermission || hasBanPermission) && (
                <Card style={{ marginBottom: 12 }}>
                    <Flex gap={12}>
                        {!userTns?.mute_status ? (
                            <Permission permission="Users_Users_Details_Mute">
                                <Button
                                    onClick={() => {
                                        setType('mute');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.mute" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Unmute">
                                <Button
                                    onClick={() => {
                                        setType('mute');
                                        setOpen(true);
                                    }}
                                    color="danger"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.unmute" />
                                </Button>
                            </Permission>
                        )}

                        {!userTns?.ban_status ? (
                            <Permission permission="Users_Users_Details_Disable">
                                <Button
                                    onClick={() => {
                                        setType('ban');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.disable" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Enable">
                                <Button
                                    onClick={() => {
                                        setType('ban');
                                        setOpen(true);
                                    }}
                                    color="danger"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.enable" />
                                </Button>
                            </Permission>
                        )}
                    </Flex>
                </Card>
            )}
            <UserDetail user={user} />
            <UserStatus user={user} userTns={userTns} />
            <UserStatusModalForm
                type={type}
                state={{ open, user, userTns }}
                onClose={(refresh?: boolean) => {
                    setType(null);
                    setOpen(false);
                    if (refresh) {
                        refreshUserDetail();
                        refreshUserTns();
                    }
                }}
            />
        </>
    );
};

export default Users;
