export default {
    oa: {
        p: {
            /**
             * Airdrop Events
             *
             * 官方空投活动列表
             */
            ae: {
                b: {
                    send: '发放',
                },
                // info
                i: {
                    activityCyclesPlaceholder: '请填写1000以内的正整数',
                    endTimePlaceholder: '结束时间',
                    seasonNotStart: '空投季尚未开始', // 空投季尚未开始 - 102100
                    seasonNotStartButAc: '空投季尚未开始已激活', // 空投季尚未开始已激活 - 102102
                    seasonStatusError: 'Airdrop season status is not pending', // Airdrop season status is not pending 102112
                },
                t: {
                    c: {
                        activityId: '活动ID',
                        activityCycles: '活动期数',
                        registrationDates: '报名起止时间',
                        activityStatus: '活动状态',
                        airdropStatus: '发放状态',
                        airdropBy: '发放人',
                        airdropAt: '发放时间',
                    },
                },
                // status
                s: {
                    notStarted: '未开始',
                    inProgress: '进行中',
                    completed: '已结束',
                    waitDistribute: '待发放',
                    distributing: '发放中',
                    distributed: '发放成功',
                },
            },
            /**
             * Official Airdrop Configurations
             *
             * 官方空投参数配置
             */
            oac: {
                mainConfigTitle: '空投总量配置',
                userConfigTitle: '用户瓜分空投量配置',
                airdropAmount: '空投总量 P',
                airdropAmountMax: '空投总量最大 M',
                efficient: '系数 {value}',
                userConfigAmount: '用户瓜分空投量 p',
                userConfigAmountVla: 'p = 个人分数 s / 所有用户总分数 S * 空投总量 P',
                postScore: '发帖量得分',
                postScoreVla: '发帖量',
                newUserCommentNum: '新用户评论人数得分',
                oldUserCommentNum: '评论人数',
                oldUserCommentNumVla: '老用户评论人数得分',
                newUserVoteNumVla: '新用户投票人数得分',
                oldUserVoteNumVla: '老用户投票人数得分',
                voteUserNum: '投票人数',
                voteNumVla: '投票量得分',
                voteNum: '投票量',
                commentNum: '评论量',
                commentNumScore: '评论量得分',
                commentLikeScore: '评论点赞得分',
                likeNum: '点赞量',
                replyNum: '回复量',
                replyNumScore: '评论回复得分',
                inviteScore: '邀请好友得分',
                inviteNum: '邀请人数',
                // error info
                e: {
                    integerError: '正整数，1亿以内',
                    validPositiveNumberError: '正数，最多两位小数，1亿以内',
                    decimalsNumError: '非负数，最多两位小数，1亿以内',
                },
            },
        },
    },
};
