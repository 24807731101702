import Permission from '@/components/permission';
import { HolderOutlined } from '@ant-design/icons';
import { ActionType, DragSortTable, DragTableProps, ProCard } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Col, Popconfirm, Row, Segmented, Spin, Tag, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * Components
 */
import ComponentRightTable from './components/right-table';
import ComponentDrawerFormType from './components/drawer-form-type';
import {
    CreatorAcademyResult,
    dCreatorAcademyApi,
    gCreatorAcademyApi,
    sCreatorAcademyApi,
} from '@/services/creator-academy';

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP'];

/**
 * Types
 */
type State = {
    language: LanguageType;
    dataSource: CreatorAcademyResult[];
    sorting: boolean;
    openDrawer: boolean;
    entity: any;
    selectdEntity: any;
};
type LanguageType = (typeof Language)[number];

const PageCreationAcademy: React.FC = () => {
    /**
     * Hooks
     */
    const intl = useIntl();

    const { message } = App.useApp();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        language: 'EN',
        dataSource: [],
        sorting: false,
        openDrawer: false,
        entity: null,
        selectdEntity: null,
    });

    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const {
        loading: loadingCreatorAcademyApi,
        refresh: refreshCreatorAcademyApi,
        run: runCreatorAcademyApi,
    } = useRequest(gCreatorAcademyApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            const dataSource = data || [];
            // 当选中时，编辑选中的数据，同步更新选中的数据
            let selectdEntity = state.selectdEntity;
            if (selectdEntity) {
                selectdEntity = dataSource.find((item) => item.id === state.selectdEntity.id);
            }

            setState({
                dataSource,
                sorting: false,
                selectdEntity,
            });
        },
    });

    const { run: runCreatorPostsSortApi, loading: loadingCreatorPostsSortApi } = useRequest(sCreatorAcademyApi, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            refreshCreatorAcademyApi();
        },
    });

    /**
     * ChildrenProps
     */
    const dragSortTableProps: DragTableProps<CreatorAcademyResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                ellipsis: true,
                title: <FormattedMessage id="cad.t.c.name" />,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => <Tag color={status ? 'green' : 'orange'}>{status ? 'Enabled' : 'Disabled'}</Tag>,
                width: 80,
            },
            {
                dataIndex: '_',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                render: (_, entity) => [
                    <Permission permission="Creator-Center_Creator-Hub" key="detail">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    <Permission permission="Creator-Center_Creator-Hub" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    dCreatorAcademyApi(entity.id)
                                        .then(({ data: { code, msg } }) => {
                                            setCellLoading({ [entity.id]: false });
                                            if (code !== 0) {
                                                return message.error(msg || 'Error');
                                            }
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            // 删除的是当前选中的项，则重置选中项
                                            if (state.selectdEntity && state.selectdEntity.id === entity.id) {
                                                setState({ selectdEntity: undefined });
                                            }
                                            refreshCreatorAcademyApi();
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                            message.error('Error');
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
                width: 100,
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading: loadingCreatorAcademyApi,
        options: {
            reload: refreshCreatorAcademyApi,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 240px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
        headerTitle: <FormattedMessage id="cad.t.headerTitle" />,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingCreatorPostsSortApi}
                        onClick={() => {
                            runCreatorPostsSortApi({ ids: state.dataSource.map((item) => item.id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingCreatorPostsSortApi}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshCreatorAcademyApi();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Permission permission="Creator-Center_Creator-Hub" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Creator-Center_Creator-Hub" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: (_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
            setState({
                dataSource: newDataSource,
            });
        },
        onRow: (data) => {
            return {
                onClick: (e) => {
                    const clickTagName = (e.target as HTMLElement).tagName;
                    if (
                        clickTagName === 'A' ||
                        clickTagName === 'BUTTON' ||
                        (e.target as HTMLElement).parentElement?.tagName === 'BUTTON'
                    ) {
                        return true;
                    }
                    // if (e.target)
                    setState({ selectdEntity: data });
                },
            };
        },
        rowClassName: (record) => (record.id === state.selectdEntity?.id ? 'rowClassName' : ''),
    };

    /**
     * Effects
     */
    useEffect(() => {
        runCreatorAcademyApi({
            language: state.language,
        });
    }, [state.language]);

    return (
        <>
            <Row gutter={16} wrap={false}>
                <Col flex="480px">
                    <ProCard className="mb-16" ref={searchRef}>
                        <Segmented<LanguageType>
                            options={Language}
                            value={state.language}
                            onChange={(value) => {
                                setState({ language: value, sorting: false, selectdEntity: null });
                            }}
                        />
                    </ProCard>
                    <DragSortTable {...dragSortTableProps} tableStyle={{ minHeight: 'calc(100vh - 264px)' }} />
                </Col>

                <Col flex="auto">
                    <ProCard style={{ padding: 0, minHeight: 'calc(100vh - 104px)' }} bodyStyle={{ padding: 0 }}>
                        <ComponentRightTable language={state.language} subjectEntity={state.selectdEntity} />
                    </ProCard>
                </Col>
            </Row>
            <ComponentDrawerFormType
                open={state.openDrawer}
                entity={state.entity}
                language={state.language}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false, entity: null });
                    reload && refreshCreatorAcademyApi();
                }}
            />
        </>
    );
};

export default PageCreationAcademy;
