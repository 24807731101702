import { useRef } from 'react';
import { useSetState } from 'ahooks';
import { App, Button, Popconfirm, Spin, Tag, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentDrawerFormCustom from './components/drawer-form-custom';

/**
 * APIs
 */
import { dDomainApi, gDomainApi, Status } from '@/services/domain';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { IntlShape } from 'react-intl';
import type { DomainResult } from '@/services/domain';

/**
 * State
 */
type State = {
    entity: DomainResult | null;
    openDrawer: boolean;
};

const PageClientDomain: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();

    const intl: IntlShape = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        entity: null,
        openDrawer: false,
    });
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<DomainResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'domain',
                ellipsis: true,
                title: <FormattedMessage id="su.t.c.domain" />,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => (
                    <Tag color={status === Status.Enabled ? 'green' : 'orange'}>
                        {status === Status.Enabled ? 'Enabled' : 'Disabled'}
                    </Tag>
                ),
                width: 80,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Client-Config_Domain" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,

                    <Permission permission="Client-Config_Domain" key="delete">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    dDomainApi(entity.id)
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                tableRef.current?.reload();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        search: false,
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 272px)`,
            x: 930,
        },
        tableAlertRender: false,
        toolBarRender: () => [
            <Button
                type="primary"
                onClick={() => {
                    setState({ openDrawer: true, entity: null });
                }}
                key="sureSort"
            >
                <FormattedMessage id="b.add" />
            </Button>,
        ],
        request: async (params) => {
            const res = await gDomainApi({
                page: params.current,
                limit: params.pageSize,
            });
            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return (
        <>
            <ProTable {...proTableProps} />

            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && tableRef.current?.reload();
                }}
            />
        </>
    );
};

export default PageClientDomain;
