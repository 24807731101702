import { useBoolean, useLocalStorageState } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, Form, message, Space } from 'antd';
import { ProForm, ProFormDigitRange, ProFormText } from '@ant-design/pro-components';

/**
 * APIs
 */
import { createAirAccApi, updateAirAccApi, AirdropAccApiErrorCodeMsg } from '@/services/airdrop-account';
import { checkUserId } from '@/services/common';

// form submit
const formSubmit = async (propsData: AirdropAccItem | undefined | null, formData: FeFormParams, operator: string) => {
    const submitFormData = {
        airdrop_min: formData.range[0],
        airdrop_max: formData.range[1],
        operator: operator,
    };
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        return await updateAirAccApi(
            propsData.id,
            Object.assign(submitFormData, {
                updated_at: propsData.updated_at,
            }),
        );
    }
    return await createAirAccApi({
        user_id: formData.user_id,
        ...submitFormData,
    });
};

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { DrawerProps } from 'antd';
import type { ProFormProps } from '@ant-design/pro-components';
import type { AirdropAccItem } from '@/services/airdrop-account';
import type { User } from '@repo/typings/user';

export type FeFormParams = {
    user_id: 'string';
    range: [number, number];
};

type Props = {
    open: boolean;
    entity: AirdropAccItem | undefined | null;
    closeModelForm: (reload?: boolean) => void;
};

/**
 * Constants
 */
const airdropCountRangeMin = 1;

const airdropCountRangeMax = 1000000;

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();
    const [user] = useLocalStorageState<User>('user', {
        defaultValue: {
            id: '',
            status: 0,
            name: '',
        },
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            setSubmittingTrue();
                            form.validateFields()
                                .then(async (formData: FeFormParams) => {
                                    try {
                                        const res = await formSubmit(entity, formData, user!.name);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            const errorCodeKey = AirdropAccApiErrorCodeMsg[get(res, 'data.code')];
                                            if (errorCodeKey) {
                                                message.error(intl.formatMessage({ id: errorCodeKey }));
                                            } else {
                                                message.error(get(res, 'data.msg', 'Error'));
                                            }
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {
                                    setSubmittingFlase();
                                });
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
                setSubmittingFlase();
            } else if (entity) {
                form.setFieldsValue({
                    user_id: entity.user_id,
                    range: [entity.airdrop_min, entity.airdrop_max],
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: ProFormProps = {
        form: form,
        layout: 'vertical',
        submitter: false,
        validateTrigger: 'onBlur',
        autoFocusFirstInput: false,
    };

    return (
        <Drawer {...drawerProps}>
            <ProForm {...formProps}>
                <ProFormText
                    name="user_id"
                    label="UID"
                    placeholder=""
                    disabled={!!entity}
                    rules={[
                        {
                            required: true,
                            validator: async (_rule, value) => {
                                if (!value) {
                                    return Promise.reject(new Error(intl.formatMessage({ id: 'su.f.pleaseInputUID' })));
                                }
                                if (!value.match(/^\d+$/)) {
                                    return Promise.reject(new Error(intl.formatMessage({ id: 'su.f.uidFormatError' })));
                                }
                                // 通过单独api 检测，输入的uid是否存在
                                return new Promise((resolve, reject) => {
                                    checkUserId(value)
                                        .then((res) => {
                                            if (get(res, 'data.code') === 0 && get(res, 'data.data', null)) {
                                                resolve(null);
                                            } else {
                                                reject(new Error(intl.formatMessage({ id: 'su.f.AccountNotExist' })));
                                            }
                                        })
                                        .catch(() => {
                                            reject(new Error(intl.formatMessage({ id: 'su.f.AccountNotExist' })));
                                        });
                                });
                            },
                        },
                    ]}
                />

                <ProFormDigitRange
                    label={intl.formatMessage({ id: 'su.t.c.airdropCountRange' })}
                    name="range"
                    separator="-"
                    placeholder={['', '']}
                    fieldProps={{
                        min: airdropCountRangeMin,
                        max: airdropCountRangeMax,
                        precision: 0,
                    }}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            required: true,
                            validator: async (_rule, value) => {
                                if (!value || value.length !== 2) {
                                    return Promise.resolve();
                                }
                                let [min, max] = value;
                                min = Math.round(min);
                                max = Math.round(max);
                                if (
                                    min < airdropCountRangeMin ||
                                    min > airdropCountRangeMax ||
                                    max < airdropCountRangeMin ||
                                    max > airdropCountRangeMax ||
                                    min === max
                                ) {
                                    console.log('error');
                                    return Promise.reject(
                                        new Error(intl.formatMessage({ id: 'su.f.airdropCountRangeError' })),
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                />
            </ProForm>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
