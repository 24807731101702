import { useCallback, useRef } from 'react';
import { useSetState } from 'ahooks';

/**
 * Components
 */
import ComponentModalFormCustom from './components/modal-form-custom';
import ComponentTable from './components/table';

/**
 * Types
 */
import type { ActionType } from '@ant-design/pro-components';
import type { ModalFormState } from './components/modal-form-custom';
import type { AccountsResponse } from '@/services/account';

const PageAccountManagement: React.FC = () => {
    /**
     * States
     */
    const tableActionRef = useRef<ActionType>();
    const [modalFormState, setModalFormState] = useSetState<ModalFormState>({
        open: false,
        data: undefined,
    });

    /**
     * Callback
     */
    // entity 不存在时，是新增，存在时，是编辑
    const showModalFormEvent = useCallback((entity: AccountsResponse | undefined) => {
        setModalFormState({
            open: true,
            data: entity,
        });
    }, []);

    const closeModalForm = useCallback((reload: boolean = false) => {
        setModalFormState({
            open: false,
            data: undefined,
        });
        if (reload) {
            tableActionRef.current?.reload();
        }
    }, []);

    return (
        <div>
            <ComponentTable tableActionRef={tableActionRef} showModalFormEvent={showModalFormEvent} />

            <ComponentModalFormCustom state={modalFormState} closeModelForm={closeModalForm} />
        </div>
    );
};

export default PageAccountManagement;
