import axios from './index';

/**
 * APIs
 */
// blocks
export const blocks = () => {
    return axios<ResType<BlocksResult[]>>('/v1/squares', {
        method: 'get',
    });
};

// update block
export const uBlock = (id: string, data: UBlockData) => {
    return axios<ResType>(`/v1/squares/${id}`, {
        data,
        method: 'put',
    });
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

// blocks
export type BlocksResult<T = any> = {
    area: 'Africa' | 'All' | 'Asia' | 'Default' | 'Europe' | 'NorthAmerica' | 'SouthAmerica' | '-';
    data: T | T[];
    id: string;
    index: number;
    max_item_count: number;
    name: string;
    status: boolean;
    type:
        | 'Custom1'
        | 'Custom2'
        | 'Custom3'
        | 'Custom4'
        | 'Custom5'
        | 'Group'
        | 'Hot'
        | 'KOL'
        | 'Nft'
        | 'Promotion'
        | 'Shorts'
        | 'Tag';
    children?: BlocksResult[];
    created_at?: string;
    updated_at?: string;
};

// update block
export type UBlockData = Partial<BlocksResult>;
