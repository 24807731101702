// import { useCallback, useRef } from 'react';
import { useSetState, useRequest } from 'ahooks';
import { App, Col, Row, Tag, Button, Popconfirm, Card, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';

/**
 * APIs
 */
import { deleteRoleByIdApi, roleListApi } from '@/services/roles';

/**
 * Components
 */
import Permission from '@/components/permission';
import RolePermission from './components/role-permission';
import ModalForm from './components/modal-form';

/**
 * Types
 */
import { ProTable, type ProTableProps } from '@ant-design/pro-components';
import type { PaginationProps } from 'antd';
import type { RoleDataResult } from '@/services/roles';
import type { ModalFormState } from './components/modal-form';
import type { IntlShape } from 'react-intl';
import { useEffect } from 'react';

type State = {
    dataSource: RoleDataResult[];
    currentRole: RoleDataResult | undefined;
    modalFormState: ModalFormState;
};

const PageAccountManagement: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * States
     */

    const [state, setState] = useSetState<State>({
        dataSource: [],
        currentRole: undefined,
        modalFormState: {
            open: false,
            data: undefined,
        },
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Callback
     */

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshRoleList,
        run: runRoleList,
    } = useRequest(roleListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<RoleDataResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="common.role" />,
            },
            {
                dataIndex: 'status',
                width: 120,
                title: <FormattedMessage id="common.status" />,
                hideInSearch: true,
                render: (dom) => (dom === 1 ? <Tag color="green">Enabled</Tag> : <Tag color="red">Disabled</Tag>),
            },
            {
                dataIndex: 'operator',
                width: 120,
                title: <FormattedMessage id="t.c.createdBy" />,
            },
            {
                dataIndex: 'created_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="common.createTime" />,
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => {
                    const { id } = entity;
                    if (id === '11111111-1111-1111-1111-111111111111')
                        return <div style={{ textAlign: 'center' }}>-</div>;
                    return [
                        <Permission permission="System-Management_Roles_Edit">
                            <a
                                onClick={() => {
                                    setState({
                                        modalFormState: {
                                            open: true,
                                            data: entity,
                                        },
                                    });
                                }}
                            >
                                <FormattedMessage id="common.edit" />
                            </a>
                        </Permission>,

                        <Permission permission="System-Management_Roles_Delete">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'p.rm.msg.deleteConfirm' })}
                                onConfirm={async () => {
                                    const res = await deleteRoleByIdApi(entity.id);
                                    if (get(res, 'data.code') !== 0) {
                                        message.error(
                                            get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })),
                                        );
                                        return;
                                    }
                                    message.success(intl.formatMessage({ id: 'common.deleteOk' }));
                                    await refreshRoleList();
                                    setState({
                                        currentRole: undefined,
                                    });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="common.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Permission>,
                    ];
                },
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshRoleList,
        },
        rowKey: 'name',
        toolBarRender: () => [
            <Permission permission="System-Management_Roles_Create">
                <Button
                    type="primary"
                    onClick={() => {
                        setState({
                            modalFormState: {
                                open: true,
                                data: undefined,
                            },
                        });
                    }}
                >
                    <FormattedMessage id="common.add" />
                </Button>
            </Permission>,
        ],
        scroll: {
            y: 'calc(100vh - 330px)',
            x: 890,
        },
        expandable: {
            expandIconColumnIndex: 1,
            expandRowByClick: true,
        },
        onRow: (data) => {
            return {
                onClick: () => {
                    if (data.id === '11111111-1111-1111-1111-111111111111') {
                        setState({
                            currentRole: undefined,
                        });
                        return;
                    }
                    setState({
                        currentRole: data,
                    });
                },
            };
        },
        search: false,
        rowClassName: (record) => (record.id === state?.currentRole?.id ? 'rowClassName' : ''),
    };

    /**
     * Effects
     */
    useEffect(() => {
        runRoleList({
            page: pagination.current,
            limit: pagination.pageSize,
        });
    }, [pagination.current, pagination.pageSize]);

    const closeModalForm = (reload: boolean) => {
        setState({
            modalFormState: {
                open: false,
                data: undefined,
            },
        });
        if (reload) {
            refreshRoleList();
        }
    };

    return (
        <>
            <Row gutter={16} wrap={false}>
                <Col flex="65%">
                    <Card
                        bordered={false}
                        styles={{
                            body: {
                                padding: 0,
                                paddingInline: 0,
                            },
                        }}
                    >
                        <ProTable {...proTableProps} />
                    </Card>
                </Col>
                <Col flex="35%">
                    <RolePermission role={state.currentRole} />
                </Col>
            </Row>
            <ModalForm state={state.modalFormState} onClose={closeModalForm} />
        </>
    );
};

export default PageAccountManagement;
