import { useEffect, useState } from 'react';
import { useBoolean, useRequest } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Form, Button, Input, Row, Col, Select, Switch, Space, App } from 'antd';
import { tokenConfigs } from '@/services/token-config';
import { createTokenPool, tokenPoolByPairApi, updateTokenPool } from '@/services/token-pool';
// import { get } from 'lodash';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { TokenPool } from '@/services/token-pool';
import { FormProps } from 'antd/lib';
import { get } from 'lodash';

export type ModalFormState = {
    open: boolean;
    data?: TokenPool;
};

type TokenConfigsData = {
    label: string;
    value: string;
    token_id: string;
    url: string;
    sort: number;
};

type Props = {
    tokenPools?: TokenPool[];
    state: ModalFormState;
    reload?: () => void;
    onClose: () => void;
};

/**
 * Constants
 */

const FEE_TIER_OPTIONS = [100, 500, 3000, 10000];

const ModalFormTokenConfig: React.FC<Props> = ({ tokenPools, state, onClose, reload }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [tokenConfigsData, setTokenConfigsData] = useState<TokenConfigsData[]>([]);
    const [tokenPoolByPairData, setTokenPoolByPairData] = useState<TokenPool[]>([]);
    const [pair, setPair] = useState<{ token0: string; token1: string }>({ token0: '', token1: '' });
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    const isEdit = !!state.data?.id;

    useRequest(tokenConfigs, {
        onSuccess: ({ data }) => {
            if (data.code === 0) {
                setTokenConfigsData(
                    // status=0是不可用，type=4是黑名单
                    data.data
                        .filter((item) => item.status !== 0 && item.type !== 4)
                        .map((item) => ({
                            label: item.token.label,
                            value: item.token.id,
                            token_id: item.token.token_id,
                            url: item.token.url,
                            sort: item.sort,
                        })),
                );
            }
        },
    });

    const { run: runTokenPoolByPair, loading: tokenPoolByPairLoading } = useRequest(tokenPoolByPairApi, {
        onSuccess: ({ data }) => {
            if (data.code === 0) {
                setTokenPoolByPairData(data.data);
            }
        },
    });

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.resetFields();
            setSubmittingFlase();
        } else if (state.data) {
            form.setFieldsValue({
                ...state.data,
                defaultFee: state.data.default_fee === '1',
            });
        }
    }, [state]);

    useEffect(() => {
        if (!state.open) {
            return;
        }
        if (pair.token0 && pair.token1) {
            runTokenPoolByPair({ token0: pair.token0, token1: pair.token1 });
        } else {
            setTokenPoolByPairData([]);
        }
    }, [pair.token0, pair.token1, state.open]);

    const handleFinish = async (formData: any) => {
        const { token0, token1, fee, price, defaultFee } = formData;
        const token0Data = tokenConfigsData.find((item) => item.value === token0.value);
        const token1Data = tokenConfigsData.find((item) => item.value === token1.value);

        if (Number(token0Data?.sort) >= Number(token1Data?.sort)) {
            message.error(intl.formatMessage({ id: 'p.tp.msg.token0GreaterThanToken1' }));
            return;
        }

        const feeOptions = filterFeeOptions(token0.value, token1.value);
        const isFirstTime = feeOptions.filter((item) => item.disabled).length === 0;
        if (isFirstTime && !defaultFee) {
            message.error(intl.formatMessage({ id: 'p.tp.msg.defaultFee' }));
            return;
        }

        setSubmittingTrue();
        if (state.data && state.data.id) {
            if (!defaultFee) {
                onClose();
                reload?.();
                return;
            }
            const res = await updateTokenPool({
                tokenA: token0,
                tokenB: token1,
                fee: fee ? Number(fee) : 0,
            });
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose();
                reload?.();
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        } else {
            const tokenA = tokenConfigsData.find((item) => item.value === token0.value);
            const tokenB = tokenConfigsData.find((item) => item.value === token1.value);
            const res = await createTokenPool({
                tokenA: tokenA?.value || '',
                tokenA_id: tokenA?.token_id || '',
                tokenA_sort: tokenA?.sort ? String(tokenA?.sort) : '',
                tokenB: tokenB?.value || '',
                tokenB_id: tokenB?.token_id || '',
                tokenB_sort: tokenB?.sort ? String(tokenB?.sort) : '',
                price,
                default_fee: defaultFee,
                fee: fee ? Number(fee) : 0,
            });
            const code = get(res, 'data.code');
            if (code === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose();
                reload?.();
            } else if (code === 302001) {
                message.error(intl.formatMessage({ id: 'p.tp.msg.302001' }));
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        }
        setSubmittingFlase();
    };

    const filterFeeOptions = (token0: string, token1: string) => {
        const options: { label: string; value: number; disabled: boolean }[] = [];
        FEE_TIER_OPTIONS.map((item) => {
            if (!token0 || !token1) {
                return [];
            }
            const isExist = tokenPools?.find(
                (pool) =>
                    (pool.token0 === token0 && pool.token1 === token1 && Number(pool.fee) === item) ||
                    (pool.token0 === token1 && pool.token1 === token0 && Number(pool.fee) === item),
            );
            options.push({
                label: `${item / 10000}%`,
                value: item,
                disabled: isEdit ? false : !!isExist,
            });
        });
        return options;
    };

    /**
     * ChildrenProps
     */
    const drawerProps = {
        title: state.data?.id ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        onClose: () => {
            form.resetFields();
            onClose();
        },
        maskClosable: false,
        open: state.open,
        destroyOnClose: true,
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={submitting}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: handleFinish,
        initialValues: {
            defaultFee: true,
        },
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            required
                            label={<FormattedMessage id="p.tp.column.token" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="token0"
                                        style={{ marginBottom: 0 }}
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                        ]}
                                    >
                                        <Select
                                            labelInValue={true}
                                            disabled={isEdit}
                                            allowClear={true}
                                            optionFilterProp="label"
                                            options={tokenConfigsData}
                                            onSelect={(value) => {
                                                setPair({ ...pair, token0: value.value });
                                            }}
                                            labelRender={(item) => {
                                                const currentItem = tokenConfigsData.find(
                                                    (i) => i.value === item.value,
                                                );
                                                return (
                                                    <Space>
                                                        <img
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                            src={currentItem?.url}
                                                        />
                                                        {item.label}
                                                    </Space>
                                                );
                                            }}
                                            optionRender={(item) => (
                                                <Space align="center">
                                                    <img
                                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                        src={item.data.url}
                                                    />
                                                    {item.label}
                                                </Space>
                                            )}
                                            showSearch={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="token1"
                                        style={{ marginBottom: 0 }}
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                        ]}
                                    >
                                        <Select
                                            labelInValue={true}
                                            disabled={isEdit}
                                            allowClear={true}
                                            optionFilterProp="label"
                                            options={tokenConfigsData}
                                            showSearch={true}
                                            onSelect={(value) => {
                                                setPair({ ...pair, token1: value.value });
                                            }}
                                            labelRender={(item) => {
                                                const currentItem = tokenConfigsData.find(
                                                    (i) => i.value === item.value,
                                                );
                                                return (
                                                    <Space>
                                                        <img
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                            src={currentItem?.url}
                                                        />
                                                        {item.label}
                                                    </Space>
                                                );
                                            }}
                                            optionRender={(item) => (
                                                <Space>
                                                    <img
                                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                        src={item.data.url}
                                                    />
                                                    {item.label}
                                                </Space>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            required
                            name="fee"
                            label={<FormattedMessage id="p.tp.column.fee" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Select
                                loading={tokenPoolByPairLoading}
                                disabled={isEdit}
                                allowClear={true}
                                optionFilterProp="label"
                                showSearch={true}
                                options={FEE_TIER_OPTIONS.map((item) => {
                                    return {
                                        label: `${item / 10000}%`,
                                        value: item,
                                        disabled: !!tokenPoolByPairData.find(
                                            (pool) =>
                                                pool.fee === item &&
                                                ((pool.token0 === pair.token0 && pool.token1 === pair.token1) ||
                                                    (pool.token0 === pair.token1 && pool.token1 === pair.token0)),
                                        ),
                                    };
                                })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="defaultFee"
                            label={<FormattedMessage id="p.tp.column.defaultFee" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="price"
                            label={<FormattedMessage id="p.tp.column.price" />}
                            tooltip={<FormattedMessage id="p.tp.tips.price" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    validator: (_, value) => {
                                        const regex = /^(0|[1-9]\d{0,10})(\.\d{1,10})?$/;
                                        if (!value || regex.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(intl.formatMessage({ id: 'p.tp.message.price' })),
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input disabled={isEdit} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default ModalFormTokenConfig;
