import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import {
    App,
    Button,
    DatePicker,
    Flex,
    Form,
    Input,
    Modal,
    PaginationProps,
    Popconfirm,
    Select,
    Spin,
    Typography,
} from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentDrawerFormCustom from './components/drawer-form-custom';
import ComponentUploadDrawer from '@/components/upload-drawer';
import ComponentSearchFormSelectOperator from '@/pages/report-management/components/search-form-select-operator';

/**
 * Constant
 */
import { CreatorLanguageOptions, LanguageMap } from '@/const/creator-language';

/**
 * APIs
 */
import { dCreatorTopicApi, gCreatorTopicApi, iCreatorTopicApi } from '@/services/creator-topic';

/**
 * Types
 */
import type { ActionType, FormProps, ProTableProps } from '@ant-design/pro-components';
import type { IntlShape } from 'react-intl';
import type { CreatorTopicParams, CreatorTopicResult, FeCreatorTopicParams } from '@/services/creator-topic';

type SeletRowState = {
    selects: string[];
    loading: boolean;
};

/**
 * State
 */
type State = {
    dataSource: CreatorTopicResult[];
    entity: any;
    openDrawer: boolean;
    openUploadDrawer: boolean;
};

const PageCreationTopic: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
        openUploadDrawer: false,
    });
    const intl: IntlShape = useIntl();
    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        loading: false,
    });
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<CreatorTopicParams>({});

    const {
        loading: loadingCreatorTopicApi,
        refresh: refreshCreatorTopicApi,
        run: runCreatorTopicApi,
    } = useRequest(gCreatorTopicApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * Requests
     */
    const { run: runDeleteCreatorTopicApi } = useRequest(dCreatorTopicApi, {
        manual: true,
        onBefore: () => {
            setSelectState({
                loading: true,
            });
        },
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            } else {
                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            }
            setSelectState({
                selects: [],
                loading: false,
            });
            refreshCreatorTopicApi();
        },
        onError: (e) => {
            message.error(get(e, 'response.data.msg', 'Error'));
        },
        onFinally: () => {
            setSelectState({
                loading: false,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<CreatorTopicResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'content',
                ellipsis: true,
                title: <FormattedMessage id="ctp.t.c.topic" />,
            },
            {
                dataIndex: 'language',
                title: <FormattedMessage id="p.ambassadors.detail.languages" />,
                render: (val) => LanguageMap[val as string] || val,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Creator-Center_Topic" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,

                    <Permission permission="Creator-Center_Topic" key="delete">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    dCreatorTopicApi({
                                        ids: [entity.id],
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                refreshCreatorTopicApi();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        search: false,
        dataSource: state.dataSource,
        loading: loadingCreatorTopicApi,
        options: {
            reload: refreshCreatorTopicApi,
        },
        pagination,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: () => ({
                disabled: selectState.loading,
            }),
            onChange: (selectedRowKeys) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    loading: false,
                });
            },
        },
        scroll: {
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
            x: 1020,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length) {
                return [
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="b.add" />
                    </Button>,
                    <Button
                        onClick={() => {
                            setState({
                                openUploadDrawer: true,
                            });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="b.import" />
                    </Button>,
                ];
            }
            return [
                <Permission permission="Creator-Center_Topic" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={selectState.loading}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    setSelectState({
                                        loading: true,
                                    });
                                    await runDeleteCreatorTopicApi({
                                        ids: selectState.selects,
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
    };

    const formSearchProps: FormProps<FeCreatorTopicParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.operation_date) {
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values.topic) {
                values.topic = trim(values.topic);
            }

            setPagination({
                current: 1,
            });
            setSelectState({
                selects: [],
                loading: false,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runCreatorTopicApi({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="topic" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'ctp.t.c.topic' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="language" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={CreatorLanguageOptions}
                                placeholder={intl.formatMessage({ id: 'p.ambassadors.detail.languages' })}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Creator-Center_Topic" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingCreatorTopicApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshCreatorTopicApi();
                }}
            />

            <ComponentUploadDrawer
                visible={state.openUploadDrawer}
                templateHref="/template/topic.xlsx"
                closeCallback={(reload) => {
                    setState({
                        openUploadDrawer: false,
                    });
                    reload && refreshCreatorTopicApi();
                }}
                uploadApi={(options) => {
                    const formData = new FormData();
                    formData.append('file', options.file as File);
                    iCreatorTopicApi(formData).then(options.onSuccess, options.onError);
                }}
            />
        </>
    );
};

export default PageCreationTopic;
