export default {
    p: {
        plazaConfig: {
            t: {
                headerTitle: 'Plaza Lists',
            },
            tips: {
                unselected: 'Click the module on the left to configure content',
            },
        },
    },
};
