export default {
    p: {
        us: {
            b: {
                batchDelete: '批量删除',
                downloadTemplate: '下载模版',
            },
            ph: {
                name: '请输入名称',
                type: '请选择类型',
                upload: '点击或将文件拖拽到这里上传',
                uploadType: '支持扩展名：.xls，xlsx，csv',
            },
            msg: {
                deleteConfirm: '确认删除该用户名？',
                uploading: '正在上传',
                uploadError: '导入失败，部分数据不符合数据格式要求',
                uploadSuccess: '导入成功，共导入{number}条数据',
            },
            type: {
                fancyNo: '靓号',
                company: '企业',
                celebrity: '名人',
                organisation: '机构',
                country: '国家',
            },
        },
    },
};
