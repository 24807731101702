import axios from './index';

/**
 * APIs
 */
// get airdrop official config
export const getAirdropOfficialConfigApi = () => {
    return axios<ResType<string>>('/v1/airdrop/official/season/config', {
        method: 'get',
    });
};

// update airdrop official config
export const updateAirdropOfficialConfigApi = (data: OfficialAirdropConfigSubmit) => {
    return axios<ResType<OfficialAirdropConfigResult>>(`/v1/airdrop/official/season/config`, {
        data,
        method: 'put',
    });
};

/**
 * Const
 */
export const OfficaialTableStatu: Record<number, string> = {
    0: 'officialDrops.status.notStarted', // 账户已存在
    1: 'officialDrops.status.inProgress', // 账户不存在
    2: 'officialDrops.status.completed', // 最大空头人数要大于最小空投人数
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type OfficialAirgropScoreResult = {
    decs: string;
    key: string;
    params: [string, string];
    formula: string;
};

export type OfficialAirdropAmountResult = {
    lable: string;
    tokenId: string;
    scale: number;
    m: number;
    k1: number;
    k2: number;
};

export type OfficialAirdropConfigResult = {
    scoreConfs: OfficialAirgropScoreResult[];
    airdropAmountLimits: OfficialAirdropAmountResult[];
};

export type OfficialAirdropConfigSubmit = {
    content: string;
};
