import axios from './index';

/**
 * APIs 推荐帖子
 */
// 获取列表
export const gCreatorPostsApi = (params: CreatorPostsParams) => {
    return axios<PageResType<CreatorPostsResult>>('/v1/creator/posts', {
        method: 'get',
        params,
    });
};

// 新增推荐帖子
export const cCreatorPostsApi = (data: AddCreatorPostsParams) => {
    return axios<PageResType<null>>('/v1/creator/posts', {
        method: 'post',
        data,
    });
};

// 删除推荐帖子
export const dCreatorPostsApi = (data: { post_ids: string[] }) => {
    return axios<PageResType<null>>('/v1/creator/posts', {
        method: 'delete',
        data,
    });
};

// 导入推荐帖子
export const iCreatorPostsApi = (data: FormData) => {
    return axios<PageResType<null>>('/v1/creator/posts/import', {
        method: 'post',
        data,
    });
};

/**
 * ErrorCode
 */

export const ErrorCodeMap = {
    160101: 'cpost.e.postExit',
    100101: 'cpost.e.postExit',
    100102: 'cpost.e.postNotFound',
};

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';

export type CreatorPostsResult = {
    against?: string;
    audio: string;
    content?: string;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    for?: string;
    /**
     * ID
     */
    id: string;
    media: string[];
    operator_account: {
        name: string;
    };
    operator_account_id: string;
    post_id: string;
    topic?: string;
    /**
     * 更新时间
     */
    updated_at: Date;
    user_id: string;
    /**
     * 语言
     */
    language: string;
};

export type CreatorPostsParams = {
    /**
     * 正文
     */
    content?: string;
    /**
     * 发布结束时间
     */
    created_end?: string;
    /**
     * 发布开始时间
     */
    created_start?: string;
    /**
     * 辩题
     */
    debate_title?: string;
    limit?: number;
    operation_end?: string;
    /**
     * 操作开始时间
     */
    operation_start?: string;
    /**
     * 操作人后台账号 ID
     */
    operator?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 发布者UID
     */
    user_id?: string;
    /**
     * 语言
     */
    language?: string;
};

export type AddCreatorPostsParams = Pick<CreatorPostsParams, 'post_id'> & {
    /**
     * 更新时间
     */
    updated_at?: string;
};

export type FeCreatorPostsParams = CreatorPostsParams & {
    operation_date?: any[];
    created_date?: any[];
};
