export default {
    cp: {
        t: {
            c: {
                publishAt: '发布时间',
                shortsId: '短视频ID',
            },
        },
        f: {
            type: 'Type',
            deleted: '已删除',
            debateTopic: 'Debate',
            video: 'Video',
            audio: 'Audio',
            nft: 'NFT',
            image: 'Image',
        },
    },
};
