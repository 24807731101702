export default {
    p: {
        rm: {
            ph: {
                name: '请输入名称',
                permission: '请点击左侧角色进行对应权限配置',
            },
            msg: {
                deleteConfirm: '确认删除该角色么',
            },
        },
    },
};
