import { FormattedMessage } from 'react-intl';
import { ProTable } from '@ant-design/pro-components';
import { Address } from '@ant-design/web3';
import { get } from 'lodash';

/**
 * APIs
 */
import { gNFTCollectionApi } from '@/services/marketplace-nft';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { NFTCollectionResult } from '@/services/marketplace-nft';
import { Tag } from 'antd';

const PageMarketplaceCollections: React.FC = () => {
    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<NFTCollectionResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.collectionId" />,
                width: 200,
            },
            {
                dataIndex: 'name',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.collectionName" />,
            },
            {
                dataIndex: 'icon_url',
                ellipsis: true,
                title: 'Icon',
                valueType: 'image',
                width: 80,
            },
            {
                dataIndex: 'chain',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.chain" />,
                width: 120,
            },
            {
                dataIndex: 'contract',
                title: <FormattedMessage id="mknft.t.c.eAddress" />,
                render: (_, entity) => (
                    <Address
                        ellipsis={{
                            headClip: 8,
                            tailClip: 6,
                        }}
                        copyable
                        address={get(entity, 'contract')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: 'floor_price',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.lowPrice" />,
                width: 120,
            },
            {
                dataIndex: 'best_offer_price',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.bestPrice" />,
                width: 120,
            },
            {
                dataIndex: 'sell_nft_count',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.sellCount" />,
                width: 120,
            },
            {
                dataIndex: 'total_nft_count',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.nftCount" />,
                width: 120,
            },
            {
                dataIndex: 'sell_nft_ratio',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.nftRate" />,
                width: 120,
            },
            {
                dataIndex: 'volume_24h',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.volume_24h" />,
                width: 200,
            },
            {
                dataIndex: 'amount_24h',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.amount_24h" />,
                width: 200,
            },
            {
                dataIndex: 'total_trading_volume',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.total_trading_volume" />,
                width: 200,
            },
            {
                dataIndex: 'total_trading_amount',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.total_trading_amount" />,
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => (
                    <Tag color={status === 1 ? 'green' : 'orange'}>{status === 1 ? 'Enabled' : 'Disabled'}</Tag>
                ),
                width: 120,
            },
            {
                dataIndex: '_fe_created_at',
                title: <FormattedMessage id="t.c.createdAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        rowKey: 'id',
        scroll: {
            x: 2570,
            y: `calc(100vh - 272px)`,
        },
        search: false,
        request: async (params) => {
            const res = await gNFTCollectionApi({
                page: params.current,
                limit: params.pageSize,
            });
            return {
                data: get(res, 'data.data.list', []).map((item) => {
                    item._fe_created_at = parseInt(`${item.created_at}`, 10);
                    return item;
                }),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return (
        <>
            <ProTable {...proTableProps} />
        </>
    );
};

export default PageMarketplaceCollections;
