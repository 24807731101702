export default {
    p: {
        plazaConfig: {
            t: {
                headerTitle: '广场列表',
            },
            tips: {
                unselected: '请点击左侧各板块进行内容配置',
            },
        },
    },
};
