import { useRef } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Modal, Popconfirm, Spin, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import TableCellMedia from '../components/table-cell-media';
import ComponentTableCellReason from '../components/table-cell-reason';
import ComponentTableCellDebate from '../components/table-cell-debate';
import ComponentTableCellAudio from '../components/table-cell-audio';

/**
 * APIs
 */
import { changeReportStatusApi, PostsReportApi, Status } from '@/services/report';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { IntlShape } from 'react-intl';
import type { PostReportRecordResult } from '@/services/report';

type TableParams = {
    pageSize: number;
    current: number;
};

type SeletRowState = {
    selects: string[];
    loading: boolean;
};

/**
 * Constants
 */
import ReportTypeENUM from '../components/report-type-enum';

const PagePostReport: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * States
     */
    const tableRef = useRef<ActionType>();
    const intl: IntlShape = useIntl();
    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        loading: false,
    });
    const [tableCellNormalLoading, setTableCellNormalLoading] = useSetState<Record<string, boolean>>({});
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const { run: runChangeReportStatusApi } = useRequest(changeReportStatusApi, {
        manual: true,
        onBefore: () => {
            setSelectState({
                loading: true,
            });
        },
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            } else {
                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            }
            setSelectState({
                selects: [],
                loading: false,
            });
            tableRef.current?.reload();
        },
        onError: (e) => {
            message.error(get(e, 'response.data.msg', 'Error'));
        },
        onFinally: () => {
            setSelectState({
                loading: false,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<PostReportRecordResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reportId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedPostContent', 'post_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postUid" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedPostContent', 'userInfo', 'user_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedPostContent', 'content', 'body'],
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: ['content', 'reportedPostContent', 'content', 'debate', 'title'],
                title: <FormattedMessage id="rm.t.c.debate" />,
                width: 200,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity: any) => (
                    <ComponentTableCellDebate
                        forText={get(entity, 'content.reportedPostContent.content.debate.forText', '')}
                        againstText={get(entity, 'content.reportedPostContent.content.debate.against_text', '')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_media',
                title: <FormattedMessage id="rm.t.c.postImg" />,
                render: (_, entity: any) => (
                    <TableCellMedia list={get(entity, 'content.reportedPostContent.content.uploaded_materials', [])} />
                ),
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="rm.t.c.postAudio" />,
                width: 320,
                render: (_, entity: any) => (
                    <ComponentTableCellAudio srcs={get(entity, 'content.reportedPostContent.audios', [])} />
                ),
            },
            {
                dataIndex: 'report_type',
                title: <FormattedMessage id="rm.t.c.reportType" />,
                width: 120,
                valueEnum: ReportTypeENUM,
            },
            {
                dataIndex: 'reason',
                title: <FormattedMessage id="rm.t.c.otherReason" />,
                render: (_, entity) => <ComponentTableCellReason type={entity.report_type} reason={entity.reason} />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="rm.t.c.reportTime" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Reports_Report-Moderation_Posts">
                        <Spin spinning={!!tableCellNormalLoading[entity.id]} size="small">
                            <a
                                key="normal"
                                onClick={() => {
                                    setTableCellNormalLoading({
                                        [entity.id]: true,
                                    });
                                    changeReportStatusApi({
                                        ids: [entity.id],
                                        status: Status.Normal,
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                tableRef.current?.reload();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellNormalLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellNormalLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <FormattedMessage id="rm.b.normal" />
                            </a>
                        </Spin>
                    </Permission>,

                    <Permission permission="Reports_Report-Moderation_Posts">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                key="delete"
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    changeReportStatusApi({
                                        ids: [entity.id],
                                        status: Status.Violate,
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                tableRef.current?.reload();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="rm.b.violate" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        search: false,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: () => ({
                disabled: selectState.loading,
            }),
            onChange: (selectedRowKeys) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    loading: false,
                });
            },
        },
        scroll: {
            y: 'calc(100vh - 272px)',
            x: 2580,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length) {
                return [];
            }
            return [
                <Permission permission="Reports_Report-Moderation_Posts">
                    <Button
                        type="primary"
                        loading={selectState.loading}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    setSelectState({
                                        loading: true,
                                    });
                                    await runChangeReportStatusApi({
                                        ids: selectState.selects,
                                        status: Status.Normal,
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="rm.b.multNormal" />
                    </Button>
                </Permission>,
            ];
        },
        request: async (params: TableParams) => {
            const res = await PostsReportApi({
                page: params.current,
                size: params.pageSize,
                status: [Status.Pending],
            });
            setSelectState({
                selects: [],
                loading: false,
            });
            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.totalCount', 0),
            };
        },
    };

    return <ProTable {...proTableProps} />;
};

export default PagePostReport;
