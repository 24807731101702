import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '../../report-management/components/table-cell-media';
import ComponentTableCellAudio from '@/pages/report-management/components/table-cell-audio';
import ComponentTableCellDebate from '@/pages/content-management/posts/components/table-cell-debate';

/**
 * APIs
 */
import { posts } from '@/services/posts';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FePostParams, PostResult, PostParams } from '@/services/posts';

/**
 * State
 */
type State = {
    dataSource: PostResult[];
};

const PageContentPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<PostParams>({});

    const {
        loading: loadingPosts,
        refresh: refreshPosts,
        run: runPosts,
    } = useRequest(posts, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FePostParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.create_date) {
                values.create_start = values.create_date[0].startOf('d').format();
                values.create_end = values.create_date[1].endOf('d').format();
                values.create_date = undefined;
            }
            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }
            if (values.content) {
                values.content = trim(values.content);
            }
            if (values.debate_title) {
                values.debate_title = trim(values.debate_title);
            }
            values.fe_is_deleted = undefined;
            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<PostResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'post_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postUid" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'body'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: ['content', 'debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.debate" />,
                width: 200,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        debate={get(entity, 'content.debate', null)}
                        formalDebate={get(entity, 'content.formal_debate', null)}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="rm.t.c.postImg" />,
                render: (_, entity) => <TableCellMedia list={get(entity, 'content.uploaded_materials', [])} />,
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="rm.t.c.postAudio" />,
                render: (_, entity) => {
                    const audio = get(entity, 'content.audio');
                    if (!audio) {
                        return '-';
                    }
                    return <ComponentTableCellAudio srcs={[audio]} />;
                },
                width: 320,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.deleted_at
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingPosts,
        options: {
            reload: refreshPosts,
        },
        pagination,
        rowKey: 'post_id',
        scroll: {
            x: 2010,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runPosts({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="post_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="content" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_title" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.debate' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="types" style={{ marginRight: 0 }}>
                            <Select
                                mode="multiple"
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.debateTopic' }),
                                        value: 'debate_title',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.image' }),
                                        value: 'image',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.video' }),
                                        value: 'video',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.audio' }),
                                        value: 'audio',
                                    },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.nft' }),
                                    //     value: 'nft',
                                    // },
                                ]}
                                placeholder={<FormattedMessage id="cp.f.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="create_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingPosts}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageContentPosts;
