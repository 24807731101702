export default {
    // creator topic
    ctp: {
        t: {
            c: {
                topic: '话题',
            },
        },
    },
    cpost: {
        e: {
            postExit: '帖子已存在',
            postNotFound: '帖子不存在',
        },
    },
    cad: {
        i: {
            empty: '请点击左侧各类型进行内容配置',
        },
        t: {
            headerTitle: 'Type List',
            c: {
                name: '名称',
                postOrShortsId: '帖子ID/短视频ID',
                title: '标题',
                cover: '封面',
            },
        },
    },
};
