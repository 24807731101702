import { PlusOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Form, Input, Radio, Upload } from 'antd';
import { get } from 'lodash';
import { useEffect } from 'react';
import store from 'store2';

/**
 * APIs
 */
import { checkPostId, uploadImage, uploadVideo } from '@/services/common';
import { uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { FormProps, UploadProps } from 'antd';

type State = {
    bg_type: 'Image' | 'Video';
    created_at: string;
    encrypt_post_id: string;
    idorurl: string;
    image: string;
    operator: string;
    resource: string;
    subtitle: string;
    title: string;
    type: 'ID' | 'Url';
    updated_at: string;
    uploadImage: boolean;
    uploadVideo: boolean;
};

const ComponentBlockPromotions: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        bg_type: 'Image',
        created_at: '',
        encrypt_post_id: '',
        idorurl: '',
        image: '',
        operator: '',
        resource: '',
        subtitle: '',
        title: '',
        type: 'ID',
        updated_at: '',
        uploadImage: true,
        uploadVideo: true,
    });

    const { current, dataSource, setCurrent, setDataSource } = useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    const { loading: loadingCheckPostId, runAsync: runAsyncCheckPostId } = useRequest(checkPostId, {
        manual: true,
    });

    const { loading: loadingUBlock, run: runUBlock } = useRequest(uBlock, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code === 150203) {
                return message.error('It has been edited by someone else, please refresh');
            }

            if (code !== 0) {
                return message.error(msg);
            }

            const prevData = dataSource.find((item) => item.type === current!.type);

            const index = prevData!.children!.findIndex((item) => item.id === current!.id);

            prevData!.children![index] = {
                ...current!,
                ...data,
            };

            setCurrent(data);

            setDataSource(dataSource);

            message.success('Updated');
        },
    });

    /**
     * ChildrenProps
     */
    const formProps: FormProps = {
        form,
        initialValues: {
            type: 'ID',
            bg_type: 'Image',
        },
        layout: 'vertical',
        onFinish: (values) => {
            const prevData = {
                created_at: new Date().toISOString(),
                ...current!.data,
                ...values,
                image: '',
                operator: store('user').name,
                resource:
                    values.bg_type === 'Image'
                        ? get(values, 'resource_image.0.url', '')
                        : get(values, 'resource_video.0.url', ''),
                resource_image: undefined,
                resource_video: undefined,
                updated_at: new Date().toISOString(),
            };

            if (values.type === 'ID') {
                runAsyncCheckPostId(values.idorurl).then(({ data: { data } }) => {
                    if (!data) {
                        return message.error('帖子 ID 验证不通过');
                    }

                    prevData.encrypt_post_id = data.encrypt_post_id;

                    runUBlock(current!.id, {
                        data: prevData,
                        updated_at: current!.updated_at,
                    });
                });
            } else {
                runUBlock(current!.id, {
                    data: prevData,
                    updated_at: current!.updated_at,
                });
            }
        },
    };

    const uploadImageProps: UploadProps = {
        accept: 'image/jpg,image/jpeg,image/png',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    const uploadVideoProps: UploadProps = {
        accept: 'video/mp4,video/mov,video/quicktime',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadVideo({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        form.resetFields();

        setState({
            bg_type: 'Image',
            type: 'ID',
            uploadImage: true,
            uploadVideo: true,
        });

        if (current?.data) {
            form.setFieldsValue({
                ...current.data,
                resource_image:
                    current.data.bg_type === 'Image' && current.data.resource
                        ? [{ name: 'Image', url: current.data.resource }]
                        : null,
                resource_video:
                    current.data.bg_type === 'Video' && current.data.resource
                        ? [{ name: 'Video', url: current.data.resource }]
                        : null,
            });

            setState({
                bg_type: current.data.bg_type,
                type: current.data.type,
                uploadImage: current.data.bg_type !== 'Image' || !current.data.resource,
                uploadVideo: current.data.bg_type !== 'Video' || !current.data.resource,
            });
        }
    }, [current]);

    return (
        <ProCard headStyle={{ padding: '16px 24px' }} title="推广位">
            <Form {...formProps}>
                <Form.Item label="类型" name="type" rules={[{ required: true }]}>
                    <Radio.Group
                        onChange={(e) => {
                            setState({
                                type: e.target.value,
                            });
                        }}
                    >
                        <Radio value="ID">帖子 ID</Radio>
                        <Radio value="Url">外链 URL</Radio>
                    </Radio.Group>
                </Form.Item>

                {state.type === 'ID' && (
                    <Form.Item label="帖子 ID" name="idorurl" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                )}

                {state.type === 'Url' && (
                    <Form.Item label="外链 URL" name="idorurl" rules={[{ required: true, type: 'url' }]}>
                        <Input />
                    </Form.Item>
                )}

                <Form.Item label="主标题" name="title" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="副标题" name="subtitle">
                    <Input />
                </Form.Item>

                <Form.Item label="Background type" name="bg_type" rules={[{ required: true }]}>
                    <Radio.Group
                        onChange={(e) => {
                            setState({
                                bg_type: e.target.value,
                            });
                        }}
                    >
                        <Radio value="Image">Background image</Radio>
                        <Radio value="Video">Background video</Radio>
                    </Radio.Group>
                </Form.Item>

                {state.bg_type === 'Image' && (
                    <Form.Item
                        extra="JPG/JPEG/PNG, within 3 MB"
                        label="Background image"
                        name="resource_image"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (value.length === 0) {
                                        return Promise.reject('Please upload the file');
                                    }

                                    const isDone = value.every((item: any) => item.url);

                                    return isDone ? Promise.resolve() : Promise.reject('Uploading');
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadImage: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadImageProps}>
                            {state.uploadImage && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                )}

                {state.bg_type === 'Video' && (
                    <Form.Item
                        extra="MP4/MOV, within 10 MB"
                        label="Background video"
                        name="resource_video"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (value.length === 0) {
                                        return Promise.reject('Please upload the file');
                                    }

                                    const isDone = value.every((item: any) => item.url);

                                    return isDone ? Promise.resolve() : Promise.reject('Uploading');
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadVideo: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadVideoProps}>
                            {state.uploadVideo && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                )}

                <Button htmlType="submit" loading={loadingCheckPostId || loadingUBlock} type="primary">
                    保存
                </Button>
            </Form>
        </ProCard>
    );
};

export default ComponentBlockPromotions;
