import store from 'store2';
import axios from './index';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_AI,
});

api.interceptors.request.use((config) => {
    if (store.has('token')) {
        config.headers.Authorization = 'Bearer ' + store.get('token');
    }

    return config;
});

/**
 * APIs
 */
// tags get_leaf_category
export const tagsGetLeafCategory = () => {
    return api.get<ResType<TagsGetLeafCategoryResult[]>>('/api/v2/tags/get_leaf_category');
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

// tags get_leaf_category
export type TagsGetLeafCategoryResult = {
    attributes: Record<string, string>;
    created_at: string;
    id: number;
    names: Record<string, string>;
    parent_id: number;
    updated_at: string;
};
