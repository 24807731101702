import { useRequest, useSetState } from 'ahooks';
import { message, Select } from 'antd';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

/**
 * APis
 */
import { gOperators } from '@/services/common';

/**
 * Types
 */
type ValueType = 'number' | 'string' | undefined;

type OperatorProps = {
    id?: string;
    value?: ValueType;
    onChange?: (value: ValueType) => void;
    model?: string;
};
type State = {
    users: any[];
};

const ComponentSearchFormSelectOperator: React.FC<OperatorProps> = ({
    id,
    value = undefined,
    onChange,
    model = '1',
}) => {
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        users: [],
    });

    /**
     * Requests
     */
    useRequest(() => gOperators(model), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setState({
                users: get(data, 'list', []).map((v: any) => ({ label: v.account.name, value: v.account.id })),
            });
        },
    });

    return (
        <Select
            id={id}
            value={value}
            allowClear={true}
            optionFilterProp="label"
            options={state.users}
            placeholder={<FormattedMessage id="t.c.operatedBy" />}
            showSearch={true}
            style={{ width: 200 }}
            onChange={onChange}
        />
    );
};

export default ComponentSearchFormSelectOperator;
