import { ProCard, ProTable } from '@ant-design/pro-components';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Drawer, Flex, Form, Input, Modal, Select, Typography } from 'antd';
import { get, isEmpty, isEqual, mapValues, omitBy } from 'lodash';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Permission from '@/components/permission';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { ambassadors, ambassadorsExport, bulkUAmbassador, privatePoolConfigs } from '@/services/ambassadors';

/**
 * Components
 */
import ComponentDetail from '@/pages/ambassadors/applications/components/detail';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, PaginationProps } from 'antd';
import type { AmbassadorsParams, AmbassadorsResult, PrivatePoolConfigsResult } from '@/services/ambassadors';

type State = {
    configs: PrivatePoolConfigsResult[];
    dataSource: AmbassadorsResult[];
    entity: AmbassadorsResult | null;
    open: boolean;
    openDetail: boolean;
    openRemark: boolean;
    openRevoke: boolean;
    openUnbound: boolean;
    selectedRowKeys: React.Key[];
    target: number;
    status: 'normal' | 'deregistered';
};

/**
 * Constants
 */
const defaultPayload: AmbassadorsParams = {
    account_id: undefined,
    apply_end: undefined,
    apply_start: undefined,
    remark: undefined,
    user_id: undefined,
    status: 'normal',
};

const PageAmbassadorsAmbassadorsAmbassadors: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const [formRemark] = Form.useForm();

    const [formRevoke] = Form.useForm();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [loading, setLoading] = useSetState<Record<string, boolean>>({});

    const [state, setState] = useSetState<State>({
        configs: [],
        dataSource: [],
        entity: null,
        open: false,
        openDetail: false,
        openRemark: false,
        openRevoke: false,
        openUnbound: false,
        selectedRowKeys: [],
        status: 'normal',
        target: 0,
    });

    const [countdown] = useCountDown({
        targetDate: state.target,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setState({
                selectedRowKeys: [],
            });

            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorsParams>({
        ...defaultPayload,
    });

    /**
     * Requests
     */
    useRequest(privatePoolConfigs, {
        defaultParams: [{ limit: 9999, page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                configs: data.list,
            });
        },
    });

    const { loading: loadingBulkUAmbassador, run: runBulkUAmbassador } = useRequest(bulkUAmbassador, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            // Close the drawer
            setState({
                open: false,
                openDetail: false,
                openRemark: false,
                openRevoke: false,
                openUnbound: false,
            });

            // Reload the table
            tableRef.current?.clearSelected?.();
            tableRef.current?.reload?.();
        },
    });

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassador} type="primary" onClick={() => form.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id="b.transfer" />,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const drawerDetailProps: DrawerProps = {
        maskClosable: false,
        open: state.openDetail,
        size: 'large',
        title: <FormattedMessage id="b.details" />,
        onClose: () => {
            setState({
                openDetail: false,
            });
        },
    };

    const drawerRemarkProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            openRemark: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassador} type="primary" onClick={() => formRemark.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.openRemark,
        title: <FormattedMessage id="b.remark" />,
        afterOpenChange: (open) => open || formRemark.resetFields(),
        onClose: () => {
            setState({
                openRemark: false,
            });
        },
    };

    const drawerRevokeProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            openRevoke: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassador} type="primary" onClick={() => formRevoke.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.openRevoke,
        title: <FormattedMessage id="b.revoke" />,
        afterOpenChange: (open) => open || formRevoke.resetFields(),
        onClose: () => {
            setState({
                openRevoke: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassador(values);
        },
    };

    const formRemarkProps: FormProps = {
        form: formRemark,
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassador(values);
        },
    };

    const formRevokeProps: FormProps = {
        form: formRevoke,
        layout: 'vertical',
        initialValues: {
            reason: 'You have not met the platform’s requirements for the ambassador role.',
        },
        onFinish: (values) => {
            runBulkUAmbassador({
                ...values,
                status: 'deregistered',
            });
        },
    };

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        initialValues: {
            status: 'normal',
        },
        onFinish: (values) => {
            if (values.apply_date) {
                values.apply_end = values.apply_date[1].endOf('d').format();
                values.apply_start = values.apply_date[0].startOf('d').format();
                values.apply_date = undefined;
            }

            setState({
                selectedRowKeys: [],
            });

            setPagination({
                current: 1,
                pageSize: 20,
            });

            const assignPayload = {
                ...defaultPayload,
                ...mapValues(values, (value) => (value === '' ? undefined : value)),
            };

            isEqual(payload, assignPayload) ? tableRef.current?.reload?.() : setPayload(assignPayload);
        },
    };

    const proTableProps: ProTableProps<AmbassadorsResult, AmbassadorsParams> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            // {
            //     dataIndex: ['user_info', 'name'],
            //     title: <FormattedMessage id="t.c.username" />,
            //     width: 200,
            // },
            // {
            //     dataIndex: ['user_info', 'nickname'],
            //     title: <FormattedMessage id="t.c.nickname" />,
            //     width: 200,
            // },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'application.request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                width: 200,
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'link'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.link" />,
                width: 200,
                renderText: (text) => (
                    <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                width: 200,
                valueType: 'select',
                valueEnum: {
                    normal: formatMessage({ id: 'p.ambassadors.publicPool.status.normal' }),
                    deregistered: formatMessage({ id: 'p.ambassadors.publicPool.status.revoked' }),
                },
            },
            {
                dataIndex: 'remark',
                title: <FormattedMessage id="b.remark" />,
            },
            {
                dataIndex: ['account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.owner" />,
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.createdAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <a
                        key="transfer"
                        onClick={() => {
                            form.setFieldsValue({
                                ids: [entity.id],
                            });

                            setState({
                                entity,
                                open: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.transfer" />
                    </a>,
                    <a
                        key="remark"
                        onClick={() => {
                            formRemark.setFieldsValue({
                                ids: [entity.id],
                                remark: entity.remark,
                            });

                            setState({
                                openRemark: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.remark" />
                    </a>,
                    <a
                        key="unbound"
                        onClick={() => {
                            // 二次确认
                            Modal.confirm({
                                content: formatMessage({ id: 'p.ambassadors.tips.ambassador.unbound' }),
                                onOk: () => {
                                    runBulkUAmbassador({
                                        ...defaultPayload,
                                        ids: [entity.id],
                                        status: 'unbound',
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.unbound" />
                    </a>,
                    <a
                        style={{ color: 'red' }}
                        key="revoke"
                        onClick={() => {
                            formRevoke.setFieldsValue({
                                ids: [entity.id],
                            });

                            setState({
                                openRevoke: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.revoke" />
                    </a>,
                    <a
                        key="details"
                        onClick={() => {
                            setState({
                                entity,
                                openDetail: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.details" />
                    </a>,
                ],
            },
        ],
        pagination,
        params: payload,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                setState({
                    selectedRowKeys,
                });
            },
        },
        scroll: {
            x: 1740,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertOptionRender: false,
        tableAlertRender: false,
        toolbar: {
            actions: [
                state.selectedRowKeys.length ? (
                    <Flex gap={8} key="bulk">
                        <Button
                            type="primary"
                            onClick={() => {
                                form.setFieldsValue({
                                    ids: state.selectedRowKeys,
                                });

                                setState({
                                    entity: null,
                                    open: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.transfer" />
                        </Button>

                        <Button
                            onClick={() => {
                                formRemark.setFieldsValue({
                                    ids: state.selectedRowKeys,
                                });

                                setState({
                                    entity: null,
                                    openRemark: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.remark" />
                        </Button>
                    </Flex>
                ) : null,
            ],
        },
        onLoadingChange: (loading) => {
            setLoading({
                table: loading as boolean,
            });
        },
        request: async (params) => {
            const res = await ambassadors({
                ...params,
                limit: params.pageSize,
                page: params.current,
            });

            if (get(res, 'data.code') !== 0) {
                message.error(get(res, 'data.msg'));
            }

            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap="wrap">
                        <SearchUser form={formSearch} />

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.publicPool.status.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.publicPool.status.revoked' }),
                                        value: 'deregistered',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="remark" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'b.remark' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Permission permission="Ambassadors_Ambassadors_Ambassadors_All-Data">
                            <Form.Item name="account_id" style={{ marginRight: 0 }}>
                                <Select
                                    allowClear={true}
                                    optionFilterProp="label"
                                    options={state.configs.map((config) => ({
                                        label: config.account.name,
                                        value: config.account.id,
                                    }))}
                                    placeholder={<FormattedMessage id="t.c.owner" />}
                                    showSearch={true}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                        </Permission>

                        <Form.Item
                            label={formatMessage({ id: 't.c.createdAt' })}
                            name="apply_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading['table'] === true}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Button
                            disabled={countdown !== 0}
                            onClick={() => {
                                formSearch.validateFields().then(async (values) => {
                                    if (values.apply_date) {
                                        values.apply_end = values.apply_date[1].endOf('d').format();
                                        values.apply_start = values.apply_date[0].startOf('d').format();
                                        values.apply_date = undefined;
                                    }

                                    const { data } = await ambassadorsExport(omitBy(values, isEmpty));

                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(formatMessage({ id: 'm.exportTips' }));

                                    setState({
                                        target: Date.now() + 1000 * 10,
                                    });
                                });
                            }}
                        >
                            <FormattedMessage id="b.export" />
                            {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 't.c.account' })}
                        name="account_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            optionFilterProp="label"
                            options={state.configs.map((config) => ({
                                disabled: config.account.id === state.entity?.account.id,
                                label: config.account.name,
                                value: config.account.id,
                            }))}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerDetailProps}>
                <ComponentDetail dataSource={state.entity?.application as AmbassadorsResult['application']} />
            </Drawer>

            <Drawer {...drawerRemarkProps}>
                <Form {...formRemarkProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item label={formatMessage({ id: 'b.remark' })} name="remark">
                        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={1000} showCount={true} />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerRevokeProps}>
                <Form {...formRevokeProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'p.ambassadors.t.c.revokeReason' })}
                        name="reason"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={1000} showCount={true} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default PageAmbassadorsAmbassadorsAmbassadors;
