import { create } from 'zustand';

/**
 * Types
 */
import type { BlocksResult } from '@/services/square';

type DigitizedCivilizationSquareStore = {
    current: BlocksResult | null;
    dataSource: BlocksResult[];
    refreshDataSource: number;
    setCurrent: (current: BlocksResult | null) => void;
    setDataSource: (dataSource: BlocksResult[]) => void;
    setRefreshDataSource: () => void;
};

export const useDigitizedCivilizationSquareStore = create<DigitizedCivilizationSquareStore>((set) => ({
    current: null,
    dataSource: [],
    refreshDataSource: 0,
    setCurrent: (current) => set({ current }),
    setDataSource: (dataSource) => set({ dataSource }),
    setRefreshDataSource: () => set({ refreshDataSource: Math.random() }),
}));
