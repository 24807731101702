export default {
    p: {
        rm: {
            ph: {
                name: 'Please enter name',
                permission: 'Click the role on the left to configure permissions.',
            },
            msg: {
                deleteConfirm: 'Delete this role',
            },
        },
    },
};
