export default {
    p: {
        inc: {
            column: {
                id: 'Season ID',
                timeRange: 'Start & End Time',
                target: 'Target Users & Reward SOC',
                targetTooltips: 'Total Historical number of users with successful liveness verification & reward SOC',
                status: 'Season Status',
                user: 'Target Users{value}',
                soc: 'Target Reward SOC{value}',
                socCount: 'Total Airdropped SOC',
                faceActiveCount: 'Users Verified',
            },
            msg: {
                soc: 'Please enter a number with up to 11 digits before the decimal point and up to 4 digits after the decimal point',
                user: 'Please enter a positive integer between 1 and 100000000',
            },
            status: {
                pending: 'Not started',
                running: 'In Progress',
                completed: 'Completed',
            },
        },
    },
};
