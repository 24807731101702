import axios from './index';

/**
 * APIs 创作学院
 */
// 获取列表
export const gCreatorAcademyApi = (params: { language: string }) => {
    return axios<ResType<CreatorAcademyResult[]>>('/v1/creator/school', {
        method: 'get',
        params,
    });
};

// 新增
export const cCreatorAcademyApi = (data: AddCreatorAcademyParams) => {
    return axios<PageResType<null>>('/v1/creator/school', {
        method: 'post',
        data,
    });
};

// 编辑
export const pCreatorAcademyApi = (id: IdType, data: AddCreatorAcademyParams) => {
    return axios<PageResType<null>>(`/v1/creator/school/${id}`, {
        method: 'put',
        data,
    });
};

// 删除
export const dCreatorAcademyApi = (id: IdType) => {
    return axios<PageResType<null>>(`/v1/creator/school/${id}`, {
        method: 'delete',
    });
};

// 排序
export const sCreatorAcademyApi = (data: { ids: IdType[] }) => {
    return axios<PageResType<null>>('/v1/creator/school/sort', {
        method: 'put',
        data,
    });
};

/**
 * APIs 创作学院类型详情
 */
// 获取列表
export const gCreatorAcademyDetailApi = (params: { subject_id: IdType }) => {
    return axios<ResType<CreatorAcademyDetailResult[]>>('/v1/creator/lessons', {
        method: 'get',
        params,
    });
};

// 新增推荐帖子
export const cCreatorAcademyDetailApi = (data: AddCreatorAcademyDetailParams) => {
    return axios<PageResType<null>>('/v1/creator/lessons', {
        method: 'post',
        data,
    });
};

// 编辑推荐帖子
export const pCreatorAcademyDetailApi = (id: IdType, data: AddCreatorAcademyDetailParams) => {
    return axios<PageResType<null>>(`/v1/creator/lessons/${id}`, {
        method: 'put',
        data,
    });
};

// 删除
export const dCreatorAcademyDetailApi = (id: IdType) => {
    return axios<PageResType<null>>(`/v1/creator/lessons/${id}`, {
        method: 'delete',
    });
};

// 排序
export const sCreatorAcademyDetailApi = (data: { ids: IdType[] }) => {
    return axios<PageResType<null>>('/v1/creator/lessons/sort', {
        method: 'put',
        data,
    });
};

// 上传封面
export const uCreatorAcademyDetailCoverApi = (id: IdType, data: FormData) => {
    // const formData = new FormData();
    // formData.append('file', data.file);

    // return axios<ResType<string[]>>('/v1/s3/image', {
    //     data: formData,
    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //     },
    //     method: 'post',
    // });

    return axios<PageResType<null>>(`/v1/creator/lessons/${id}/cover`, {
        method: 'post',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';
export type IdType = string | number;
export type TypeValue = 1 | 2;

/**
 * CreatorSchool
 */
export type CreatorAcademyResult = {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 排序，0在最前面
     */
    index: number;
    /**
     * 语言区域，EN,CHS,JPN,VIE...
     */
    language?: string;
    /**
     * 科目名称币如web3 知识
     */
    name: string;
    /**
     * 状态，1开启，0关闭
     */
    status: boolean;
    /**
     * 更新时间
     */
    updated_at: string;
};

export type AddCreatorAcademyParams = Pick<CreatorAcademyResult, 'status' | 'name'> & {
    updated_at?: string;
    language?: string;
};

/**
 * 响应数据
 *
 * CreatorLesson
 */
export type CreatorAcademyDetailResult = {
    content_id: string;
    cover_image: string;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    operator_account: string;
    /**
     * 操作人后台账号 ID
     */
    operator_account_id: string;
    subject?: CreatorAcademyResult;
    subject_id: string;
    title: string;
    type: TypeValue;
    /**
     * 更新时间
     */
    updated_at: string;
};

export type AddCreatorAcademyDetailParams = {
    content_id?: string;
    cover_image: string;
    subject_id?: string;
    title: string;
    /**
     * 1 帖子，2 视频
     */
    type?: TypeValue;

    updated_at?: string;

    _cover_image_file?: any;

    _language?: string;
};
