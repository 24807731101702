import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '../../report-management/components/table-cell-media';
import ComponentTableCellAudio from '@/pages/report-management/components/table-cell-audio';
import ComponentTableCellDebate from '@/pages/content-management/posts/components/table-cell-debate';
import ComponentSearchFormSelectOperator from '@/pages/report-management/components/search-form-select-operator';
import Permission from '@/components/permission';
import ComponentDrawerFormCustom from './components/drawer-form-custom';

/**
 * Constant
 */
import { CreatorLanguageOptions, LanguageMap } from '@/const/creator-language';

/**
 * APIs
 */
import { dCreatorPostsApi, gCreatorPostsApi, iCreatorPostsApi } from '@/services/creator-posts';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { CreatorPostsParams, CreatorPostsResult, FeCreatorPostsParams } from '@/services/creator-posts';
import ComponentUploadDrawer from '@/components/upload-drawer';

type SeletRowState = {
    selects: string[];
    loading: boolean;
};

/**
 * State
 */
type State = {
    dataSource: CreatorPostsResult[];
    openDrawer: boolean;
    entity: CreatorPostsResult | null;
    openUploadDrawer: boolean;
};

const PageCreatorPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        openDrawer: false,
        entity: null,
        openUploadDrawer: false,
    });

    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        loading: false,
    });
    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<CreatorPostsParams>({});

    const {
        loading: loadingCreatorPostsApi,
        refresh: refreshCreatorPostsApi,
        run: runCreatorPostsApi,
    } = useRequest(gCreatorPostsApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * Requests
     */
    const { run: runDeleteCreatorTopicApi } = useRequest(dCreatorPostsApi, {
        manual: true,
        onBefore: () => {
            setSelectState({
                loading: true,
            });
        },
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            } else {
                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            }
            setSelectState({
                selects: [],
                loading: false,
            });
            refreshCreatorPostsApi();
        },
        onError: (e) => {
            message.error(get(e, 'response.data.msg', 'Error'));
        },
        onFinally: () => {
            setSelectState({
                loading: false,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FeCreatorPostsParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.created_date) {
                values.created_start = values.created_date[0].startOf('d').format();
                values.created_end = values.created_date[1].endOf('d').format();
                values.created_date = undefined;
            }

            if (values.operation_date) {
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values.content) {
                values.content = trim(values.content);
            }
            if (values.debate_title) {
                values.debate_title = trim(values.debate_title);
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<CreatorPostsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'post_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postUid" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: 'content',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: 'topic',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.debate" />,
                width: 200,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        against={get(entity, 'against', null)}
                        forTxt={get(entity, 'for', null)}
                        debate={null}
                        formalDebate={null}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="rm.t.c.postImg" />,
                render: (_, entity) => <TableCellMedia list={get(entity, 'media', [])} />,
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="rm.t.c.postAudio" />,
                render: (_, entity) => {
                    const audio = get(entity, 'audio');
                    if (!audio) {
                        return '-';
                    }
                    return <ComponentTableCellAudio srcs={[{ url: audio }]} />;
                },
                width: 320,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'language',
                title: <FormattedMessage id="p.ambassadors.detail.languages" />,
                render: (val) => LanguageMap[val as string] || val,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Creator-Center_Top-Posts" key="delete">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    dCreatorPostsApi({
                                        post_ids: [entity.post_id],
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                refreshCreatorPostsApi();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingCreatorPostsApi,
        options: {
            reload: refreshCreatorPostsApi,
        },
        pagination,
        rowKey: 'post_id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: () => ({
                disabled: selectState.loading,
            }),
            onChange: (selectedRowKeys) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    loading: false,
                });
            },
        },
        scroll: {
            x: 2660,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length) {
                return [
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="b.add" />
                    </Button>,
                    <Button
                        onClick={() => {
                            setState({
                                openUploadDrawer: true,
                            });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="b.import" />
                    </Button>,
                ];
            }
            return [
                <Permission permission="Creator-Center_Top-Posts" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={selectState.loading}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    setSelectState({
                                        loading: true,
                                    });
                                    await runDeleteCreatorTopicApi({
                                        post_ids: selectState.selects,
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runCreatorPostsApi({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="post_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="content" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_title" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.debate' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="created_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item name="language" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={CreatorLanguageOptions}
                                placeholder={intl.formatMessage({ id: 'p.ambassadors.detail.languages' })}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Creator-Center_Top-Posts" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingCreatorPostsApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshCreatorPostsApi();
                }}
            />

            <ComponentUploadDrawer
                visible={state.openUploadDrawer}
                templateHref="/template/posts.xlsx"
                closeCallback={(reload) => {
                    setState({
                        openUploadDrawer: false,
                    });
                    reload && refreshCreatorPostsApi();
                }}
                uploadApi={(options) => {
                    const formData = new FormData();
                    formData.append('file', options.file as File);
                    iCreatorPostsApi(formData).then(options.onSuccess, options.onError);
                }}
            />
        </>
    );
};

export default PageCreatorPosts;
