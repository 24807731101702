import { useEffect } from 'react';
import { App, Button } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import type { ModalFormState } from './components/token-pool-modal-form';
import { useRequest, useSetState } from 'ahooks';
import Permission from '@/components/permission';
import TokenPoolModalForm from './components/token-pool-modal-form';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { tokenPoolApi } from '@/services/token-pool';
import { tokenConfigs } from '@/services/token-config';

/**
 * Types
 */
import type { PaginationProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { ProTableProps } from '@ant-design/pro-components';
import type { TokenPool } from '@/services/token-pool';
import type { TokenConfig } from '@/services/token-config';

type State = {
    dataSource: TokenPool[];
    modalFormState: ModalFormState;
    tokenConfigs: TokenConfig[];
    socCount: number;
    faceActiveCount: number;
};

const PageTokenPool: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        dataSource: [],
        tokenConfigs: [],
        modalFormState: {
            open: false,
            data: undefined,
        },
        socCount: 0,
        faceActiveCount: 0,
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshList,
        run: runList,
    } = useRequest(tokenPoolApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    useRequest(tokenConfigs, {
        onSuccess: ({ data }) => {
            if (data.code === 0) {
                setState({
                    tokenConfigs: data.data,
                });
            }
        },
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    const getTokenConfig = (token: string) => {
        return state.tokenConfigs.find((item) => item?.token?.id === token);
    };

    const proTableProps: ProTableProps<TokenPool, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'id',
                title: 'ID',
                width: 120,
            },
            {
                dataIndex: 'token0',
                width: 120,
                title: <FormattedMessage id="p.tp.column.token0" />,
                render: (_, entity) => {
                    if (!entity.token0) {
                        return '-';
                    }
                    const tokenConfig = getTokenConfig(entity.token0.toString());
                    const url = tokenConfig?.token?.url;
                    return (
                        <div>
                            <img style={{ width: '20px', height: '20px', marginRight: '8px' }} src={url} />
                            {tokenConfig?.token?.label}
                        </div>
                    );
                },
            },
            {
                dataIndex: 'token1',
                width: 120,
                title: <FormattedMessage id="p.tp.column.token1" />,
                render: (_, entity) => {
                    if (!entity.token1) {
                        return '-';
                    }
                    const tokenConfig = getTokenConfig(entity.token1.toString());
                    const url = tokenConfig?.token?.url;
                    return (
                        <div>
                            <img style={{ width: '20px', height: '20px', marginRight: '8px' }} src={url} />
                            {tokenConfig?.token?.label}
                        </div>
                    );
                },
            },
            {
                dataIndex: 'fee',
                width: 120,
                title: 'Fee Tier',
                render: (_, entity) => {
                    return <>{`${entity.fee ? Number(entity.fee) / 10000 : 0}%`}</>;
                },
            },
            {
                dataIndex: 'default_fee',
                width: 120,
                title: <FormattedMessage id="p.tp.column.defaultFee" />,
                render: (_, entity) => {
                    return (
                        <>
                            {entity.default_fee
                                ? intl.formatMessage({ id: 'p.tp.defaultFee.true' })
                                : intl.formatMessage({ id: 'p.tp.defaultFee.false' })}
                        </>
                    );
                },
            },
            {
                dataIndex: 'price',
                width: 120,
                title: <FormattedMessage id="p.tp.column.price" />,
            },
            {
                dataIndex: 'operator',
                width: 120,
                title: <FormattedMessage id="t.c.operatedBy" />,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => {
                    if (entity.default_fee) {
                        return null;
                    }
                    return (
                        <Permission permission="Marketplace_Token_Pool_Edit">
                            <a
                                onClick={() => {
                                    setState({
                                        modalFormState: {
                                            open: true,
                                            data: entity,
                                        },
                                    });
                                }}
                            >
                                <FormattedMessage id="common.edit" />
                            </a>
                        </Permission>
                    );
                },
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshList,
        },
        rowKey: 'id',
        toolBarRender: () => [
            <Permission permission="Marketplace_Token_Pool_Add">
                <Button
                    type="primary"
                    onClick={() => {
                        setState({
                            modalFormState: {
                                open: true,
                                data: undefined,
                            },
                        });
                    }}
                >
                    <FormattedMessage id="common.add" />
                </Button>
            </Permission>,
        ],
        scroll: {
            y: 'calc(100vh - 258px)',
            x: 1090,
        },
        search: false,
        tableAlertRender: false,
    };
    /**
     * Effects
     */
    useEffect(() => {
        runList({
            page: pagination.current,
            limit: pagination.pageSize,
        });
    }, [pagination.current, pagination.pageSize]);

    return (
        <>
            <ProTable {...proTableProps} />

            <TokenPoolModalForm
                state={state.modalFormState}
                reload={refreshList}
                tokenPools={state.dataSource || []}
                onClose={() => {
                    setState({
                        modalFormState: {
                            open: false,
                            data: undefined,
                        },
                    });
                }}
            />
        </>
    );
};

export default PageTokenPool;
