import { useState } from 'react';
import { useBoolean, useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Select, Space, Switch } from 'antd';

/**
 * APIs
 */
import { cTypeApi, gNFTCollectionApi, pTypeApi, ErrorCodeMap } from '@/services/marketplace-nft';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

// form submit
const formSubmit = async (propsData: NFTTypeListResult | null | undefined, formData: CreateNFTTypeParams) => {
    const status = formData.status ? 1 : 0;
    // 区分是编辑还是新增
    if (propsData && propsData.id) {
        return await pTypeApi(
            propsData.collection_id,
            omit({
                status,
                updated_at: propsData.updated_at,
            }),
        );
    }
    return await cTypeApi({
        collection_id: formData.collection_id,
        status,
    });
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { CreateNFTTypeParams, NFTCollectionResult, NFTTypeListResult } from '@/services/marketplace-nft';

type SelectOptions = {
    label: string | number;
    value: string | number;
};

type Props = {
    open: boolean;
    entity: NFTTypeListResult | null | undefined;
    language: string;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentDrawerFormType: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [collections, setCollections] = useState<SelectOptions[]>([]);
    const [form] = Form.useForm();

    /**
     * Request
     */
    const { run: runNFTCollectionApi } = useRequest(
        () =>
            gNFTCollectionApi({
                status: 1,
                page: '1',
                limit: '999',
            }),
        {
            onSuccess: ({ data }) => {
                setCollections(
                    get(data, 'data.list', []).map((v: NFTCollectionResult) => {
                        return {
                            label: `${v.id} (${v.name})`,
                            value: v.id,
                        };
                    }),
                );
            },
        },
    );

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            setSubmittingTrue();
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, ErrorCodeMap, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {
                                    setSubmittingFlase();
                                });
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else {
                runNFTCollectionApi();
                if (entity) {
                    form.setFieldsValue({
                        collection_id: entity.collection_id,
                        status: `${entity.status}` === '1',
                    });
                }
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<CreateNFTTypeParams> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                {entity && entity.collection_id === '0' ? null : (
                    <Form.Item
                        name="collection_id"
                        label={intl.formatMessage({ id: 'mknft.t.c.collectionId' })}
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={!!entity}
                            allowClear={true}
                            optionFilterProp="label"
                            options={collections}
                            showSearch={true}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    initialValue={false}
                    name="status"
                    label={intl.formatMessage({ id: 't.c.status' })}
                    rules={[{ required: true }]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentDrawerFormType;
