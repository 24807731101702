export default {
    rm: {
        b: {
            normal: '正常',
            violate: '违规',
            multNormal: '批量正常',
        },
        t: {
            c: {
                reportId: '举报ID',
                postUid: '帖子ID',
                formPostUid: '所属帖子ID',
                commentId: '评论ID',
                commentContent: '评论内容',
                postAccUid: '发布者UID',
                content: '正文',
                postImg: '图片/视频',
                postAudio: '音频',
                nickName: '昵称',
                name: '用户名',
                avatar: '头像',
                userInfo: '个人简介',
                reportType: '举报类型',
                otherReason: '其他原因',
                reportTime: '举报时间',
                'reporter-UID': '举报者UID',
                'review-result': '审核结果',
                debate: '辩题',
                debateUsers: '正方&反方',
            },
        },
        reportType: {
            racism: '原因1',
            platform_safety: '原因2',
            misinformation: '原因3',
            other: '其他原因',
        },
        p: {},
    },
};
