import { ProCard } from '@ant-design/pro-components';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
import ComponentBlockCustom from './components/block-custom';
import ComponentBlockGroups from './components/block-groups';
import ComponentBlockHots from './components/block-hots';
import ComponentBlockKols from './components/block-kols';
import ComponentBlockPromotions from './components/block-promotions';
import ComponentBlockShorts from './components/block-shorts';
import ComponentBlockTags from './components/block-tags';
import ComponentTable from './components/table';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

const PageClientConfigPlazaConfig: React.FC = () => {
    /**
     * States
     */
    const { current } = useDigitizedCivilizationSquareStore();

    return (
        <Row gutter={16} wrap={false}>
            <Col flex="480px">
                <ComponentTable />
            </Col>

            <Col flex="auto">
                {current === null ? (
                    <ProCard style={{ padding: 48, textAlign: 'center' }}>
                        <FormattedMessage id="p.plazaConfig.tips.unselected" />
                    </ProCard>
                ) : null}

                {/* 分类标签 */}
                {current?.type === 'Tag' ? <ComponentBlockTags /> : null}

                {/* 热门内容 */}
                {current?.type === 'Hot' ? <ComponentBlockHots /> : null}

                {/* 自定义榜单 */}
                {current?.type?.startsWith('Custom') ? <ComponentBlockCustom /> : null}

                {/* 推广位 */}
                {current?.type === 'Promotion' ? <ComponentBlockPromotions /> : null}

                {/* KOL */}
                {current?.type === 'KOL' ? <ComponentBlockKols /> : null}

                {/* 社群 */}
                {current?.type === 'Group' ? <ComponentBlockGroups /> : null}

                {/* 短视频 */}
                {current?.type === 'Shorts' ? <ComponentBlockShorts /> : null}
            </Col>
        </Row>
    );
};

export default PageClientConfigPlazaConfig;
