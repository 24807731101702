export default {
    mknft: {
        e: {
            cNotFound: 'Collection not Found',
            cAlreadyExists: 'Collection already exists',
            nAlreadyExists: 'NFT already exists',
            nNotFound: 'NFT not Found',
            nftHasDisabeld: 'The current NFT has been disabled',
            nftNotToC: 'The NFT does not belong to the current collection',
        },
        i: {
            empty: 'Please click the type on the left to configure NFT',
        },
        t: {
            c: {
                collectionId: 'Collection ID',
                collectionName: 'Collection Name',
                name: 'Name',
                nftName: 'NFT Name',
                img: 'Image',
                count: 'Quantity',
                lowPrice: 'Floor Price',
                subType: 'Subcategory',
                eAddress: 'Contract Address',
                chain: 'Chain',
                nftId: 'NFT ID',
                bestPrice: 'Best Offer',
                sellCount: 'NFTs For Sale',
                nftCount: 'Total NFTs',
                nftRate: '%NFTs For Sale',
                volume_24h: '24h Trading Volume',
                amount_24h: '24h Trading Value',
                total_trading_volume: 'Total Trading Volume',
                total_trading_amount: 'Total Trading Value',
            },
        },
        makp: {},
        magt: {},
        col: {},
    },
};
