import { ProCard, ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Badge, Button, DatePicker, Flex, Form, Select } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, omitBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { exports } from '@/services/exports';
import { operators } from '@/services/operator';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { ExportParams, ExportResponse } from '@/services/exports';
import type { OperatorsResponse } from '@/services/operator';

type State = {
    dataSource: ExportResponse[];
    operators: OperatorsResponse[];
};

const PageExports: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        operators: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<ExportParams>({
        create_end: dayjs().endOf('d').format(),
        create_start: dayjs().startOf('d').format(),
        operator_account_id: undefined,
    });

    /**
     * Requests
     */
    useRequest(operators, {
        defaultParams: [{ limit: 9999, model: '5', page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                operators: data.list,
            });
        },
    });

    const {
        loading: loadingExports,
        refresh: refreshExports,
        run: runExports,
    } = useRequest(exports, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            status: 'Success',
            date: [dayjs().startOf('d'), dayjs().endOf('d')],
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.date) {
                values.create_end = values.date[1].endOf('d').format();
                values.create_start = values.date[0].startOf('d').format();
                delete values.date;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                create_end: undefined,
                create_start: undefined,
                operator_account_id: undefined,

                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<ExportResponse, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'origin',
                ellipsis: true,
                title: <FormattedMessage id="p.exports.t.c.exportContent" />,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                width: 120,
                valueEnum: {
                    pending: <Badge status="processing" text={<FormattedMessage id="p.exports.status.processing" />} />,
                    failed: <Badge status="error" text={<FormattedMessage id="p.exports.status.failed" />} />,
                    completed: <Badge status="success" text={<FormattedMessage id="p.exports.status.completed" />} />,
                },
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, record) => {
                    if (record.status !== 'completed') {
                        return <span>-</span>;
                    }

                    return (
                        <a href={record.download_url} target="_blank" rel="noreferrer">
                            <FormattedMessage id="b.download" />
                        </a>
                    );
                },
            },
        ],
        dataSource: state.dataSource,
        loading: loadingExports,
        options: {
            reload: refreshExports,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 870,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runExports({
            ...payload,
            limit: pagination.pageSize,
            page: pagination.current,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Permission permission="System-Management_Downloads_All-Data">
                            <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                                <Select
                                    allowClear={true}
                                    optionFilterProp="label"
                                    options={state.operators.map((operator) => ({
                                        label: operator.account.name,
                                        value: operator.account.id,
                                    }))}
                                    placeholder={<FormattedMessage id="t.c.operatedBy" />}
                                    showSearch={true}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                        </Permission>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingExports}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageExports;
