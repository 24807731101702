import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取任务列表
export const taskConfigs = (params: TaskConfigsParams) => {
    return axios<ResType<Task[]>>('/v1/tasks', {
        method: 'get',
        params,
    });
};

// 任务列表排序
export const taskConfigsSorts = (data: { ids: string[] | number[] }) => {
    return axios<ResType<Task[]>>('/v1/tasks/index', {
        method: 'put',
        data,
    });
};

// 删除任务
export const dTaskConfigs = (data: { ids: string[] | number[] }) => {
    return axios<ResType<Task[]>>('/v1/tasks', {
        method: 'delete',
        data,
    });
};

// 修改任务
export const pTaskConfigs = (id: string | number, data: TaskParams) => {
    return axios<ResType<Task[]>>(`/v1/tasks/${id}`, {
        method: 'put',
        data,
    });
};

// 编辑任务
export const cTaskConfigs = (data: TaskParams) => {
    return axios<ResType<Task[]>>('/v1/tasks', {
        method: 'post',
        data,
    });
};

export const errorCodeMsgMap: Record<number, string> = {
    270101: 'tc.i.incentivesInProgress',
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type TaskConfigsParams = {
    group: 'web2' | 'web3';
};

/**
 * 任务
 */
export interface Task {
    /**
     * 跳转地址，执行动作，后续扩展
     */
    action: Action;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * 描述，英文
     */
    desc: string;
    /**
     * 分组，web2 | web3
     */
    group: Group;
    /**
     * ID
     */
    id: string;
    /**
     * 顺序，默认0
     */
    index?: number;
    /**
     * 奖励积分，小数点前最多11位小数点后最多4位的数
     */
    integral: number;
    /**
     * Record<CHS | JPN | VIE, {title: string, desc: string}>
     */
    languages?: Languages;
    operator_account: OperatorAccount;
    /**
     * 启动状态，open | close
     */
    status: Status;
    /**
     * 子任务 ID
     */
    sub_task_id: string;
    /**
     * 任务种类 ID
     */
    task_type_id: string;
    /**
     * 标题，英文
     */
    title: string;
    /**
     * 类型，daily | period | once
     */
    type: Type;
    /**
     * 更新时间
     */
    updated_at: Date;
    /**
     * 条件参数n
     */
    value: number;
}

/**
 * 跳转地址，执行动作，后续扩展
 */
export interface Action {
    android: string;
    ios: string;
    web: string;
}

/**
 * 分组，web2 | web3
 */
export enum Group {
    Web2 = 'web2',
    Web3 = 'web3',
}

/**
 * Record<CHS | JPN | VIE, {title: string, desc: string}>
 */
export interface Languages {
    CHS: Language;
    JPN: Language;
}

/**
 * 标题/描述语言包
 */
export interface Language {
    desc: string;
    title: string;
}

export interface OperatorAccount {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 操作人
     */
    operator: string;
    /**
     * 权限
     */
    permissions: string[];
    /**
     * 角色
     */
    roles?: any;
    /**
     * 状态
     */
    status: number;
    /**
     * 更新时间
     */
    updated_at: Date;
}

/**
 * 启动状态，open | close
 */
export enum Status {
    Close = 'close',
    Open = 'open',
}

/**
 * 类型，daily | period | once
 */
export enum Type {
    Daily = 'daily',
    Once = 'once',
    Period = 'period',
}

export interface TaskParams {
    /**
     * 跳转地址，执行动作，后续扩展
     */
    action: Action;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * 描述，英文
     */
    desc: string;
    /**
     * ID
     */
    id: string;
    /**
     * 奖励积分，小数点前最多11位小数点后最多4位的数
     */
    integral: number;
    /**
     * Record<CHS | JPN | VIE, {title: string, desc: string}>
     */
    languages?: Languages;
    /**
     * 启动状态，open | close
     */
    status: Status;
    /**
     * 子任务 ID
     */
    sub_task_id: string;
    /**
     * 任务种类 ID
     */
    task_type_id: string;
    /**
     * 标题，英文
     */
    title: string;
    /**
     * 类型，daily | period | once
     */
    type: Type;
    /**
     * 更新时间
     */
    updated_at: Date;
    /**
     * 条件参数n
     */
    value: number;
}
