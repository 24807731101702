import { useState } from 'react';
import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, InputNumber, Segmented, Select, Space, Switch } from 'antd';

/**
 * APIs
 */
import { pTaskConfigs, cTaskConfigs, Status, errorCodeMsgMap } from '@/services/task-config';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';
// form submit
const formSubmit = async (propsData: Task | null, formData: TaskParams) => {
    formData.status = formData.status ? Status.Open : Status.Close;
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        return await pTaskConfigs(propsData.id, formData);
    }
    return await cTaskConfigs(formData);
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { Task, TaskParams } from '@/services/task-config';

type LanguageType = (typeof Language)[number];

type Props = {
    open: boolean;
    entity: Task | null;
    group: 'web2' | 'web3';
    closeModelForm: (reload?: boolean) => void;
};

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP'];
const TypeArrary = [
    {
        label: <FormattedMessage id="tc.type.daily" />,
        value: 'daily',
    },
    {
        label: <FormattedMessage id="tc.type.period" />,
        value: 'period',
    },
    {
        label: <FormattedMessage id="tc.type.once" />,
        value: 'once',
    },
];

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, group, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [showLanguage, setShowLanguage] = useState('EN');

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData: TaskParams) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, errorCodeMsgMap, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                setShowLanguage('EN');
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    _language: 'EN',
                    group: group,
                    title: entity.title,
                    desc: entity.desc,
                    languages: entity.languages,
                    task_type_id: entity.task_type_id,
                    sub_task_id: entity.sub_task_id,
                    type: entity.type,
                    value: entity.value,
                    integral: entity.integral,
                    action: entity.action,
                    status: entity.status === Status.Open,
                });
            } else {
                form.setFieldsValue({
                    _language: 'EN',
                    group: group,
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="_language"
                    label={intl.formatMessage({ id: 'tc.f.language' })}
                    rules={[{ required: true }]}
                >
                    <Segmented<LanguageType>
                        options={Language}
                        value={showLanguage}
                        onChange={(value) => {
                            setShowLanguage(value);
                            form.setFieldValue('_language', value);
                        }}
                    />
                </Form.Item>
                {Language.map((v) => {
                    return (
                        <div key={v} style={{ display: showLanguage === v ? 'block' : 'none' }}>
                            <Form.Item
                                name={v === 'EN' ? 'title' : ['languages', v, 'title']}
                                label={intl.formatMessage({ id: 'tc.f.title' })}
                                rules={[{ required: 'EN' === v }]}
                                initialValue=""
                            >
                                <Input showCount maxLength={200} />
                            </Form.Item>

                            <Form.Item
                                name={v === 'EN' ? 'desc' : ['languages', v, 'desc']}
                                label={intl.formatMessage({ id: 'tc.f.desc' })}
                                rules={[{ required: 'EN' === v }]}
                                initialValue=""
                            >
                                <Input showCount maxLength={200} />
                            </Form.Item>
                        </div>
                    );
                })}

                <Form.Item name="group" label={intl.formatMessage({ id: 'tc.f.group' })} rules={[{ required: true }]}>
                    <Select
                        disabled={true}
                        allowClear={true}
                        showSearch={true}
                        optionFilterProp="label"
                        options={[
                            {
                                label: 'web2',
                                value: 'web2',
                            },
                            {
                                label: 'web3',
                                value: 'web3',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    name="task_type_id"
                    label={intl.formatMessage({ id: 'tc.t.c.taskTypeId' })}
                    rules={[{ required: true }]}
                >
                    <Input showCount maxLength={20} />
                </Form.Item>

                <Form.Item
                    name="sub_task_id"
                    label={intl.formatMessage({ id: 'tc.t.c.subTaskId' })}
                    rules={[{ required: true }]}
                >
                    <Input showCount maxLength={20} />
                </Form.Item>

                <Form.Item name="type" label={intl.formatMessage({ id: 'tc.t.c.type' })} rules={[{ required: true }]}>
                    <Select allowClear={true} showSearch={true} optionFilterProp="label" options={TypeArrary} />
                </Form.Item>

                <Form.Item
                    name="value"
                    label={intl.formatMessage({ id: 'tc.t.c.paramsN' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} max={99999999} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="integral"
                    label={intl.formatMessage({ id: 'tc.t.c.awardValue' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber
                        stringMode
                        step="0.0001"
                        max={99999999999.9999}
                        precision={4}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name={['action', 'android']}
                    label={intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'Android' })}
                    rules={[{ required: true }]}
                >
                    <Input showCount maxLength={255} />
                </Form.Item>

                <Form.Item
                    name={['action', 'ios']}
                    label={intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'IOS' })}
                    rules={[{ required: true }]}
                >
                    <Input showCount maxLength={255} />
                </Form.Item>

                <Form.Item
                    name={['action', 'web']}
                    label={intl.formatMessage({ id: 'tc.t.c.action' }, { value: 'Web' })}
                    rules={[{ required: false }]}
                >
                    <Input showCount maxLength={255} />
                </Form.Item>

                <Form.Item
                    name="status"
                    label={intl.formatMessage({ id: 'tc.t.c.status' })}
                    rules={[{ required: true }]}
                    initialValue={false}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
