export default {
    p: {
        tp: {
            column: {
                token: 'Token',
                token0: 'Token0',
                token1: 'Token1',
                fee: 'Fee Tier',
                defaultFee: 'Default Fee',
                price: 'Initial Price',
            },
            defaultFee: {
                true: 'True',
                false: 'False',
            },
            msg: {
                token0GreaterThanToken1: 'Token0’s pair sort should be less than Token1’s pair sort',
                price: 'Please enter a number greater than 0, with up to 11 digits before the decimal point and up to 10 digits after the decimal point',
                defaultFee: 'The fee tier for the first added pair must be set to the default fee',
                302001: 'The deviation between the Initial Price and the Market Price should be within 5%',
            },
            tips: {
                price: 'The number of Token1 received in exchange for 1 Token0',
            },
        },
    },
};
