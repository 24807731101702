import Permission from '@/components/permission';
import { HolderOutlined } from '@ant-design/icons';
import { ActionType, DragSortTable, DragTableProps, ProCard } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Col, Popconfirm, Row, Spin, Tag, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * Components
 */
import ComponentRightTable from './components/right-table';
import ComponentDrawerFormType from './components/drawer-form-type';

/**
 * APIs
 */
import { dTypeApi, gTypeListApi, sTypeApi } from '@/services/marketplace-nft';

/**
 * Types
 */
import type { NFTTypeListResult } from '@/services/marketplace-nft';

type State = {
    dataSource: NFTTypeListResult[];
    sorting: boolean;
    openDrawer: boolean;
    entity: NFTTypeListResult | null | undefined;
    selectdEntity: NFTTypeListResult | null | undefined;
};

const PageNFTMarketplace: React.FC = () => {
    /**
     * Hooks
     */
    const intl = useIntl();

    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        sorting: false,
        openDrawer: false,
        entity: null,
        selectdEntity: null,
    });

    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const {
        loading: loadingTypeListApi,
        refresh: refreshTypeListApi,
        run: runTypeListApi,
    } = useRequest(gTypeListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            const dataSource = data || [];
            // 当选中时，编辑选中的数据，同步更新选中的数据
            let selectdEntity = state.selectdEntity;
            if (selectdEntity) {
                selectdEntity = dataSource.find((item) => item.id === state.selectdEntity?.id);
            }

            setState({
                dataSource,
                sorting: false,
                selectdEntity,
            });
        },
    });

    const { run: runSortTypeApi, loading: loadingCreatorPostsSortApi } = useRequest(sTypeApi, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            refreshTypeListApi();
        },
    });

    /**
     * ChildrenProps
     */
    const dragSortTableProps: DragTableProps<NFTTypeListResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                ellipsis: true,
                title: <FormattedMessage id="mknft.t.c.name" />,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => <Tag color={status ? 'green' : 'orange'}>{status ? 'Enabled' : 'Disabled'}</Tag>,
                width: 80,
            },
            {
                dataIndex: '_',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                render: (_, entity) => [
                    <Permission permission="Marketplace_NFT_NFT-Marketplace" key="detail">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    entity.collection_id !== '0' && (
                        <Permission permission="Marketplace_NFT_NFT-Marketplace" key="delete">
                            <Spin spinning={!!cellLoading[entity.id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setCellLoading({ [entity.id]: true });
                                        dTypeApi(entity.collection_id)
                                            .then(({ data: { code, msg } }) => {
                                                setCellLoading({ [entity.id]: false });
                                                if (code !== 0) {
                                                    return message.error(msg || 'Error');
                                                }
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                // 删除的是当前选中的项，则重置选中项
                                                if (state.selectdEntity && state.selectdEntity.id === entity.id) {
                                                    setState({ selectdEntity: undefined });
                                                }
                                                refreshTypeListApi();
                                            })
                                            .catch(() => {
                                                setCellLoading({ [entity.id]: false });
                                                message.error('Error');
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
                width: 100,
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: (entity) => {
            console.log(entity.collection_id);
            if (entity.collection_id === '0') {
                return (
                    <Button
                        type="text"
                        size="small"
                        icon={<HolderOutlined />}
                        style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            return false;
                        }}
                    />
                );
            }
            return <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />;
        },
        loading: loadingTypeListApi,
        options: {
            reload: refreshTypeListApi,
        },
        pagination: false,
        rowKey: 'collection_id',
        scroll: {
            y: `calc(100vh - 232px)`,
        },
        search: false,
        headerTitle: 'Type List',
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingCreatorPostsSortApi}
                        onClick={() => {
                            runSortTypeApi({ collectionIds: state.dataSource.map((item) => item.collection_id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingCreatorPostsSortApi}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshTypeListApi();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Permission permission="Marketplace_NFT_NFT-Marketplace" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Marketplace_NFT_NFT-Marketplace" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: (_beforeIndex: number, _afterIndex: number, newDataSource) => {
            if (state.dataSource[0].collection_id === '0' && newDataSource[0].collection_id !== '0') {
                message.error(`[${state.dataSource[0].name}] not allow to sort`);
                const sortedDataSource: any[] = [state.dataSource[0]].concat(
                    newDataSource.filter((item) => item.collection_id !== '0'),
                );
                setState({
                    dataSource: sortedDataSource,
                });
            } else {
                setState({
                    dataSource: newDataSource,
                });
            }
        },
        onRow: (data) => {
            return {
                onClick: (e) => {
                    const clickTagName = (e.target as HTMLElement).tagName;
                    if (
                        clickTagName === 'A' ||
                        clickTagName === 'BUTTON' ||
                        (e.target as HTMLElement).parentElement?.tagName === 'BUTTON'
                    ) {
                        return true;
                    }
                    // if (e.target)
                    setState({ selectdEntity: data });
                },
            };
        },
        rowClassName: (record) => (record.id === state.selectdEntity?.id ? 'rowClassName' : ''),
    };

    /**
     * Effects
     */
    useEffect(() => {
        runTypeListApi();
    }, []);

    return (
        <>
            <Row gutter={16} wrap={false}>
                <Col flex="480px">
                    <DragSortTable {...dragSortTableProps} tableStyle={{ minHeight: 'calc(100vh - 184px)' }} />
                </Col>

                <Col flex="auto">
                    <ProCard style={{ padding: 0, minHeight: 'calc(100vh - 104px)' }} bodyStyle={{ padding: 0 }}>
                        <ComponentRightTable language={'EN'} subjectEntity={state.selectdEntity} />
                    </ProCard>
                </Col>
            </Row>
            <ComponentDrawerFormType
                open={state.openDrawer}
                entity={state.entity}
                language={'EN'}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false, entity: null });
                    reload && refreshTypeListApi();
                }}
            />
        </>
    );
};

export default PageNFTMarketplace;
