import { AudioPlayer } from 'react-audio-play';
import { Audio } from '@/services/posts';

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Props
 */
type Props = {
    srcs: Audio[];
};

const ComponentTableCellAudio: React.FC<Props> = ({ srcs }) => {
    if (!srcs || !srcs.length || !srcs[0].url) {
        return '-';
    }
    return (
        <AudioPlayer
            preload="metadata"
            src={`${WEB2_ASSETS_URL}/${srcs[0].url}`}
            style={{
                maxWidth: 300,
                height: 46,
                paddingLeft: 14,
                paddingRight: 14,
                borderRadius: 10,
                transform: 'scale(0.8)',
            }}
        />
    );
};

export default ComponentTableCellAudio;
