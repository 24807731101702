export default {
    p: {
        logs: {
            t: {
                c: {
                    body: 'Operation Details',
                    menu: 'Menu',
                    params: 'Path',
                    result: 'Operation Result',
                },
            },
        },
    },
};
