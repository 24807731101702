export const LanguageMap: Record<string, string> = {
    'en-US': 'EN', // 英语
    'zh-CN': 'CHS', // 简体中文
    'ja-JP': 'JPN', // 日语
    'vi-VN': 'VIE', // 越南语
    'tr-TR': 'TRK', // 土耳其语
    'es-ES': 'ESP', // 西班牙语
};

export const Languages = ['EN', 'CHS', 'JPN', 'VIE', 'TRK', 'ESP', 'UNKNOWN'];

// 用于创作者 帖子+话题 语言筛选
export const CreatorLanguageOptions = Languages.map((v) => {
    return {
        label: v,
        value: v,
    };
});
