import { Checkbox, Popconfirm, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

/**
 * APIs
 */
import { useState } from 'react';

/**
 * Props
 */
type Props = {
    entity: any;
    sureCallback: (id: string | number, reset_items: string[]) => void;
};
const ComponentAccountReportBtn: React.FC<Props> = ({ entity, sureCallback }) => {
    /**
     * States
     */
    const [checkbox, setCheckbox] = useState([]);

    return (
        <Popconfirm
            key="delete"
            icon={null}
            title={null}
            okButtonProps={{
                disabled: checkbox.length === 0,
            }}
            description={() => (
                <Checkbox.Group value={checkbox} onChange={setCheckbox} style={{ width: 200 }}>
                    <Space direction="vertical">
                        <Checkbox value="name">
                            <FormattedMessage id="rm.t.c.nickName" />
                        </Checkbox>
                        <Checkbox value="username">
                            <FormattedMessage id="rm.t.c.name" />
                        </Checkbox>
                        <Checkbox value="avatar">
                            <FormattedMessage id="rm.t.c.avatar" />
                        </Checkbox>
                        <Checkbox value="bio">
                            <FormattedMessage id="rm.t.c.userInfo" />
                        </Checkbox>
                    </Space>
                </Checkbox.Group>
            )}
            onConfirm={async () => {
                await sureCallback(entity.id, checkbox);
            }}
            onOpenChange={(open) => !open && setCheckbox([])}
        >
            <Typography.Link type="danger">
                <FormattedMessage id="rm.b.violate" />
            </Typography.Link>
        </Popconfirm>
    );
};

export default ComponentAccountReportBtn;
