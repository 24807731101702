import axios from './index';

/**
 * APIs
 */
// 获取 热门token
export const gTopToken = () => {
    return axios<ResType<TokenConfig[]>>('/v1/web3/token/tops', {
        method: 'get',
    });
};

// 排序 热门token
export const sTopToken = (data: { token_ids: string[] | number[] }) => {
    return axios<ResType<TokenConfig[]>>('/v1/web3/token/tops/sort', {
        method: 'post',
        data,
    });
};

// 添加 热门token
export const pTopToken = (data: { token_id: string | number }) => {
    return axios<ResType<TokenConfig[]>>(`/v1/web3/token/tops`, {
        method: 'post',
        data,
    });
};

// 添加 热门token
export const dTopToken = (token_id: string | number) => {
    return axios<ResType<TokenConfig[]>>(`/v1/web3/token/tops/${token_id}`, {
        method: 'delete',
    });
};

// 获取token 列表
export const gTokenList = () => {
    return axios<ResType<TokenListItem[]>>('/v1/web3/token/list', {
        method: 'get',
    });
};

/**
 * ErrorCode
 */

export const ErrorCodeMap = {
    160101: 'cpost.e.postExit',
    100101: 'cpost.e.postExit',
    100102: 'cpost.e.postNotFound',
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

export type TokenConfigsParams = {
    token_id: string;
};

export type Token = {
    /**
     * ID
     */
    id: string;
    /**
     * 代币 ID
     */
    token_id: string;
    /**
     * 符号
     */
    symbol: string;
    /**
     * 全称
     */
    full_symbol: string;
    /**
     * 标签
     */
    label: string;
    /**
     * URL
     */
    url: string;
};

/**
 * 代币修改
 */
export type TokenConfigUpdate = {
    /**
     * 色值
     */
    color: string;
    /**
     * 交易对排序
     */
    sort: number;
    /**
     * 价格精度
     */
    price_scale: number;
    /**
     * 数量精度
     */
    show_scale: number;
    /**
     * 最小划转金额
     */
    // min_top_up: string;
    /**
     * 最小交易金额
     */
    // min_trade_amount: string;
    /**
     * 类型
     */
    type: number;
    /**
     * 状态
     */
    status: number;
    /**
     * 更新时间
     */
    updated_at: string;
};

/**
 * 代币配置
 */
export interface TokenConfig {
    /**
     * ID
     */
    id: string;
    /**
     * 代币
     */
    token: Token;
    /**
     * 交易对排序
     */
    sort: number;
    /**
     * 色值
     */
    color: string;
    /**
     * 引擎精度
     */
    decimals: string;
    /**
     * 最小划转金额
     */
    min_top_up: string;
    /**
     *
     */
    chain: string;
    /**
     * 状态
     */
    status: number;
    /**
     * 价格精度
     */
    price_scale: number;
    /**
     * 数量精度
     */
    show_scale: number;
    /**
     *
     */
    min_trade_amount: string;
    /**
     * 类型
     */
    type: number;
    /**
     * 操作人
     */
    operator: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 更新时间
     */
    updated_at: string;
}

export type TokenListItem = {
    id: string;
    label: string;
    status: number;
    token_id: string;
    type: number;
    url: string;
};
