import axios from './index';

/**
 * APIs 话题管理
 */
// 获取列表
export const gCreatorTopicApi = (params: CreatorTopicParams) => {
    return axios<PageResType<CreatorTopicResult>>('/v1/creator/topics', {
        method: 'get',
        params,
    });
};

// 创建话题
export const cCreatorTopicApi = (data: CreatorTopicContent) => {
    return axios<PageResType<null>>('/v1/creator/topics', {
        method: 'post',
        data,
    });
};

// 编辑话题
export const pCreatorTopicApi = (id: string | number, data: CreatorTopicContent) => {
    return axios<PageResType<null>>(`/v1/creator/topics/${id}`, {
        method: 'put',
        data,
    });
};

// 删除话题
export const dCreatorTopicApi = (data: { ids: string[] }) => {
    return axios<PageResType<null>>('/v1/creator/topics', {
        method: 'delete',
        data,
    });
};

// 导入话题
export const iCreatorTopicApi = (data: FormData) => {
    return axios<PageResType<null>>('/v1/creator/topics/import', {
        method: 'post',
        data,
    });
};

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';

export type CreatorTopicResult = {
    content: string;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    operator_account: OperatorAccount;
    operator_account_id: string;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 语言
     */
    language: string;
};
// 操作人
export interface OperatorAccount {
    /**
     * 账号名
     */
    name: string;
    /**
     * 状态
     */
    status: number;
}

export type CreatorTopicParams = {
    limit?: number;
    operation_end?: string;
    operation_start?: string;
    /**
     * 操作人后台账号 ID
     */
    operator?: string;
    page?: number;
    topic?: string;
    /**
     * 语言
     */
    language?: string;
};

export type CreatorTopicContent = Pick<CreatorTopicResult, 'content'> & {
    /**
     * 更新时间
     */
    updated_at?: string;
};

export type ExportCreatorTopic = {
    file: File;
};

export type FeCreatorTopicParams = CreatorTopicParams & {
    operation_date?: any[];
};
