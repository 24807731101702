export default {
    'digitizedCivilizationSquare.table.headerTitle': '广场列表',

    'digitizedCivilizationSquare.table.columns.name': '名称',
    'digitizedCivilizationSquare.table.columns.area': '生效地域',
    'digitizedCivilizationSquare.table.columns.status': '启用状态',

    'digitizedCivilizationSquare.table.columns.sort': '排序',
    'digitizedCivilizationSquare.table.columns.l1': '一级标签',
    'digitizedCivilizationSquare.table.columns.icon': '图标',
    'digitizedCivilizationSquare.table.columns.headerImage': '头图',

    'digitizedCivilizationSquare.placeholder': '请点击左侧各板块进行内容配置',
};
