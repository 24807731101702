import { ProLayout } from '@ant-design/pro-components';
import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { useBoolean, useLocalStorageState, useRequest } from 'ahooks';
import { App, ConfigProvider, Dropdown, message, Space } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useEffect } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';
import store from 'store2';
import routes from '@/router/routes';
import { markedRoutes } from '@/utils';
import { findRoute } from '@/utils/findRoute';

/**
 * APIs
 */
import { logout, me } from '@/services/auth';

/**
 * Components
 */
import ComponentLanguage from '@/components/language';
import ComponentChangePasswordModal from '@/components/changePasswordModal';

/**
 * Contexts
 */
import { useLayoutStore } from '@/store/layout';

/**
 * Resources
 */
import antdEnUS from 'antd/locale/en_US';
import antdZhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import intlEnUS from '@/locales/en-US';
import intlZhCN from '@/locales/zh-CN';

/**
 * Types
 */
import type { ProLayoutProps } from '@ant-design/pro-components';
import type { ConfigProviderProps, DropdownProps } from 'antd';
import type { AccountsResponse } from '@/services/account';

/**
 * Constants
 */
const localeMaps: Record<string, any> = {
    'en-US': {
        antd: antdEnUS,
        dayjs: 'en',
        intl: intlEnUS,
    },
    'zh-CN': {
        antd: antdZhCN,
        dayjs: 'zh-cn',
        intl: intlZhCN,
    },
};

const routesWithoutLayout = ['/login', '/403'];

const LayoutIndex: React.FC = () => {
    /**
     * Hooks
     */
    const location = useLocation();

    /**
     * States
     */
    const { config, layout, locale } = useLayoutStore();

    const [permissions, setPermissions] = useLocalStorageState<string[]>('permissions');

    const [user] = useLocalStorageState<Pick<AccountsResponse, 'id' | 'name'>>('user', {
        defaultValue: {
            id: '',
            name: 'Socrates',
        },
    });

    const [
        openChangePasswordModal,
        { setFalse: setOpenChangePasswordModalFalse, setTrue: setOpenChangePasswordModalTrue },
    ] = useBoolean(false);

    /**
     * Requests
     */
    useRequest(me, {
        ready: !!user!.id,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            const { permissions, roles, ...user } = data;

            setPermissions(permissions);

            store({ roles, user });
        },
    });

    /**
     * ChildrenProps
     */
    const configProps: ConfigProviderProps = {
        locale: localeMaps[locale!].antd,
        ...config,
    };

    const dropdownProps: DropdownProps = {
        menu: {
            items: [
                {
                    key: 'changePassword',
                    label: (
                        <Space align="center">
                            <LockOutlined />
                            <FormattedMessage id="c.cpm.changePassword" />
                        </Space>
                    ),
                },
                {
                    key: 'signOut',
                    label: (
                        <Space align="center">
                            <LogoutOutlined />
                            <FormattedMessage id="signOut" />
                        </Space>
                    ),
                },
            ],
            onClick: ({ key }) => {
                // signOut
                if (key === 'signOut') {
                    logout()
                        .then(({ data: { code, msg } }) => {
                            if (code !== 0) {
                                message.error(msg);
                                return;
                            }

                            store.clear();

                            window.location.href = '/login';
                        })
                        .catch((e) => {
                            message.error(get(e, 'response.data.msg', 'Error'));
                        });
                } else if (key === 'changePassword') {
                    setOpenChangePasswordModalTrue();
                }
            },
        },
        placement: 'bottomRight',
    };

    const layoutProps: ProLayoutProps = {
        avatarProps: {
            children: user!.name?.charAt(0).toUpperCase(),
            size: 'small',
            title: user!.name,
            render: (_, dom) => <Dropdown {...dropdownProps}>{dom}</Dropdown>,
        },
        contentStyle: {
            padding: 24,
        },
        layout: 'mix',
        location,
        logo: '/favicon.svg',
        pure: routesWithoutLayout.includes(location.pathname),
        route: {
            routes: routes.map((route) => {
                return markedRoutes(route);
            }),
        },
        siderWidth: 240,
        title: 'Socrates Admin',
        actionsRender: () => [<ComponentLanguage key="language" />],
        menuFooterRender: (props) => `v${props?.collapsed ? __VERSION__.match(/^(\d+\.\d+)/)?.[0] : __VERSION__}`,
        menuItemRender: (item, dom) => <Link to={item.path!}>{dom}</Link>,
        ...layout,
    };

    /**
     * Effects
     */
    dayjs.locale(localeMaps[locale!].dayjs);

    useEffect(() => {
        const current = location.pathname.split('/').pop() || '/';
        if (current === 'login' || current === '403') {
            return;
        }
        const route = findRoute(current, routes);
        if (!route || route.unaccessible) {
            // 没有权限, 跳转403
            window.location.replace('/403');
        }
    }, [permissions]);

    return (
        <ConfigProvider {...configProps}>
            <App>
                <IntlProvider locale={locale!} messages={localeMaps[locale!].intl}>
                    <ProLayout {...layoutProps}>
                        <Outlet />
                    </ProLayout>

                    <ComponentChangePasswordModal
                        open={openChangePasswordModal}
                        closeModelForm={setOpenChangePasswordModalFalse}
                    />
                </IntlProvider>
            </App>
        </ConfigProvider>
    );
};

export default LayoutIndex;
