import { useEffect } from 'react';
import { App, Button, Drawer, Form, Input, Popconfirm, Radio, Space, Typography, Upload } from 'antd';
import { HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { DragSortTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { cloneDeep, get } from 'lodash';
import store from 'store2';

/**
 * APIs
 */
import { checkPostId, uploadImage } from '@/services/common';
import { uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { DragTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, UploadProps } from 'antd';
import { FormattedMessage } from 'react-intl';

type DataType = {
    created_at: string;
    encrypt_post_id: string;
    icon: string;
    idorurl: string;
    title: string;
    subtitle: string;
    operator: string;
    type: 'ID' | 'Url';
    updated_at: string;
};

type State = {
    form: Record<string, any>;
    index: number;
    open: boolean;
    openName: boolean;
    type: 'create' | 'update';
    uploadIcon: boolean;
    sorting: boolean;
};

const ComponentBlockCustom: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const [formName] = Form.useForm();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        form: {
            type: 'ID',
        },
        index: -1,
        open: false,
        openName: false,
        type: 'create',
        uploadIcon: true,
        sorting: false,
    });

    const { current, dataSource, setCurrent, setDataSource, setRefreshDataSource } =
        useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    const { loading: loadingCheckPostId, runAsync: runAsyncCheckPostId } = useRequest(checkPostId, {
        manual: true,
    });

    const { loading: loadingUBlock, run: runUBlock } = useRequest(uBlock, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code === 150203) {
                return message.error('It has been edited by someone else, please refresh');
            }

            if (code !== 0) {
                return message.error(msg);
            }

            const prevData = dataSource.find((item) => item.type === current!.type);

            const index = prevData!.children!.findIndex((item) => item.id === current!.id);

            prevData!.children![index] = {
                ...current!,
                ...data,
            };

            setCurrent(data);

            setDataSource(dataSource);

            setState({
                open: false,
                openName: false,
                uploadIcon: true,
                sorting: false,
            });

            message.success('Updated');
        },
    });

    /**
     * ChildrenProps
     */
    const dragTableProps: DragTableProps<DataType, any> = {
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'idorurl',
                title: '帖子 ID / 外链 URL',
                width: 200,
            },
            {
                dataIndex: 'title',
                title: '主标题',
            },
            {
                dataIndex: 'icon',
                title: '图标',
                width: 120,
                valueType: 'image',
            },
            {
                dataIndex: 'operator',
                title: '操作人',
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: '操作时间',
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: '操作',
                valueType: 'option',
                width: 120,
                render: (_, entity, index) => [
                    <a
                        key="update"
                        onClick={() => {
                            form.setFieldsValue({
                                ...entity,
                                icon: entity.icon
                                    ? [
                                          {
                                              name: 'icon',
                                              url: entity.icon,
                                          },
                                      ]
                                    : [],
                            });

                            setState({
                                form: {
                                    type: entity.type,
                                },
                                index,
                                open: true,
                                type: 'update',
                                uploadIcon: !entity.icon,
                            });
                        }}
                    >
                        编辑
                    </a>,
                    <Popconfirm
                        cancelText="No"
                        key="delete"
                        okText="Yes"
                        title="Are you sure?"
                        onConfirm={() => {
                            current!.data.splice(index, 1);

                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                    >
                        <Typography.Link type="danger">删除</Typography.Link>
                    </Popconfirm>,
                ],
            },
        ],
        dataSource: current!.data,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        headerTitle: current!.name,
        options: {
            reload: () => {
                setRefreshDataSource();
            },
        },
        pagination: false,
        rowKey: 'idorurl',
        scroll: {
            x: 1060,
        },
        search: false,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingUBlock}
                        onClick={() => {
                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,

                    <Button
                        loading={loadingUBlock}
                        onClick={() => {
                            setState({ sorting: false });
                            const prevData = dataSource.find((item) => item.type === current!.type);

                            const index = prevData!.children!.findIndex((item) => item.id === current!.id);

                            const oldCurrent = prevData!.children![index];

                            setCurrent({ ...oldCurrent });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Button
                    disabled={current!.data?.length >= current!.max_item_count}
                    key="add"
                    type="primary"
                    onClick={() => {
                        setState({
                            form: {
                                type: 'ID',
                            },
                            index: -1,
                            open: true,
                            type: 'create',
                            uploadIcon: true,
                        });
                    }}
                >
                    添加
                </Button>,

                <Button
                    key="update"
                    onClick={() => {
                        formName.setFieldsValue({
                            name: current!.name,
                        });

                        setState({
                            openName: true,
                        });
                    }}
                >
                    编辑
                </Button>,

                <Button
                    key="sort"
                    type="primary"
                    ghost
                    onClick={() => {
                        setState({ sorting: true });
                    }}
                >
                    <FormattedMessage id="tc.t.c.sort" />
                </Button>,
            ];
        },
        onDragSortEnd: (_, __, newDataSource) => {
            setCurrent({ ...current!, data: newDataSource });
        },
    };

    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        loading={loadingCheckPostId || loadingUBlock}
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    if (
                                        current!.data?.some(
                                            (item: DataType, index: number) =>
                                                item.idorurl === values.idorurl && index !== state.index,
                                        )
                                    ) {
                                        return message.error('帖子 ID 或外链 Url 重复');
                                    }

                                    const prevData = cloneDeep(current!.data) || [];

                                    // 创建
                                    if (state.type === 'create') {
                                        prevData.push({
                                            ...values,
                                            icon: get(values, 'icon.0.url', ''),
                                            created_at: new Date().toISOString(),
                                            operator: store('user').name,
                                            updated_at: new Date().toISOString(),
                                        });
                                    }
                                    // 编辑
                                    else {
                                        prevData[state.index] = {
                                            ...prevData[state.index],
                                            ...values,
                                            icon: get(values, 'icon.0.url', ''),
                                            operator: store('user').name,
                                            updated_at: new Date().toISOString(),
                                        };
                                    }

                                    if (values.type === 'ID') {
                                        runAsyncCheckPostId(values.idorurl).then(({ data: { data } }) => {
                                            if (!data) {
                                                return message.error('帖子 ID 验证不通过');
                                            }

                                            const index = state.index > -1 ? state.index : prevData.length - 1;
                                            prevData[index].encrypt_post_id = data.encrypt_post_id;

                                            runUBlock(current!.id, {
                                                data: prevData,
                                                updated_at: current!.updated_at,
                                            });
                                        });
                                    } else {
                                        runUBlock(current!.id, {
                                            data: prevData,
                                            updated_at: current!.updated_at,
                                        });
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        保存
                    </Button>
                </Space>
            </div>
        ),
        maskClosable: false,
        open: state.open,
        title: state.type === 'create' ? '添加' : '编辑',
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            }
        },
        onClose: () => {
            setState({
                open: false,
                uploadIcon: true,
            });
        },
    };

    const drawerNameProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        loading={loadingUBlock}
                        type="primary"
                        onClick={() => {
                            formName.validateFields().then((values) => {
                                runUBlock(current!.id, {
                                    ...values,
                                    updated_at: current!.updated_at,
                                });
                            });
                        }}
                    >
                        保存
                    </Button>
                </Space>
            </div>
        ),
        maskClosable: false,
        open: state.openName,
        title: '编辑排行榜',
        afterOpenChange: (open) => {
            if (!open) {
                formName.resetFields();
            }
        },
        onClose: () => {
            setState({
                openName: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        initialValues: {
            type: 'ID',
        },
        layout: 'vertical',
    };

    const formNameProps: FormProps = {
        form: formName,
        layout: 'vertical',
    };

    const uploadProps: UploadProps = {
        accept: 'image/jpg,image/jpeg,image/png',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    useEffect(() => {
        setState({
            sorting: false,
        });
    }, [current?.id]);

    return (
        <>
            <DragSortTable {...dragTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item label="类型" name="type" rules={[{ required: true }]}>
                        <Radio.Group
                            onChange={(e) => {
                                setState({
                                    form: {
                                        type: e.target.value,
                                    },
                                });
                            }}
                        >
                            <Radio value="ID">帖子 ID</Radio>
                            <Radio value="Url">外链 URL</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {state.form.type === 'ID' && (
                        <Form.Item label="帖子 ID" name="idorurl" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    )}

                    {state.form.type === 'Url' && (
                        <Form.Item label="外链 URL" name="idorurl" rules={[{ required: true, type: 'url' }]}>
                            <Input />
                        </Form.Item>
                    )}

                    <Form.Item label="主标题" name="title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="副标题" name="subtitle">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        extra="Size: 144*144 (width x height), JPG/JPEG/PNG, within 3 MB"
                        label="Icon"
                        name="icon"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (value.length === 0) {
                                        return Promise.reject('Please upload the file');
                                    }

                                    const isDone = value.every((item: any) => item.url);

                                    return isDone ? Promise.resolve() : Promise.reject('Uploading');
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadIcon: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadProps}>
                            {state.uploadIcon && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerNameProps}>
                <Form {...formNameProps}>
                    <Form.Item label="排行榜名称" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default ComponentBlockCustom;
