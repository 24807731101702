export default {
    p: {
        appVersion: {
            title: 'App版本管理',
            version: '最新版本',
            androidApk: 'Android APK地址',
            googlePlay: 'Google Play地址',
            appStore: 'App Store地址',
            save: '保存',
            cancel: '取消',
            modify: '修改',
            lowestVersion: '强制更新最低版本',
            approvedVersion: '审核版本',
            language: '多语言',
            versionUpdateTitle: '版本更新标题',
            versionUpdateContent: '版本更新内容',
        },
    },
};
