export default {
    'menu.dashboard': 'Dashboard',

    /**
     * 大使计划
     */
    'menu.ambassador': 'Ambassador',
    'menu.ambassador.application': 'Application',
    'menu.ambassador.applicationPublicPool': 'Application Public Pool',
    'menu.ambassador.applicationPrivatePool': 'Application Private Pool',
    'menu.ambassador.applicationReviewRecords': 'Review Records',

    'menu.ambassador.l2-3': 'Data Management',
    'menu.ambassador.l2-3-1': 'Task data',

    'menu.ambassador.config': 'Configuration Management',
    'menu.ambassador.config.applicationPrivatePoolConfiguration': 'Application Private Pool Configuration',
    /**
     * 大使计划 end
     */

    'menu.client': 'Client',
    'menu.client.digitizedCivilizationSquare': 'Digitized Civilization Square',

    'menu.roleManagement': 'Roles',
    'menu.airdropRootAccount': 'Post Airdrop Super-Admin',
    'menu.systemManagement': 'System Management',
    'menu.accountManagement': 'Accounts',
    'menu.userNameManagement': 'Username Management',
    clientConfigurationManagement: '客户端配置管理',
    'menu.airDropManagement': '空投管理',
    'menu.officialAirDropList': '官方空投活动列表',

    /**
     * 举报管理
     */
    'menu.reportManagement': '举报管理',
    'menu.postReport': '帖子举报',
    'menu.commentReport': '评论举报',
    'menu.accountInfoReport': '个人资料举报',
    /**
     * 举报管理 end
     */
};
