export default {
    sma: {
        b: {
            generated: '生成',
        },
        c: {
            inputAccountPlaceholder: '20字符数限制，可包含大小写字母、数字、特殊字符',
            inputPWDPlaceholder: '8-16位，须包含大小写字母、数字、特殊字符',
        },
    },
};
